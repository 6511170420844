import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  HashRouter as Router
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as Sentry from '@sentry/react';
import Hotjar from '@hotjar/browser';
import { TooltipProvider } from '@companion-professional/components';
import { getEnvironmentFromLocation } from '@companion-professional/webutils';
import { version, commitHash } from './lib/version';
import { AppWithLDProvider } from './App';
import './tailwind.css';

declare const __SENTRY_DSN__: string;

Sentry.init({
  dsn: __SENTRY_DSN__,
  environment: getEnvironmentFromLocation('companionpro.biz', 'vportal'),
  release: `${version}-${commitHash}`,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    }),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    })
  ],

  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions

  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/vportal\.(.*\.)?companionpro\.biz/i],

  // Session Replay

  // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a
  // lower rate in production.
  replaysSessionSampleRate: 0.1,

  // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where
  // errors occur.
  replaysOnErrorSampleRate: 1.0
});

// Initialize Hotjar
// NOTE: When running locally the hotjar widget will not be displayed. This is because the hotjar widget has been
// configured to only work when loaded from https://vportal.companionpro.biz/.  If this needs to be changed, you can
// update the hotjar settings here: https://insights.hotjar.com/site/3882285/settings#site-information
declare const __HOTJAR_SITE_ID__: number;
declare const __HOTJAR_VERSION__: number;
Hotjar.init(__HOTJAR_SITE_ID__, __HOTJAR_VERSION__);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // I'm not sure having retries is helping us at the moment, so I'm setting the retry to false.  This means that
      // if a query fails, it will not be retried and immediately return an error.
      retry: false
    }
  }
});

// This is the main entry point for the web app. It is responsible for rendering the App component and setting up any
// high level context/providers (like the QueryClientProvider)
ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    {/* TODO: Add fallback error component to Sentry.ErrorBoundary */}
    <Sentry.ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <Router>
          <TooltipProvider>
            <AppWithLDProvider />
          </TooltipProvider>
        </Router>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);
