import React, { FunctionComponent } from 'react';

interface LoginLayoutProps {
  children?: React.ReactNode;
}

// LoginLayout is the core layout wrapper for the Login page.
export const LoginLayout: FunctionComponent<LoginLayoutProps> = ({ children }) => {
  return (
    <div className="flex min-h-screen justify-center p-0 md:items-center md:p-6">
      <div className="flex w-full flex-col justify-between overflow-hidden bg-white shadow-lg md:flex-1 md:flex-row md:justify-start md:rounded-md lg:max-w-screen-md">
        {children}
      </div>
    </div>
  );
};
