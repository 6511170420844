import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updatePetName } from '../lib/api';

export type UpdatePetNameFormValues = {
  petId: string;
  petName: string;
};

// useSavePetName is a hook that returns a function that updates the pet name.
//
// It returns:
// - isPending: A boolean that indicates if the request is currently in progress.
// - error: An error object if the request failed.
// - savePetName: Mutation function that updates the pet name.
export const useSavePetName = () => {
  const queryClient = useQueryClient();

  const {
    mutateAsync: savePetName,
    isPending,
    error
  } = useMutation({
    mutationFn: async (values: UpdatePetNameFormValues) => {
      const res = await updatePetName(values.petId, values.petName);
      await queryClient.invalidateQueries({
        queryKey: ['active-checkups']
      });
      return res;
    }
  });

  return {
    savePetName,
    isPending,
    error
  };
};
