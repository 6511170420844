import React, { FunctionComponent } from 'react';
import { CheckboxWithLabel } from '../../CheckboxWithLabel';
import { Answers, CheckboxFieldSchema, CheckboxField } from '../dynamicFormSchema';
import { DynamicFieldProps } from '../DynamicField';
import { DynamicFieldComponent } from './DynamicFieldComponentBase';

export const CheckboxFieldComponent: FunctionComponent<DynamicFieldProps> = ({ field, reactHookField }) => {
  const c = CheckboxFieldSchema.parse(field);
  return (
    <CheckboxWithLabel
      checked={reactHookField.value}
      disabled={reactHookField.disabled}
      onCheckedChange={(value) => {
        // This is a custom Radix thing (the onCheckedChange prop) that needs to be manually mapped back to the
        // react-hook-form onChange function.
        // Even though the value of a checkbox is a string, we want to save it as a boolean.
        reactHookField.onChange(!!value);
      }}
      id={c.name}
      label={c.label}
      name={c.name}
      description={c.description}
    />
  );
};

export class DynamicCheckboxField extends DynamicFieldComponent<CheckboxField, boolean> {
  Component = CheckboxFieldComponent;

  constructor(field: CheckboxField) {
    super(field);
  }

  getAnswer(answers: Answers): boolean {
    const answer = answers[this.field.name];

    if (answer === undefined) {
      return this.getDefaultValue();
    }

    if (typeof answer === 'boolean') {
      return answer;
    }

    throw new Error(`Invalid answer for field ${this.field.name}`);
  }

  getDefaultValue(): boolean {
    return !!this.field.defaultValue;
  }

  getValueToSubmit(value: unknown): boolean {
    return !!value;
  }
}
