import React, { FunctionComponent } from 'react';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  InputWithLabel,
  QueryLoaderWrapper
} from '@companion-professional/components';
import { CheckupRequestInfo } from '@companion-professional/dctypes';
import { DialogSharedProps } from '../DialogSharedProps';
import { DymoPrinterForm } from '../DymoPrinterForm';
import { useCheckupUrl } from '../../hooks/useCheckupUrl';
import { getQrCardWithIconXml, getSimpleQrCardXml } from '../../lib/dymoLabels';
import { useHealthSurveyUrl, useSetHealthSurveyUrl } from '../../state/eventCards';

interface EventCardsPrinterDialogProps extends DialogSharedProps {
  checkupRequest: CheckupRequestInfo;
}

// EventCardsPrinterDialog is a dialog that will create and print the cards needed at a Companion event (e.g. BarK, OFA
// clinic, etc.).  There will be two cards:
// 1. Take this card to the videographer
// 2. Take this card to the clinician
export const EventCardsPrinterDialog: FunctionComponent<EventCardsPrinterDialogProps> = ({
  children,
  checkupRequest,
  open,
  onOpenChange = () => {}
}) => {
  const { getCheckupUrl, error: checkupUrlError, isPending: checkupUrlIsPending } = useCheckupUrl();
  const checkupUrl = getCheckupUrl(checkupRequest.id);
  const vportalCheckupUrl = `${window.location.origin}/#/checkup/${checkupRequest.id}`;
  const healthSurveyUrl = useHealthSurveyUrl();
  const setHealthSurveyUrl = useSetHealthSurveyUrl();

  return (
    <Dialog open={open} onOpenChange={(o) => onOpenChange(o)}>
      {children ? <DialogTrigger asChild>{children}</DialogTrigger> : null}
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Event Card</DialogTitle>
          <DialogDescription>Print cards after creating a checkup.</DialogDescription>
        </DialogHeader>
        <div>
          <InputWithLabel
            label="Health Survey URL"
            description={
              "Changing this URL will change the URL used for the health survey card's QR code.  NOTE: this URL is " +
              'saved locally to your browser so if you use another computer/browser; you will need to update this ' +
              'field.'
            }
            value={healthSurveyUrl}
            onChange={(e) => setHealthSurveyUrl(e.target.value)}
          />
        </div>
        <QueryLoaderWrapper isPending={checkupUrlIsPending} error={checkupUrlError}>
          {checkupUrl ? (
            <DymoPrinterForm
              createLabels={() => [
                getQrCardWithIconXml(
                  checkupUrl,
                  'Step 1 of 3 - Video',
                  'Hand this card to the videographer.',
                  `Your Pet Id: ${checkupRequest.clinic_pet_id}`,
                  'dogCamera'
                ),
                getQrCardWithIconXml(
                  vportalCheckupUrl,
                  'Step 2 of 3 - Physical Exam',
                  'Hand this card to the veterinarian.',
                  `Your Pet Id: ${checkupRequest.clinic_pet_id}`,
                  'dogExam'
                ),
                getSimpleQrCardXml(
                  healthSurveyUrl,
                  'Step 3 of 3 - Health Questionnaire',
                  "Scan this card and fill out the questionnaire.  When you're finished, come back to the booth.",
                  `Your Pet Id: ${checkupRequest.clinic_pet_id}`
                )
              ]}
            />
          ) : null}
        </QueryLoaderWrapper>
        <DialogFooter className="sm:justify-start">
          <DialogClose asChild>
            <Button size="small" type="button" variant="secondary">
              Close
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
