import React, { FunctionComponent } from 'react';
import { DataTable } from '@companion-professional/components';
import { PetAndClinicInfo } from '@companion-professional/dctypes';
import { getPetsForClinicColumns } from './columns';

interface PetsTableProps {
  pets: PetAndClinicInfo[];
  onOpen: (pet: PetAndClinicInfo) => void;
}

export const PetsTable: FunctionComponent<PetsTableProps> = ({ pets, onOpen }) => {
  return (
    <DataTable
      name="petsTable"
      columns={getPetsForClinicColumns(onOpen)}
      data={pets}
      filterPlaceholder="Enter Pet Name or ID to filter results..."
      resultsPerPage={20}
      enableSearch
      enablePagination
    />
  );
};
