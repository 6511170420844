import { CheckupRequestStatusResponse } from '@companion-professional/dctypes';
import React, { FunctionComponent } from 'react';
import { CheckCircle, Circle, CircleEllipsis } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipTrigger } from '@companion-professional/components';

interface StatusIconProps {
  status: CheckupRequestStatusResponse['status'];
  width?: number;
}

// StatusIcon is the icon that is used in the CheckupTable to show the status of a checkup.  Currently, we are only
// storing the status of a checkup as either 'not_complete', 'complete', or null, but more statuses could/should be
// added in the future.
export const StatusIcon: FunctionComponent<StatusIconProps> = ({ status, width = 18 }) => {
  switch (status) {
    case 'not_complete':
      return <CircleEllipsis width={width} />;
    case 'complete':
      return (
        <Tooltip>
          <TooltipTrigger>
            <CheckCircle width={width} className="text-primary" />
          </TooltipTrigger>
          <TooltipContent>
            <p>Checkup has been completed.</p>
          </TooltipContent>
        </Tooltip>
      );
    default:
      return (
        <Tooltip>
          <TooltipTrigger>
            <Circle width={width} className="text-secondary" />
          </TooltipTrigger>
          <TooltipContent>
            <p>Checkup has not been completed yet.</p>
          </TooltipContent>
        </Tooltip>
      );
  }
};
