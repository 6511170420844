import React, { useEffect } from 'react';
import { ArrowDownIcon, ArrowUpIcon, CaretSortIcon } from '@radix-ui/react-icons';
import { Column } from '@tanstack/react-table';
import { cn } from '@companion-professional/webutils';

// ColumnHeaderCycleSort is used to render the header of a column that can be sorted.  If the column is sortable, this
// component will render a clickable icon that will cycle through the sorting states.  If the column is not sortable,
// only the title will be rendered.
interface ColumnHeaderCycleSortProps<TData, TValue> extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>;
  title: string;

  // setInitialSort is an optional prop that determines the initial sort state of the column.  If set to 'desc', the
  // column will be sorted in descending order.  If set to 'asc', the column will be sorted in ascending order.
  setInitialSort?: 'desc' | 'asc';
}

export function ColumnHeaderCycleSort<TData, TValue>({
  column,
  title,
  className,
  setInitialSort
}: ColumnHeaderCycleSortProps<TData, TValue>) {
  if (!column.getCanSort()) {
    return <div className={cn(className)}>{title}</div>;
  }

  useEffect(() => {
    if (setInitialSort === 'desc') {
      column.toggleSorting(true);
    } else if (setInitialSort === 'asc') {
      column.toggleSorting(false);
    }
  }, []);

  return (
    <div className={cn('flex items-center justify-start space-x-2', className)}>
      <span>{title}</span>
      {column.getIsSorted() === 'desc' ? (
        <ArrowDownIcon className="ml-2 h-4 w-4" onClick={() => column.toggleSorting(false)} />
      ) : column.getIsSorted() === 'asc' ? (
        <ArrowUpIcon className="ml-2 h-4 w-4" onClick={() => column.clearSorting()} />
      ) : (
        <CaretSortIcon className="ml-2 h-4 w-4" onClick={() => column.toggleSorting(true)} />
      )}
    </div>
  );
}
