import { ClipboardCheck, Clipboard } from 'lucide-react';
import { SurveyInfo } from '@companion-professional/dctypes';
import { FilterSettings } from '@companion-professional/components';

export const getSurveyFilterSettings = (surveys: SurveyInfo[]): FilterSettings[] => {
  const filterSettings: FilterSettings[] = [];

  surveys.forEach((survey) => {
    filterSettings.push({
      title: survey.title || survey.name,
      options: [
        {
          value: 'not_started',
          label: 'Not Started',
          icon: Clipboard
        },
        {
          value: 'answered',
          label: 'Answered',
          icon: ClipboardCheck
        }
      ],
      columnId: `survey_${survey.survey_id}`,
      makeSticky: true
    });
  });
  return filterSettings;
};
