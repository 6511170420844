import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { QueryLoaderWrapper, toast } from '@companion-professional/components';
import { PetAndClinicInfo } from '@companion-professional/dctypes';
import { useSurveyAnswers } from '../../hooks/useSurveyAnswers';
import { SurveyDisplay } from '../../components/SurveyDisplay';
import { useUpdateAnswers } from '../../hooks/useUpdateAnswers';
import { PageNotFound } from '../PageNotFound';

type AnswersParams = {
  answersId: string;
};

interface AnswersProps {
  pet: PetAndClinicInfo;
}

export const Answers: FunctionComponent<AnswersProps> = ({ pet }) => {
  const { answersId } = useParams<AnswersParams>();
  if (!answersId) {
    return <PageNotFound />;
  }

  const { answers, isPending: answersIsPending, error: errorAnswers } = useSurveyAnswers(answersId);
  const { updateAnswers, error: errorUpdatingAnswers, isPending: updatingAnswersIsPending } = useUpdateAnswers();

  return (
    <div className="flex flex-1 flex-col items-center">
      <div className="w-full max-w-screen-xl">
        <QueryLoaderWrapper isPending={answersIsPending} error={errorAnswers}>
          {answers?.survey_id ? (
            <QueryLoaderWrapper isPending={updatingAnswersIsPending} error={errorUpdatingAnswers}>
              {answers?.survey_id ? (
                <SurveyDisplay
                  pet={pet}
                  checkupRequestIds={answers.checkup_request_ids}
                  surveyId={answers.survey_id}
                  answers={answers.answers}
                  answersSurveyVersion={answers.version}
                  onSurveySubmit={async (formData, survey, checkupsToAdd = []) => {
                    try {
                      await updateAnswers({
                        surveyId: survey.survey_id,
                        version: survey.version,
                        answers: formData,
                        answersId: answersId,
                        checkupsToAdd
                      });

                      toast.success('Answers updated!');
                    } catch (e) {
                      // The hook should handle the error, also logging here for good measure.
                      console.error(e);
                    }
                  }}
                />
              ) : null}
            </QueryLoaderWrapper>
          ) : null}
        </QueryLoaderWrapper>
      </div>
    </div>
  );
};
