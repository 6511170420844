import React, { FunctionComponent } from 'react';
import { DynamicFieldProps } from '../DynamicField';
import {
  Answers,
  CheckboxGroupFieldSchema,
  CheckboxGroupField,
  CheckboxGroupValues,
  CheckboxGroupValuesSchema,
  CheckboxGroupValue
} from '../dynamicFormSchema';
import { CheckboxGroup } from '../../CheckboxGroup';
import { DynamicFieldComponent } from './DynamicFieldComponentBase';

export const CheckboxGroupFieldComponent: FunctionComponent<DynamicFieldProps> = ({ field, reactHookField }) => {
  const cg = CheckboxGroupFieldSchema.parse(field);

  return (
    <CheckboxGroup
      {...cg}
      values={reactHookField.value}
      disabled={reactHookField.disabled}
      onChange={(value) => {
        reactHookField.onChange(value);
      }}
      expandedRadioOptions={cg.expandedRadioOptions}
    />
  );
};

export class DynamicCheckboxGroupField extends DynamicFieldComponent<CheckboxGroupField, CheckboxGroupValues> {
  Component = CheckboxGroupFieldComponent;

  constructor(field: CheckboxGroupField) {
    super(field);
  }

  getAnswer(answers: Answers): CheckboxGroupValues {
    const answer = answers[this.field.name];
    if (answer === undefined) {
      return this.getDefaultValue();
    }

    if (CheckboxGroupValuesSchema.safeParse(answer).success) {
      return answer as CheckboxGroupValues;
    }

    // The following is added for reverse compatibility with the old CheckboxField.  The old shape was:
    // { [option_value: string]: boolean | string }
    // and the new shape is:
    // { [option_value: string]: { checked: boolean; otherText?: string } }
    if (typeof answer === 'object') {
      const values: CheckboxGroupValues = {};
      Object.entries(answer as Record<string, unknown>).forEach(([key, value]) => {
        if (typeof value === 'boolean') {
          values[key] = {
            checked: value as boolean
          };
        } else if (typeof value === 'string') {
          values[key] = {
            checked: true,
            otherText: value
          };
        }
      });
      return values;
    }

    throw new Error(`Invalid answer for field ${this.field.name}`);
  }

  getDefaultValue(): CheckboxGroupValues {
    // TODO: Implement a way to set the default value for the CheckboxGroupField.  This would most likely be an array of
    //   values to check, or a default value for each checkbox option.
    return {};
  }

  getValueToSubmit(value: CheckboxGroupValues): Record<string, boolean | string | CheckboxGroupValue> {
    const returnValues: Record<string, boolean | string | CheckboxGroupValue> = {};
    Object.keys(value).forEach((key) => {
      if (value[key]?.radioValue) {
        const newValue: CheckboxGroupValue = {
          checked: !!value[key]?.checked
        };

        if (value[key]?.radioValue) {
          newValue.radioValue = value[key]?.radioValue;
        }

        if (value[key]?.otherText) {
          newValue.otherText = value[key]?.otherText;
        }

        returnValues[key] = newValue;
      } else if (value[key]?.otherText) {
        returnValues[key] = value[key]?.otherText || '';
      } else {
        returnValues[key] = !!value[key]?.checked;
      }
    });

    return returnValues;
  }
}
