import React, { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  DialogClose,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Input,
  Label,
  QueryLoaderWrapper
} from '@companion-professional/components';
import { QuickCheckupFormValues, useQuickCheckupCreate } from '../../hooks/useQuickCheckupCreate';
import { useCheckupUrl } from '../../hooks/useCheckupUrl';

export type CheckupData = {
  clinicPetId: string;
  checkupId: string;
  checkupUrl: string;
};

interface CreateQuickCheckupProps {
  setCheckupData: (checkupData: CheckupData) => void;
  setPetIdFound: (petIdNotFound: boolean) => void;
  setPetId: (petId: string) => void;
  autofillPetId?: string;
}

// CreateQuickCheckup is the first step of the quick checkup flow where the user can enter a clinic pet id (an id that
// comes from their PIMs) and create a new checkup.
export const CreateQuickCheckup: FunctionComponent<CreateQuickCheckupProps> = ({
  setCheckupData,
  setPetIdFound,
  setPetId,
  autofillPetId
}) => {
  const { register, handleSubmit, reset, formState } = useForm<QuickCheckupFormValues>();
  const { createQuickCheckup, error: createCheckupError, isPending: createCheckupIsPending } = useQuickCheckupCreate();
  const { getCheckupUrl, error: getCheckupUrlError, isPending: getCheckupUrlIsPending } = useCheckupUrl();

  return (
    <form
      onSubmit={handleSubmit((values) => {
        createQuickCheckup(values).then((res) => {
          // Successfully updated, reset the form
          const checkupUrl = getCheckupUrl(res.id);
          if (checkupUrl) {
            setCheckupData({
              clinicPetId: values.clinicPetId,
              checkupId: res.id,
              checkupUrl
            });
          }
          setPetId(res.pet_id);
          setPetIdFound(!res.unknown_pet_id);
          reset();
        });
      })}
    >
      <DialogHeader>
        <DialogTitle>Create Quick Checkup</DialogTitle>
        <DialogDescription>
          Enter the pet id from your Patient Information Management System. If the pet id doesn't exist in our system,
          we will create a new pet entry.
        </DialogDescription>
      </DialogHeader>
      <QueryLoaderWrapper
        isPending={getCheckupUrlIsPending || createCheckupIsPending}
        error={getCheckupUrlError || createCheckupError}
        showChildrenOnError
      >
        <div className="mb-8 mt-4 flex flex-col space-y-5">
          <div className="flex flex-col space-y-1">
            <Label htmlFor="clinicPetId">Pet Id (from PIMs)</Label>
            <Input
              id="clinicPetId"
              aria-invalid={formState.errors.clinicPetId ? 'true' : 'false'}
              defaultValue={autofillPetId}
              {...register('clinicPetId', { required: true })}
            />
            {formState.errors.clinicPetId && formState.errors.clinicPetId.type === 'required' ? (
              // TODO: This should be it's own <InputError /> component
              <span className="text-destructive">The Pet Id is required.</span>
            ) : null}
          </div>
        </div>
      </QueryLoaderWrapper>
      <DialogFooter className="flex flex-row items-end space-x-2 md:items-center">
        <DialogClose asChild>
          <Button size="small" type="button" variant="secondary">
            Close
          </Button>
        </DialogClose>
        <Button disabled={getCheckupUrlIsPending || createCheckupIsPending} size="small" type="submit" className="ml-3">
          {getCheckupUrlIsPending || createCheckupIsPending ? 'Creating Checkup...' : 'Create Checkup'}
        </Button>
      </DialogFooter>
    </form>
  );
};
