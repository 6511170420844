import React, { FunctionComponent, useEffect, useState } from 'react';
import { Label } from '../Label';
import { Input } from '../Input';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '../Select';

type SuffixSelectOption = {
  label: string;
  value: string;
};

export type InputWithSuffixSelectValues = {
  input?: string;
  suffix?: string;
};

interface InputWithSuffixSelectProps {
  // name is the name of this form entry.  This is used to generate the ids/keys for the various components.  This
  // should be unique to the form.
  name: string;

  // label is the text that appears above the input field.
  label: string;

  // description (if provided) is some smaller text that appears below the label.
  description?: string;

  // suffixOptions is a list of options for the suffix dropdown (a select box after the input field).  Each option
  // should have a label (what's displayed) and a value.
  suffixOptions?: SuffixSelectOption[];

  // suffixValue is the current value of the suffix dropdown.  This should be one of the values from suffixOptions.
  suffixValue?: string;

  // inputValue is the current value of the input field.
  inputValue?: string;

  // onChange is a function that is called when the value of the input field or suffix dropdown changes.
  onChange?: (values: InputWithSuffixSelectValues) => void;

  // disabled is a boolean that determines if this input field is disabled (this includes both the text field and
  //  suffix dropdown).
  disabled?: boolean;

  // inputType is the type of the input field.  This can be either 'text' or 'number'.
  inputType?: 'text' | 'number';
}

// InputWithSuffixSelect is a combination of an input field and a dropdown field tacked on to the end.  The is most
// useful in situations that require units (e.g. "Enter dogs weight" with a dropdown of "kg" and "lbs")
export const InputWithSuffixSelect: FunctionComponent<InputWithSuffixSelectProps> = ({
  name,
  label,
  description,
  inputValue,
  suffixValue,
  suffixOptions = [],
  onChange = () => {},
  disabled = false,
  inputType = 'text'
}) => {
  const [suffixSelectValue, setSuffixSelectValue] = useState<string | undefined>(suffixOptions?.[0]?.value);
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    if (typeof inputValue === 'string' && inputValue !== value) {
      setValue(inputValue);
    }
  }, [inputValue]);

  useEffect(() => {
    if (suffixOptions.some((so) => so.value === suffixValue)) {
      setSuffixSelectValue(suffixValue);
    }
  }, [suffixValue]);

  useEffect(() => {
    onChange({ input: value, suffix: suffixSelectValue });
  }, [value, suffixSelectValue]);

  return (
    <div>
      <Label htmlFor={name} description={description} className="mb-2">
        {label}
      </Label>
      <div className="flex flex-row gap-2">
        <Input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          id={name}
          disabled={disabled}
          type={inputType}
        />
        <Select
          value={suffixSelectValue}
          onValueChange={(value) => {
            setSuffixSelectValue(value);
          }}
          disabled={disabled}
        >
          <SelectTrigger className="w-[180px]" data-testid={`${name}-suffix`}>
            <SelectValue className="truncate" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {suffixOptions.map((so) => (
                <SelectItem key={`suffix-${name}-${so.value}`} value={so.value}>
                  {so.label}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
    </div>
  );
};
