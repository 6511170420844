import React from 'react';
import { Column } from '@tanstack/react-table';
import { isSameDay } from 'date-fns';
import { DateRange } from 'react-day-picker';
import { DateRangePicker } from '../DateRangePicker';

interface DateRangeFilterProps<TData, TValue> {
  column?: Column<TData, TValue>;
}

export function DateRangeFilter<TData, TValue>({ column }: DateRangeFilterProps<TData, TValue>) {
  if (!column) {
    return null;
  }

  return (
    <DateRangePicker
      value={column.getFilterValue() as DateRange}
      dateFormat="P"
      onValueChange={(value) => {
        // Because "value" and "column.getFilterValue()" reference different objects, we need to compare the values of
        // the "to" and "from" dates inside each object to determine if we should update the react-table column filter
        // value.
        // The following prevents rendering cycles where the actual date range values have not changed, but the object
        // is new.  This happens when the initial state of the table is set with a range value.  We want to reflect
        // that value in the date range picker, but we don't want to update the column filter value; react-table is
        // will do that as part of the initial render.

        const currentRange = column.getFilterValue() as DateRange;

        // Are the new and current values the same?  NOTE this will only be true if both are undefined
        if (value?.to === currentRange?.to && !value?.from && !currentRange?.from) {
          return;
        }

        // Have either the to or from been cleared?
        if (!value?.to || !value?.from) {
          column.setFilterValue(value);
          return;
        }

        // Has the day changed?
        const isSameToDay = currentRange?.to && isSameDay(currentRange.to, value.to);
        const isSameFromDay = currentRange?.from && isSameDay(currentRange.from, value.from);
        if (!isSameToDay || !isSameFromDay) {
          column.setFilterValue(value);
        }
      }}
    />
  );
}
