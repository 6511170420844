import { useQuery } from '@tanstack/react-query';
import { PetAndClinicInfo } from '@companion-professional/dctypes';
import { getPet } from '../lib/api';

// usePet is a hook that fetches a single pet using the provided petId.
//
// It returns:
// - isPending: A boolean that indicates if the request is currently in progress.
// - error: An error object if the request failed.
// - pet: The pet (PetAndClinicInfo) for the provided petId.
export const usePet = (petId: string) => {
  const {
    isPending,
    error,
    data: pet
  } = useQuery<PetAndClinicInfo>({
    queryKey: ['pet', petId],
    queryFn: () => getPet(petId)
  });

  return {
    isPending,
    error,
    pet
  };
};
