import React, { FunctionComponent, useMemo } from 'react';
import { LogOut, Settings, User } from 'lucide-react';
import JsGravatar from 'js-gravatar';
import { useNavigate } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@companion-professional/components';
import { useStateLogout, useEmail } from '../../state/auth';
import { commitHash, version } from '../../lib/version';

// The UserMenu is the little "bug" (round profile image) in the top right corner of the screen (in the Header).  When
// clicked, it opens a menu that contains the user's profile, settings, logout, etc. options.
// TODO: Implement the navigation for the Settings and Profile options.
export const UserMenu: FunctionComponent = () => {
  const navigate = useNavigate();
  const logout = useStateLogout();
  const email = useEmail();

  const gravatarUrl = useMemo<string>(() => {
    const defaultEmail = 'user@email.com';
    return JsGravatar({ email: email || defaultEmail, size: 34, defaultImage: 'identicon' });
  }, [email]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        {/* TODO: Replace this with the user's profile image (if/when we have it in the user's profile).  For now this
              is currently using a gravatar image. */}
        <img src={gravatarUrl} className="rounded-full" style={{ height: 34, width: 34 }} alt="" loading="lazy" />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuGroup>
          <DropdownMenuItem
            onClick={() => {
              navigate('/profile');
            }}
          >
            <User className="mr-2 h-4 w-4" />
            <span>Profile</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => {
              navigate('/settings');
            }}
          >
            <Settings className="mr-2 h-4 w-4" />
            <span>Settings</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          onClick={() => {
            logout();
          }}
        >
          <LogOut className="mr-2 h-4 w-4" />
          <span>Log out</span>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuLabel>
          Version {version}
          {/* The commit hash is hidden (same color as background) so we don't frighten or alarm the user.  This can be
          highlighted so that we can view it. */}
          <span className="text-widget">-{commitHash}</span>
        </DropdownMenuLabel>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
