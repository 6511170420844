import { Field, FIELD_TYPE } from './dynamicFormSchema';
import { RegisterOptions, Validate } from 'react-hook-form/dist/types/validator';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { Path } from 'react-hook-form';
import { InputWithSuffixSelectValues } from '../InputWithSuffixSelect';
import { isStringANumber } from '@companion-professional/webutils';

type ValidatorResponse = {
  // rules is a set of built-in react-hook-form validation rules that should be configured as part of the validation
  // for the field.  More info can be found here: https://react-hook-form.com/docs/useform/register#options
  rules: RegisterOptions<FieldValues, Path<FieldValues>>;

  // validateRules are a set of custom validation rules that should be configured as part of the validation for the
  // field.  These are custom validation rules that are not built into react-hook-form.
  validateRules: Record<string, Validate<any, FieldValues>>;
};
export type Validator = (field: Field) => ValidatorResponse;

// validators is an object that contains a set of validation rules that can be applied to a field.  Each validator is a
// function that takes a field object and returns an object with two fields: rules and validateRules.
export const validators: Record<string, Validator> = {
  // required adds validation such that the form can not be submitted without this field being filled out.
  required: (field) => {
    const validateRules: Record<string, Validate<any, FieldValues>> = {};

    const errorMessage = `${field.label} is required.`;

    // The textWithSuffix field type requires a custom validation rule (as it returns an object with two fields,
    // and we want to make sure both are filled out)
    if (field.type === FIELD_TYPE.textWithSuffix) {
      validateRules.required = (values: InputWithSuffixSelectValues) =>
        !(!values?.input || values.input === '' || !values?.suffix || values.suffix === '') ? true : errorMessage;
    } else if (field.type === FIELD_TYPE.radio) {
      validateRules.required = (value: string | { value: string }) => {
        if (typeof value === 'object') {
          return value.value.length > 0 || errorMessage;
        }
        return value.length > 0 || errorMessage;
      };
    }

    return {
      rules: {
        required: { value: true, message: errorMessage }
      },
      validateRules
    };
  },

  // mustBeNumber adds validation to a field to ensure that the value is a number (the form can not be submitted if the
  // value is not a number).
  mustBeNumber: (field) => {
    const validateRules: Record<string, Validate<any, FieldValues>> = {};

    const errorMessage = `${field.label} must be a number.`;
    if (field.type === FIELD_TYPE.textWithSuffix) {
      // This just checks the input field part of the element.
      validateRules.mustBeNumber = (values: InputWithSuffixSelectValues) => {
        if (!values?.input || values.input === '') {
          // If the input field is empty, we don't want to show an error message.
          return true;
        }
        return isStringANumber(values?.input || '') ? true : errorMessage;
      };
    } else {
      validateRules.mustBeNumber = (value: string) => (value === '' || isStringANumber(value) ? true : errorMessage);
    }

    return {
      rules: {},
      validateRules
    };
  }
} as const;

// ValidatorName is a type that represents the keys of the validators object.  Adding an entry to validators will
// automatically add a new entry to this type.
export type ValidatorName = keyof typeof validators;

// isValidator checks if the key is a valid ValidatorName.  This acts as a type guard.
export const isValidator = (key: string): key is ValidatorName => {
  return !!(
    key in validators &&
    validators?.[key as ValidatorName] &&
    typeof validators[key as ValidatorName] === 'function'
  );
};

// getValidationRulesForField returns the validation rules for the provided field.
export const getValidationRulesForField = (field: Field): RegisterOptions<FieldValues, Path<FieldValues>> => {
  let rules: RegisterOptions<FieldValues, Path<FieldValues>> = {};
  const validationRules: Record<string, Validate<any, FieldValues>> = {};

  const fieldValidators = field.validators || [];

  // Apply simple validators
  fieldValidators.forEach((validatorName: string) => {
    if (isValidator(validatorName)) {
      const getValidationRules = validators[validatorName] as Validator;
      const { rules: fieldRules, validateRules: fieldValidationRules } = getValidationRules(field);
      Object.assign(rules, fieldRules);
      Object.assign(validationRules, fieldValidationRules);
    } else {
      // NOTE: This should never happen, the schema should be validated before this point.
      throw new Error(`Unknown validator: ${validatorName}`);
    }
  });

  // Apply special required validation for checkboxGroup with expanded radio options.  This is a special case because
  // the checkboxes are not required, but we want to require that a radio option is selected if the parent checkbox
  // is checked.
  if (field.type === FIELD_TYPE.checkboxGroup && field?.expandedRadioOptions) {
    validationRules.requireRadioToBeSelected = (value: Record<string, { checked: boolean; radioValue?: string }>) => {
      for (const k in value) {
        if (value[k]?.checked && (!value[k]?.radioValue || value[k]?.radioValue === 'undefined')) {
          return 'Please select an option for each checked box.';
        }
      }
      return true;
    };
  }

  // Apply special min/max validation for text and textWithSuffix fields
  if (field.type === FIELD_TYPE.text || field.type === FIELD_TYPE.textWithSuffix) {
    if (field.minValue !== undefined) {
      validationRules.requireMinValue = (value: string) => {
        if (value === '') {
          return true;
        }

        if (field.minValue !== undefined && parseFloat(value) < field.minValue) {
          return `${field.label} must be greater than or equal to ${field.minValue}.`;
        }
        return true;
      };
    }
    if (field.maxValue !== undefined) {
      validationRules.requireMaxValue = (value: string) => {
        if (value === '') {
          return true;
        }

        if (field.maxValue !== undefined && parseFloat(value) > field.maxValue) {
          return `${field.label} must be less than or equal to ${field.maxValue}.`;
        }
        return true;
      };
    }
  }

  rules.validate = validationRules;
  return rules;
};
