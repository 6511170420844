import React from 'react';
import { MoreVertical } from 'lucide-react';
import { Separator } from '../Separator';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger
} from '../DropdownMenu';
import { Button } from '../Button';
import { BulkOperation, BulkOptionMenuItem } from './dataTableTypes';

// See BulkOperationHeader.tsx for the definition of the BulkOperationTitleProps interface.
interface BulkOperationTitleAndMenuProps<TData> {
  title: string;
  bulkOptionMenuItems: BulkOptionMenuItem<TData>[];
  setSelectingRows: (selectingCheckups: boolean) => void;
  setCurrentBulkOperations: React.Dispatch<React.SetStateAction<BulkOperation<TData>[]>>;
}

// BulkOperationTitleAndMenu is a component that displays the title and the left bulk operation dropdown menu.
export function BulkOperationTitleAndMenu<TData>({
  title,
  bulkOptionMenuItems,
  setSelectingRows,
  setCurrentBulkOperations
}: BulkOperationTitleAndMenuProps<TData>): React.ReactNode {
  return (
    <>
      <div className="text-xl font-semibold">{title}</div>
      <div className="flex flex-row items-center justify-end">
        <Separator orientation="vertical" className="mx-2 h-6" />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="icon">
              <MoreVertical className="h-4 w-4" />
              <span className="sr-only">More</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>Multiple Select</DropdownMenuLabel>
            {bulkOptionMenuItems.map((item, index) => (
              <DropdownMenuItem
                key={index}
                onClick={() => {
                  if (item?.onActive) {
                    item.onActive();
                  }
                  setSelectingRows(true);
                  setCurrentBulkOperations(item.operations || []);
                }}
              >
                {item.icon}
                <span>{item.label}</span>
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </>
  );
}
