import React, { FunctionComponent } from 'react';
import { ArrowUp } from 'lucide-react';

interface InputErrorProps {
  children?: React.ReactNode;
}

// InputError is a component that displays an error message for an input field.  This should be placed directly after
// the input field it is associated with.
export const InputError: FunctionComponent<InputErrorProps> = ({ children }) => {
  return (
    <div className="flex gap-1 text-destructive">
      <ArrowUp width={16} />
      {children}
    </div>
  );
};
