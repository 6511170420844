import React, { FunctionComponent } from 'react';
import { CheckupRequestInfo } from '@companion-professional/dctypes';
import { cn } from '@companion-professional/webutils';
import { Copy, ExternalLink, QrCode } from 'lucide-react';
import {
  buttonVariants,
  QueryLoaderWrapper,
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from '@companion-professional/components';
import { copyRequestUrlToClipboard } from '../../lib/copy';
import { useCheckupUrl } from '../../hooks/useCheckupUrl';
import { QRCodeDialog } from '../QRCodeDialog';

interface CheckupLinkButtonsProps {
  checkupRequest: CheckupRequestInfo;
}

export const CheckupLinkButtons: FunctionComponent<CheckupLinkButtonsProps> = ({ checkupRequest }) => {
  const { getCheckupUrl, error, isPending } = useCheckupUrl();

  return (
    <QueryLoaderWrapper isPending={isPending} error={error}>
      <div className="space-x-2">
        <Tooltip>
          <TooltipTrigger
            onClick={() => {
              const checkupUrl = getCheckupUrl(checkupRequest.id);
              window.open(checkupUrl, '_blank');
            }}
          >
            <div className={cn(buttonVariants({ variant: 'primary', size: 'icon' }))}>
              <ExternalLink />
            </div>
          </TooltipTrigger>
          <TooltipContent>
            <p>Open the checkup in a new tab.</p>
          </TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger>
            <QRCodeDialog checkupRequestId={checkupRequest.id}>
              <div className={cn(buttonVariants({ variant: 'primary', size: 'icon' }))}>
                <QrCode />
              </div>
            </QRCodeDialog>
          </TooltipTrigger>
          <TooltipContent>
            <p>Show a QR code of the checkup URL.</p>
            <p>You can scan this with your phone to open the checkup.</p>
          </TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger
            onClick={() => {
              const checkupUrl = getCheckupUrl(checkupRequest.id);
              copyRequestUrlToClipboard(checkupUrl);
            }}
          >
            <div className={cn(buttonVariants({ variant: 'primary', size: 'icon' }))}>
              <Copy />
            </div>
          </TooltipTrigger>
          <TooltipContent>
            <p>Copy the checkup URL to your clipboard.</p>
          </TooltipContent>
        </Tooltip>
      </div>
    </QueryLoaderWrapper>
  );
};
