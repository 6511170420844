import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useResetSwitchClinicId, useSetToken } from '../state/auth';
import { changeClinicLogin } from '../lib/api';

// useChangeClinic is a hook that returns the state (isPending and errorMessage) and a function for initiating a
// change to the clinic that the user is logged into.  changeClinic is a promise that resolves to a string of the
// user's new token.
export const useChangeClinic = () => {
  const queryClient = useQueryClient();
  const setToken = useSetToken();
  const resetSwitchClinicId = useResetSwitchClinicId();

  const {
    mutateAsync: changeClinic,
    isPending,
    error
  } = useMutation({
    mutationFn: async (clinicId: string) => {
      const token = await changeClinicLogin(clinicId);
      setToken(token);

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['user-accounts']
        }),
        queryClient.invalidateQueries({
          queryKey: ['active-checkups']
        }),
        queryClient.invalidateQueries({
          queryKey: ['pets-for-clinic']
        }),
        queryClient.invalidateQueries({
          queryKey: ['current-clinic']
        })
      ]);

      // If the user indicates they don't want to switch clinics you can call this to clear the "to-be-switched-to" clinic id.
      resetSwitchClinicId();
      return token;
    }
  });

  return {
    isPending,
    error,
    changeClinic
  };
};
