import React, { FunctionComponent, useState } from 'react';
import { CalendarDays } from 'lucide-react';
import {
  Button,
  Calendar,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  IconTextButton
} from '@companion-professional/components';

// ScheduleCheckupDialog is a dialog that will eventually allow a user to schedule a checkup request to be sent (via
// email? sms?) on a future date.  For now, it just shows a calendar and the "Save" button is disabled.
export const ScheduleCheckupDialog: FunctionComponent = () => {
  const [date, setDate] = useState<Date | undefined>(new Date());

  return (
    <Dialog>
      <DialogTrigger asChild>
        <IconTextButton
          variant="primary"
          size="small"
          className="flex items-center justify-center space-x-2 px-4"
          Icon={CalendarDays}
        >
          Schedule Checkup Request
        </IconTextButton>
      </DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Schedule Checkup</DialogTitle>
          <DialogDescription>Schedule a checkup request to be sent on a future date.</DialogDescription>
        </DialogHeader>
        <div className="flex flex-row items-center justify-center py-8">
          <Calendar mode="single" selected={date} onSelect={setDate} className="rounded-md border" />
        </div>
        <DialogFooter>
          <Button size="small" type="submit" variant="primary" disabled>
            Save
          </Button>
          <DialogClose asChild>
            <Button size="small" type="button" variant="secondary">
              Close
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
