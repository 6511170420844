import React, { FunctionComponent } from 'react';

export const PageNotFound: FunctionComponent = () => {
  return (
    <div className="m-10 flex flex-col items-center">
      <div className="text-6xl font-bold text-destructive">404</div>
      <h1 className="text-2xl">Page Not Found</h1>
    </div>
  );
};
