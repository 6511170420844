import React from 'react';
import { CheckIcon } from 'lucide-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { archivePetAndCheckups } from '../lib/api';
import { toast } from '@companion-professional/components';

export type ArchivePetValues = {
  // petId is the ID of the pet to archive.
  petId: string;
};

// useArchivePet is a hook that returns a function that archives a pet.
export const useArchivePet = () => {
  const queryClient = useQueryClient();

  const {
    isPending,
    error,
    mutateAsync: archivePet
  } = useMutation({
    mutationFn: async (values: ArchivePetValues) => {
      const res = await archivePetAndCheckups(values.petId);
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['active-checkups']
        }),
        queryClient.invalidateQueries({
          queryKey: ['pets-for-clinic']
        })
      ]);

      toast('Pet Archived', {
        description: 'The pet has been successfully archived.',
        icon: <CheckIcon />
      });

      return res;
    }
  });

  return {
    isPending,
    error,
    archivePet
  };
};
