import { CheckCircle, Loader, AlertTriangleIcon, CircleIcon } from 'lucide-react';
import { FilterOption } from '@companion-professional/components';
import { CheckupRequestAnalysisStatus } from '@companion-professional/dctypes';

// NOTE: If more statuses or analysisStatuses are added to the API model, they will need to be added here as well (if
// we want them to be "filterable").

// statuses are all the statuses that can be filtered by in the checkup table.
export const statuses: FilterOption[] = [
  {
    value: 'complete',
    label: 'Video Uploaded',
    icon: CheckCircle
  },
  {
    value: 'not_started',
    label: 'Not Started',
    icon: CircleIcon
  }
];

// analysisStatuses are all the statuses for the analysis process that can be filtered by in the checkup table.
export const analysisStatuses: FilterOption<CheckupRequestAnalysisStatus>[] = [
  // not_started is the status when the checkup has not been submitted for analysis yet (i.e. the video or videos
  // associated with the checkup have not been uploaded or sent to be processed).
  {
    value: 'not_started',
    label: 'Not Started',
    icon: CircleIcon
  },

  // processing is the status when the checkup has been submitted for analysis and is currently being processed (i.e.
  // ML model is running, etc.).
  {
    value: 'processing',
    label: 'Processing',
    icon: Loader
  },

  // gait_anomaly is the status when the analysis has been completed and the ML model has detected a gait anomaly.
  {
    value: 'gait_anomaly',
    label: 'Gait Anomaly',
    icon: AlertTriangleIcon
  },

  // no_anomaly_detected is the status when the analysis has been completed and the ML model has not detected a gait.
  {
    value: 'no_anomaly_detected',
    label: 'No Anomaly Detected',
    icon: CheckCircle
  }
];
