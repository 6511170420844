import React from 'react';
import { CheckIcon } from 'lucide-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { archiveCheckupRequest } from '../lib/api';
import { toast } from '@companion-professional/components';

export type ArchiveCheckupValues = {
  // checkupRequestId is the ID of the checkup request to archive.
  checkupRequestId: string;

  // petId is the ID of the pet that the checkup is associated.  If provided, the pet's checkups cached query will be
  // invalidated.
  petId?: string;
};

// useArchiveCheckup is a hook that returns a function that archives a checkup.
export const useArchiveCheckup = () => {
  const queryClient = useQueryClient();

  const {
    isPending,
    error,
    mutateAsync: archiveCheckup
  } = useMutation({
    mutationFn: async (values: ArchiveCheckupValues) => {
      const res = await archiveCheckupRequest(values.checkupRequestId);
      await queryClient.invalidateQueries({
        queryKey: ['active-checkups']
      });

      if (values.petId) {
        await queryClient.invalidateQueries({
          queryKey: ['pet-checkups', values.petId]
        });
      }

      toast('Checkup Archived', {
        description: 'The checkup has been successfully archived.',
        icon: <CheckIcon />
      });

      return res;
    }
  });

  return {
    isPending,
    error,
    archiveCheckup
  };
};
