import React from 'react';
import { Table } from '@tanstack/react-table';
import { Cross2Icon } from '@radix-ui/react-icons';
import { Input } from '../Input';
import { FacetedFilter } from './FacetedFilter';
import { FilterSettings } from './dataTableTypes';
import { Button } from '../Button';
import { DateRangeFilter } from './DateRangeFilter';
import { useClearStoredFilters } from './filterState';

// See ./index.tsx for props documentation
interface FilterBarProps<TData> {
  table: Table<TData>;
  tableName: string;
  enableSearch: boolean;
  filterString: string;
  filterPlaceholder: string;
  setFilterString: (value: string) => void;
  filters: FilterSettings[];
  dateRangeFilterColumnId?: string;
}

export function FilterBar<TData>({
  table,
  tableName,
  enableSearch,
  filterString,
  filterPlaceholder,
  setFilterString,
  filters,
  dateRangeFilterColumnId
}: FilterBarProps<TData>) {
  // isFiltered is true if there are any column filters (like the FacetedFilter or DateRangeFilters) or a global
  // filter string (i.e. search string) is set.
  const isFiltered = table.getState().columnFilters.length > 0 || filterString.length > 0;
  const clearStoredFilters = useClearStoredFilters();

  return (
    <div className="mb-2 flex flex-row justify-between rounded-lg bg-gray-400/50 p-2">
      <div className="flex flex-row flex-wrap gap-x-4 gap-y-4">
        {enableSearch ? (
          <Input
            placeholder={filterPlaceholder}
            value={filterString}
            onChange={(event) => setFilterString(event?.target?.value)}
            className="h-9 w-[150px] lg:w-[300px]"
          />
        ) : null}

        {dateRangeFilterColumnId ? <DateRangeFilter column={table.getColumn(dateRangeFilterColumnId)} /> : null}

        {filters.map((filter, i) => {
          const column = table.getColumn(filter.columnId);

          if (!column) {
            return null;
          }

          return (
            <FacetedFilter
              key={`${filter.title}-${i}`}
              column={column}
              title={filter.title}
              options={filter.options}
              makeSticky={filter.makeSticky}
              tableName={tableName}
            />
          );
        })}
      </div>
      <div>
        {isFiltered && (
          <Button
            variant="ghost"
            size="small"
            onClick={() => {
              setFilterString('');
              table.resetColumnFilters();
              clearStoredFilters(tableName);
            }}
            className="h-8 px-2 hover:text-secondary lg:px-3"
          >
            Reset Filters
            <Cross2Icon className="ml-2 h-4 w-4" />
          </Button>
        )}
      </div>
    </div>
  );
}
