import React, { FunctionComponent } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { CSVLink } from 'react-csv';
import { cn } from '@companion-professional/webutils';
import { QueryLoaderWrapper, buttonVariants, Button } from '@companion-professional/components';
import { useAnsweredCheckupsWithUnlinkedVideos } from '../../hooks/useAnsweredCheckupsWithUnlinkedVideos';
import { Page } from '../../components/Page';
import { ReviewedCheckupsWithBonusVideosTable } from '../../components/ReviewCheckupsWithBonusVideosTable';
import { PageNotFound } from '../PageNotFound';

// getPathToSurvey returns the path to the survey for the given pet_id and answers_id.
const getPathToSurvey = (pet_id: string, answers_id: string) => `/#/pets/${pet_id}/answers/${answers_id}`;

// ReviewedCheckups displays a table of reviewed checkups for pets with bonus videos.  The rows link to the base
// answered survey. The table also has an export to CSV button.
export const ReviewedCheckups: FunctionComponent = () => {
  const navigate = useNavigate();
  const { surveyType } = useParams();
  if (!surveyType) {
    return <PageNotFound />;
  }
  const { answeredCheckupsWithUnlinkedVideos, isPending, error } = useAnsweredCheckupsWithUnlinkedVideos(surveyType);

  const csvData = [
    ['Pet Name', 'Clinic Name', 'Requested At', 'Number of Bonus Checkups', 'Link To Survey'],
    ...(answeredCheckupsWithUnlinkedVideos || []).map(
      ({ pet_name, clinic_name, requested_at, num_unanswered_checkups, pet_id, answers_id }) => [
        pet_name,
        clinic_name,
        format(parseISO(requested_at), 'MM/dd/yyyy hh:mm a'),
        num_unanswered_checkups,
        `${window.location.origin}${getPathToSurvey(pet_id, answers_id)}`
      ]
    )
  ];

  return (
    <Page title="Reviewed Checkups for Pets with Bonus Videos">
      <QueryLoaderWrapper error={error} isPending={isPending}>
        <div className="flex flex-col gap-4">
          <ReviewedCheckupsWithBonusVideosTable
            checkups={answeredCheckupsWithUnlinkedVideos || []}
            onOpen={(checkup) => {
              window.open(getPathToSurvey(checkup.pet_id, checkup.answers_id), '_blank');
            }}
          />
          <div className="flex flex-row justify-between">
            <Button
              variant="primary"
              size="small"
              onClick={() => {
                navigate('/reviews/dashboard');
              }}
            >
              Back
            </Button>
            <CSVLink
              className={cn(buttonVariants({ variant: 'secondary', size: 'small' }))}
              filename="pets_with_bonus_checkups.csv"
              data={csvData}
            >
              Export to CSV
            </CSVLink>
          </div>
        </div>
      </QueryLoaderWrapper>
    </Page>
  );
};
