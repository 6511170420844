import React, { FunctionComponent } from 'react';
import { PainSelectorFieldSchema, PainSelectorField, Answers } from '../dynamicFormSchema';
import { PainSelector } from '../../PainSelector';
import { DynamicFieldProps } from '../DynamicField';
import { DynamicFieldComponent } from './DynamicFieldComponentBase';
import { TypeConverterName, typeConverters } from '../typeConverters';
import { DiagnosisOptionSetName, DiagnosisOptionSets, DiagnosisValue, isDiagnosisValue } from '../../ImageDiagnosis';

export const PainSelectorFieldComponent: FunctionComponent<DynamicFieldProps> = ({ field, reactHookField }) => {
  const ps = PainSelectorFieldSchema.parse(field);

  return (
    <PainSelector
      value={reactHookField.value}
      onChange={reactHookField.onChange}
      disabled={reactHookField.disabled}
      label={ps.label}
      description={ps.description}
      name={ps.name}
      options={DiagnosisOptionSets[ps.diagnosisOptionSet as DiagnosisOptionSetName]}
    />
  );
};

export class DynamicPainSelectorField extends DynamicFieldComponent<PainSelectorField, DiagnosisValue> {
  Component = PainSelectorFieldComponent;

  constructor(field: PainSelectorField) {
    super(field);
  }

  getAnswer(answers: Answers): DiagnosisValue {
    const answer = answers[this.field.name];
    if (answer === undefined || answer === null) {
      return this.getDefaultValue();
    }

    if (isDiagnosisValue(answer)) {
      return answer;
    }
    throw new Error(`Answer for field ${this.field.name} is not a DiagnosisValue`);
  }

  getDefaultValue(): DiagnosisValue {
    return this.field.defaultValue || undefined;
  }

  getValueToSubmit(value: unknown): DiagnosisValue {
    return typeConverters[this.field.fieldType as TypeConverterName](value);
  }
}
