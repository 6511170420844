import { useQuery } from '@tanstack/react-query';
import { SurveyInfo } from '@companion-professional/dctypes';
import { getItemReviewSurveys } from '../lib/api';

// useItemSurveys is a hook that fetches all the item surveys available.
export const useItemSurveys = () => {
  const {
    isPending,
    error,
    data: itemSurveys
  } = useQuery<SurveyInfo[]>({
    queryKey: ['item-surveys'],
    queryFn: () => getItemReviewSurveys()
  });

  return {
    isPending,
    error,
    itemSurveys
  };
};
