import React, { FunctionComponent } from 'react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { useIsLoggedIn, usePasswordNeedsReset } from './state/auth';
import { LoggedInRoutes } from './LoggedInRoutes';
import { Login, LoginPasswordChange } from './pages';
import { useSetFeatureFlagContext } from './hooks/useSetFeatureFlagContext';

declare const __LAUNCH_DARKLY_CLIENT_ID__: string;

// App is the root component of the application. It is responsible for ensuring the user is logged in and rendering the
// appropriate content based on the logged in state.
export const App: FunctionComponent = () => {
  const isLoggedIn = useIsLoggedIn();
  const passwordNeedsReset = usePasswordNeedsReset();

  // Custom hook that sets the feature flag context (currently using the user email and clinic id).
  useSetFeatureFlagContext();

  if (!isLoggedIn) {
    return <Login />;
  }

  // If the user is logged in, but their password needs to be reset; show the password change form
  if (passwordNeedsReset) {
    return <LoginPasswordChange />;
  }

  return <LoggedInRoutes />;
};

// AppWithLDProvider is a wrapper around the App component that provides the LaunchDarkly context. This is required for
// LaunchDarkly to work.
export const AppWithLDProvider = withLDProvider({
  clientSideID: __LAUNCH_DARKLY_CLIENT_ID__
})(App);
