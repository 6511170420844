import React, { FunctionComponent } from 'react';
import { useSetMobileMenuOpen } from '../../state/layout';

interface ContentProps {
  children?: React.ReactNode;
}

export const ContentWrapper: FunctionComponent<ContentProps> = ({ children }) => {
  const setMobileMenuOpen = useSetMobileMenuOpen();

  return (
    <div
      className="flex-1 overflow-auto p-4"
      onClick={() => {
        setMobileMenuOpen(false);
      }}
    >
      {children}
    </div>
  );
};
