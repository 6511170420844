import React, { FunctionComponent } from 'react';
import { LoginLayout } from '../../components/LoginLayout';
import { LoginSplash } from '../../components/LoginSplash';
import { LoginForm } from './LoginForm';
import { useLogin } from '../../hooks/useLogin';

// Login is the page used for login.  It contains a Splash and the actual LoginForm
export const Login: FunctionComponent = () => {
  const { submitLoginForm, isPending, errorMessage } = useLogin();

  return (
    <LoginLayout>
      <LoginSplash />
      <LoginForm onFormSubmit={submitLoginForm} isLoggingIn={isPending} errorMessage={errorMessage} />
    </LoginLayout>
  );
};
