import React, { FunctionComponent, useEffect } from 'react';
import {
  Button,
  ErrorAlert,
  InputWithLabel,
  LoaderSpinner,
  QueryLoaderWrapper,
  toast
} from '@companion-professional/components';
import { useForm } from 'react-hook-form';
import { Archive, CheckIcon } from 'lucide-react';
import { PetAndClinicInfo } from '@companion-professional/dctypes';
import { UpdatePetInfoFormValues, useSavePetInfo } from '../../hooks/useSavePetInfo';
import { usePetStats } from '../../hooks/usePetStats';
import { ArchivePetDialog } from '../../components/ArchivePetDialog';

interface PetInfoFormProps {
  pet: PetAndClinicInfo;
}

export const PetInfoForm: FunctionComponent<PetInfoFormProps> = ({ pet }) => {
  const { isPending: savingPetInfo, errorMessage, savePetInfo } = useSavePetInfo();
  const { isPending: isPendingStats, error: errorStats, petStats } = usePetStats(pet.id);
  const { register, handleSubmit, reset, formState, clearErrors } = useForm<UpdatePetInfoFormValues>({
    defaultValues: { petName: pet.name, clinicPetId: pet.clinic_pet_id }
  });

  useEffect(() => {
    // Make sure the form is reset when the pet changes
    reset({ petName: pet.name, clinicPetId: pet.clinic_pet_id });
  }, [pet]);

  return (
    <form
      className="my-4 flex flex-col space-y-5"
      onSubmit={handleSubmit((values) => {
        clearErrors();
        savePetInfo({ ...values, petId: pet.id })
          .then(() => {
            toast('Pet Information Updated', {
              description: `Pet information for ${values.petName} has been updated.`,
              icon: <CheckIcon />
            });
          })
          .catch(() => {
            // Error is handled by the useSavePetInfo hook
          });
      })}
    >
      <InputWithLabel id="petName" label="Pet Name" required={true} {...register('petName')} />
      <InputWithLabel
        id="petClinicId"
        label="Pet Clinic Id"
        required={true}
        {...register('clinicPetId')}
        description="This is the unique ID that's used at your clinic for this pets identification."
      />
      {errorMessage ? <ErrorAlert title="Error Saving" message={errorMessage} /> : null}
      <div className="flex flex-row justify-end gap-2">
        <QueryLoaderWrapper isPending={isPendingStats} error={errorStats} size="small">
          <ArchivePetDialog pet={pet} returnUrl="/pets">
            <Button
              size="small"
              variant="destructive"
              type="button"
              disabled={!!(petStats?.num_items_uploaded || petStats?.num_surveys_answered)}
            >
              <Archive size={16} className="mr-2" /> Archive Pet
            </Button>
          </ArchivePetDialog>
        </QueryLoaderWrapper>

        {formState.isDirty ? (
          <>
            <Button
              type="button"
              variant="outline"
              size="small"
              onClick={() => {
                reset();
                clearErrors();
              }}
            >
              Reset
            </Button>

            <Button type="submit" variant={savingPetInfo ? 'outline' : 'primary'} size="small" disabled={savingPetInfo}>
              {savingPetInfo ? (
                <span className="flex flex-row items-center space-x-2">
                  <LoaderSpinner size="small" />
                  Saving ...
                </span>
              ) : (
                'Save Pet Info'
              )}
            </Button>
          </>
        ) : null}
      </div>
    </form>
  );
};
