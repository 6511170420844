import React, { FunctionComponent } from 'react';
import { IconTextButton } from '@companion-professional/components';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { PetCheckups } from './PetCheckups';
import { ScheduleCheckupDialog } from './ScheduleCheckupDialog';
import { Page } from '../../components/Page';
import { QuickCheckup } from '../../components/QuickCheckup';
import { Section } from '../../components/Section';
import { PetInfoForm } from './PetInfoForm';
import { PetAndClinicInfo } from '@companion-professional/dctypes';

interface PetProps {
  pet: PetAndClinicInfo;
}

// Pet is a page that shows the details of a pet.
// TODO: lots of opportunity for restyling here.  I haven't come up with a good design for displaying data yet, once I
//   do, I'll update this page.
export const Pet: FunctionComponent<PetProps> = ({ pet }) => {
  const navigate = useNavigate();

  return (
    <Page
      title={pet?.name || 'Pet'}
      rightButton={
        <div className="flex flex-row gap-2">
          <QuickCheckup autofillPetId={pet?.clinic_pet_id} />
          <ScheduleCheckupDialog />
        </div>
      }
      wrapWithSection={false}
    >
      <div className="space-y-4">
        <Section>
          <PetInfoForm pet={pet} />
        </Section>

        <Section>
          <PetCheckups pet={pet} />
        </Section>

        <IconTextButton
          size="small"
          variant="secondary"
          onClick={() => {
            navigate('/pets');
          }}
          Icon={ArrowLeft}
        >
          Back To Pets
        </IconTextButton>
      </div>
    </Page>
  );
};
