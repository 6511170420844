import React, { FunctionComponent, useMemo } from 'react';
import { cn } from '@companion-professional/webutils';
import { DiagnosisImagePoint, DiagnosisOption, ImageDiagnosisValues } from './imageDiagnosisTypes';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '../DropdownMenu';
import { Tooltip, TooltipContent, TooltipTrigger } from '../Tooltip';

interface ImageDiagnosisPointProps {
  point: DiagnosisImagePoint;
  options: DiagnosisOption[];
  selectedOptions: ImageDiagnosisValues;
  onValueChange: (key: string, value: string | number) => void;
  pageIndex: number;
  disabled?: boolean;
}

export const ImageDiagnosisPoint: FunctionComponent<ImageDiagnosisPointProps> = ({
  point,
  options,
  selectedOptions,
  onValueChange,
  pageIndex,
  disabled = false
}) => {
  const setValue = (newValue: string | number) => {
    onValueChange(point.name, newValue);
  };
  const isValueSelected = useMemo(() => selectedOptions[point.name] !== undefined, [selectedOptions, point]);
  const selectedOption = useMemo(
    () => options.find((option) => option.value === selectedOptions[point.name]),
    [options, selectedOptions, point]
  );
  const selectedOptionColor = useMemo(() => selectedOption?.color || 'none', [selectedOption]);

  return (
    <div
      className={cn(
        'absolute flex flex-row items-center justify-start rounded-md bg-opacity-90 p-1 text-sm text-white'
      )}
      style={{ top: point.y - 14, left: point.x - 14, backgroundColor: selectedOptionColor }}
    >
      <DropdownMenu>
        <DropdownMenuTrigger asChild data-testid={`${point.name}-point-page-${pageIndex}`} disabled={disabled}>
          <div className="flex flex-row items-center">
            <Tooltip delayDuration={0}>
              <TooltipTrigger>
                <div className="flex cursor-pointer flex-row items-center gap-2">
                  <div
                    className={cn('size-5 rounded-full border border-gray-500 bg-gray-400 opacity-50', {
                      'border-gray-900 bg-white opacity-70': isValueSelected
                    })}
                  />
                  <div className="pb-1 pr-1 text-lg leading-none">{selectedOption?.label}</div>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <p>{point.label}</p>
              </TooltipContent>
            </Tooltip>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56">
          <DropdownMenuLabel>Pain Level - {point.label}</DropdownMenuLabel>
          <DropdownMenuSeparator />
          {options.map((option, index) => (
            <DropdownMenuCheckboxItem
              key={index}
              checked={selectedOption?.value === option.value}
              onCheckedChange={() => setValue(option.value)}
            >
              {option.label}
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};
