import { useQuery } from '@tanstack/react-query';
import { SurveyAnswersFull } from '@companion-professional/dctypes';
import { getSurveyAnswers } from '../lib/api';

// useSurveyAnswers is a hook that fetches a set of answers
//
// It returns:
// - isPending: A boolean that indicates if the request is currently in progress.
// - error: An error object if the request failed.
// - answers: A SurveyAnswersFull object.
export const useSurveyAnswers = (answersId: string) => {
  const {
    isPending,
    error,
    data: answers
  } = useQuery<SurveyAnswersFull>({
    queryKey: ['survey-answers', answersId],
    queryFn: () => getSurveyAnswers(answersId)
  });

  return {
    isPending,
    error,
    answers
  };
};
