import React, { FunctionComponent, useState } from 'react';
import { Button } from '@companion-professional/components';

interface AddCheckupToSurveyWizardProps {
  onAllAnswersYes: () => void;
}

const questions = [
  'Is this the same pet as the other checkup(s) attached to the findings before?',
  'Does this checkup represent the findings below (you may update the findings in light of this new evidence if appropriate)?'
];

// AddCheckupToSurveyWizard is a wizard that asks the user a series of questions to determine if a checkup should be
// added to the survey answers.  If any of the answers are 'No', the checkup cannot be added and a message is
// displayed. If all answers are 'Yes', the onAllAnswersYes function is called after the last question.
export const AddCheckupToSurveyWizard: FunctionComponent<AddCheckupToSurveyWizardProps> = ({ onAllAnswersYes }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answeredNo, setAnsweredNo] = useState(false);

  if (answeredNo) {
    return (
      <div className="min-h-36 font-semibold text-destructive">
        Sorry, this checkup cannot be added to the survey answers.
      </div>
    );
  }

  return (
    <div
      className={
        'flex min-h-36 flex-col justify-between gap-4 border border-widget-foreground bg-widget px-4 py-2 ' +
        'text-widget-foreground'
      }
    >
      <div className="font-semibold">{questions[currentQuestionIndex]}</div>
      <div className="flex flex-row justify-center gap-2">
        <Button
          size="small"
          onClick={() => {
            if (currentQuestionIndex === questions.length - 1) {
              onAllAnswersYes();
            } else {
              setCurrentQuestionIndex(currentQuestionIndex + 1);
            }
          }}
        >
          Yes
        </Button>
        <Button size="small" onClick={() => setAnsweredNo(true)} variant="destructive">
          No
        </Button>
      </div>
    </div>
  );
};
