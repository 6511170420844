import QRious from 'qrious';

const getLineTextSpan = (text: string) => {
  return `<LineTextSpan>
              <TextSpan>
                <Text>${text}</Text>
                <FontInfo>
                  <FontName>Helvetica</FontName>
                  <FontSize>12</FontSize>
                  <IsBold>False</IsBold>
                  <IsItalic>False</IsItalic>
                  <IsUnderline>False</IsUnderline>
                  <FontBrush>
                    <SolidColorBrush>
                      <Color A="1" R="0" G="0" B="0"></Color>
                    </SolidColorBrush>
                  </FontBrush>
                </FontInfo>
              </TextSpan>
            </LineTextSpan>`;
};

// breakIntoLineTextSpans takes a string and breaks it into LineTextSpans XML that are no longer than maxLineLength.
// We need this because the Dymo web service does not support word wrapping.
export const breakIntoLineTextSpans = (text: string, maxLineLength: number): string => {
  let lineTextSpans = '';
  let currentLine = '';
  for (const word of text.split(' ')) {
    if (currentLine.length + word.length > maxLineLength) {
      lineTextSpans += getLineTextSpan(currentLine);
      currentLine = '';
    }
    currentLine += word + ' ';
  }
  lineTextSpans += getLineTextSpan(currentLine);
  return lineTextSpans;
};

// convertToQrCode takes a string and returns a base64 encoded QR code image.
export const convertToQrCode = (text: string): string => {
  const qr = new QRious({
    value: text,
    size: 500
  });

  return qr.toDataURL().split(',')[1] || '';
};
