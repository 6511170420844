import * as SelectPrimitive from '@radix-ui/react-select';

// The Select component and it's sub-components are stylized select components.  These are typically used in forms.

// Primitive select components
export const Select = SelectPrimitive.Root;
export const SelectGroup = SelectPrimitive.Group;
export const SelectValue = SelectPrimitive.Value;

// Styled select components
export { SelectTrigger } from './SelectTrigger';
export { SelectContent } from './SelectContent';
export { SelectItem } from './SelectItem';
export { SelectLabel } from './SelectLabel';
export { SelectSeparator } from './SelectSeparator';
