import React, { FunctionComponent, useMemo } from 'react';
import { FormState } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';

interface FormFieldErrorProps {
  fieldName: string;
  formState: FormState<FieldValues>;
}

// FormFieldError displays an error message for a form field.  If the field has an error, an error message box with an
// arrow pointing up (presumably at the field that is causing the error), with the label is displayed (e.g. "<LABEL>
// is required").
export const FormFieldError: FunctionComponent<FormFieldErrorProps> = ({ fieldName, formState }) => {
  if (!formState.errors[fieldName]) {
    return null;
  }

  const errorMessage = useMemo(
    () =>
      typeof formState?.errors?.[fieldName]?.message === 'string'
        ? (formState?.errors?.[fieldName]?.message as string)
        : 'Unknown error',
    [formState.errors[fieldName]]
  );

  return (
    <div className="flex">
      <div className="mt-1">
        {/* The div below is styled to be an arrow pointing up */}
        <div className="ml-8 h-0 w-0 border border-b-8 border-l-8 border-r-8 border-widget border-b-destructive" />
        <div className="rounded-md bg-destructive px-4 py-3 text-destructive-foreground">{errorMessage}</div>
      </div>
    </div>
  );
};
