import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormFieldError,
  QueryLoaderWrapper,
  RadioGroupItemWithLabel,
  RadioGroupWithLabel
} from '@companion-professional/components';
import { Page } from '../../components/Page';
import { useItemSurveys } from '../../hooks/useItemSurveys';
import { useForm, Controller } from 'react-hook-form';
import { useAllSurveysForCheckups } from '../../hooks/useAllSurveysForCheckups';

type ItemsToReviewFormValues = {
  itemReviewSurveyId: string;
  checkupSurveyId: string;
};

export const ReviewsDashboard: FunctionComponent = () => {
  const { handleSubmit: handleItemsToReviewSubmit, control: itemsToReviewControl } = useForm<ItemsToReviewFormValues>();
  const navigate = useNavigate();
  const { itemSurveys, error, isPending } = useItemSurveys();
  const { checkupSurveys, error: surveysError, isPending: surveysIsPending } = useAllSurveysForCheckups();

  return (
    <Page title="Reviews Dashboard" wrapWithSection={false}>
      <QueryLoaderWrapper error={error || surveysError} isPending={isPending || surveysIsPending}>
        <div className="grid grid-cols-3 gap-4">
          <Card className="bg-primary-foreground">
            <CardHeader className="text-lg font-semibold">Review Surveys</CardHeader>
            <CardContent>
              <form
                className="flex flex-col space-y-6"
                onSubmit={handleItemsToReviewSubmit((val) => {
                  navigate(`/reviews/items-to-review/${val.checkupSurveyId}/${val.itemReviewSurveyId}`);
                })}
              >
                <div>
                  <Controller
                    control={itemsToReviewControl}
                    name="checkupSurveyId"
                    render={({ field, formState }) => (
                      <div>
                        <RadioGroupWithLabel
                          name={field.name}
                          label="Checkup Survey"
                          description="Only items that are part of a checkup with this survey submitted will be displayed."
                          onValueChange={(v) => {
                            field.onChange(v);
                          }}
                        >
                          {checkupSurveys?.map((s) => (
                            <RadioGroupItemWithLabel key={s.survey_id} value={s.survey_id} label={s.title || s.name} />
                          ))}
                        </RadioGroupWithLabel>
                        <FormFieldError fieldName={field.name} formState={formState} />
                      </div>
                    )}
                    rules={{ required: 'Checkup Survey is required' }}
                  />
                </div>
                <div>
                  <Controller
                    control={itemsToReviewControl}
                    name="itemReviewSurveyId"
                    render={({ field, formState }) => (
                      <div>
                        <RadioGroupWithLabel
                          name={field.name}
                          label="Item Review Survey"
                          description="Only items that do not have this submitted review will be displayed."
                          onValueChange={(v) => {
                            field.onChange(v);
                          }}
                        >
                          {itemSurveys?.map((is) => (
                            <RadioGroupItemWithLabel
                              key={is.survey_id}
                              value={is.survey_id}
                              label={is.title || is.name}
                            />
                          ))}
                        </RadioGroupWithLabel>
                        <FormFieldError fieldName={field.name} formState={formState} />
                      </div>
                    )}
                    rules={{ required: 'Item Review Survey is required' }}
                  />
                </div>

                <div>
                  <Button type="submit" variant="primary" size="small" className="w-full">
                    Review Items
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>

          <Card className="bg-primary-foreground">
            <CardHeader className="text-lg font-semibold">Checkups with Answered Surveys</CardHeader>
            <CardContent className="h-full">
              <div className="flex h-full flex-col">
                <div className="flex-1">
                  This returns a list of the latest completed checkups (have an answered survey and an uploaded video)
                  that have other checkups with videos that have not been linked to an answered survey.
                </div>
                <Button
                  type="button"
                  variant="primary"
                  size="small"
                  className="w-full"
                  onClick={() => {
                    navigate(`/reviews/checkups-to-review/data_collection`);
                  }}
                >
                  View Data Collection Checkups
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>
      </QueryLoaderWrapper>
    </Page>
  );
};
