import { useQuery } from '@tanstack/react-query';
import { CheckupRequestStatusResponse } from '@companion-professional/dctypes';
import { getCheckupsForPet } from '../lib/api';

// useCheckupsForPet is a hook that fetches the checkups for a given pet.
// It returns:
// - isPending: A boolean that indicates if the request is currently in progress.
// - error: An error object if the request failed.
// - checkups: An array of CheckupRequestStatusResponse objects.
export const useCheckupsForPet = (petId: string) => {
  const {
    isPending,
    error,
    data: checkups
  } = useQuery<CheckupRequestStatusResponse[]>({
    queryKey: ['pet-checkups', petId],
    queryFn: () => getCheckupsForPet(petId)
  });

  return {
    isPending,
    error,
    checkups
  };
};
