import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Page } from '../../components/Page';
import { DynamicFormQuestionField } from '@companion-professional/components';
import { ItemToReview, SurveyFull } from '@companion-professional/dctypes';
import { ItemsToReviewTable } from '../../components/ItemsToReviewTable';

interface ItemsReadyForReviewProps {
  itemSurvey?: SurveyFull<DynamicFormQuestionField>;
  checkupSurvey?: SurveyFull<DynamicFormQuestionField>;
  itemsToReview?: ItemToReview[];
}

// ItemsReadyForReview displays a table with all the items that are ready for review.
export const ItemsReadyForReview: FunctionComponent<ItemsReadyForReviewProps> = ({
  itemSurvey,
  checkupSurvey,
  itemsToReview = []
}) => {
  const navigate = useNavigate();

  // The item type is hardcoded to 'video' for now.
  const itemType = 'video';

  return (
    <Page title={`${checkupSurvey?.name} :: ${itemSurvey?.name} -- Items Ready For Review`}>
      <ItemsToReviewTable
        itemsToReview={itemsToReview}
        onOpen={({ item_id }) => {
          navigate(
            `/reviews/items-to-review/${checkupSurvey?.survey_id}/${itemSurvey?.survey_id}/${itemType}/${item_id}`
          );
        }}
      />
    </Page>
  );
};
