import { useCallback, useState } from 'react';
import { getErrorMessageFromAxiosError } from '@companion-professional/webutils';
import { useSetEmail, useSetToken } from '../state/auth';
import { login } from '../lib/api';

export type LoginFormValues = {
  email: string;
  password: string;
  remember: boolean;
};

// useLogin is a hook that does all the work of logging in a user.  It takes care of making the API call, and updating
// the state of the application.  It returns:
// - isPending: A boolean that indicates if the login process is currently in progress.
// - errorMessage: A string that contains an error message if the login process failed.
// - submitLoginForm: A function that takes the form values and submits the login request.
export const useLogin = () => {
  const [isPending, setIsPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const setToken = useSetToken();
  const setEmail = useSetEmail();

  const submitLoginForm = useCallback((formValues: LoginFormValues) => {
    const { email, password } = formValues;

    setErrorMessage(null);
    setIsPending(true);

    login(email, password)
      .then((token) => {
        setEmail(email);
        setToken(token);
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(getErrorMessageFromAxiosError(err));
      })
      .finally(() => {
        setIsPending(false);
      });
  }, []);

  return {
    isPending,
    errorMessage,
    submitLoginForm
  };
};
