import { useQuery } from '@tanstack/react-query';
import { ClinicInfo } from '@companion-professional/dctypes';
import { getCurrentClinic } from '../lib/api';

// useCurrentClinic is a hook that fetches the clinic that the current user is logged into.
export const useCurrentClinic = () => {
  const {
    isPending,
    error,
    data: clinic
  } = useQuery<ClinicInfo>({
    queryKey: ['current-clinic'],
    queryFn: () => getCurrentClinic()
  });

  return {
    isPending,
    error,
    clinic
  };
};
