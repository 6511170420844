import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { QueryLoaderWrapper } from '@companion-professional/components';
import { CheckupTable } from '../../components/CheckupTable';
import { useActiveCheckups } from '../../hooks/useActiveCheckups';
import { useClinicSurveysForCheckups } from '../../hooks/useClinicSurveysForCheckups';

// CheckupActivity is the page that shows the list of active checkups for the logged-in user.
export const CheckupActivity: FunctionComponent = () => {
  const navigate = useNavigate();
  const { isPending, error, checkups } = useActiveCheckups();
  const { surveys, error: surveyError, isPending: surveyIsPending } = useClinicSurveysForCheckups();

  return (
    <QueryLoaderWrapper isPending={isPending || surveyIsPending} error={error || surveyError}>
      <CheckupTable
        name="checkupActivity"
        checkups={checkups}
        onOpen={({ id }) => {
          navigate(`/checkup/${id}`);
        }}
        surveys={surveys}
      />
    </QueryLoaderWrapper>
  );
};
