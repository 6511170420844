import { useEffect } from 'react';
import { LDContext, useLDClient } from 'launchdarkly-react-client-sdk';
import { useEmail, useClinicId } from '../state/auth';

// This hook will set the LaunchDarkly context for the user. This will allow us to target feature flags to specific
// users and/or clinics.
export const useSetFeatureFlagContext = () => {
  const ldClient = useLDClient();
  const loggedInEmail = useEmail();
  const clinicId = useClinicId();

  useEffect(() => {
    if (ldClient) {
      // Create a context with the user's email address and clinic. This will be used to target the user with feature
      // flags.
      const context: LDContext = {
        kind: 'multi',
        clinic: {
          key: clinicId || 'default-clinic'
        },
        user: {
          key: loggedInEmail || 'default-user'
        }
      };

      ldClient.identify(context).catch((err) => {
        console.log('Error setting user context with LD', err);
      });
    }
  }, [loggedInEmail, clinicId, ldClient]);
};
