import { useQuery } from '@tanstack/react-query';
import { SurveyInfo } from '@companion-professional/dctypes';
import { getSurveysForClinic } from '../lib/api';
import { useClinicId } from '../state/auth';

// useClinicSurveysForCheckups is a hook that fetches all the checkup surveys that are available for the currently
// logged-in user (i.e. checkup surveys that are available for the clinic that the user is currently logged into).
//
// It returns:
// - isPending: A boolean that indicates if the request is currently in progress.
// - error: An error object if the request failed.
// - surveys: An array of surveys for the currently logged-in user.
export const useClinicSurveysForCheckups = () => {
  // Pulling in the clinic id from the auth store so we can use it in the query key.  That way, if the user changes
  // clinics, we'll automatically refetch the surveys.
  const clinicId = useClinicId();

  const {
    isPending,
    error,
    data: surveys
  } = useQuery<SurveyInfo[]>({
    queryKey: ['surveys-for-clinic', clinicId],
    queryFn: () => getSurveysForClinic(),

    // Setting the stale time to 30 minutes so that we're not constantly fetching the same data from the server.  I
    // don't believe that the surveys will change very often, so we can afford to have a longer stale time.
    // NOTE: the user can manually refresh the surveys by refreshing the page.
    staleTime: 1000 * 60 * 30
  });

  return {
    isPending,
    error,
    surveys: Array.isArray(surveys) ? surveys : []
  };
};
