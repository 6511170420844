import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  QueryLoaderWrapper
} from '@companion-professional/components';
import { PetAndClinicInfo } from '@companion-professional/dctypes';
import { DialogSharedProps } from '../DialogSharedProps';

import { useArchivePet } from '../../hooks/useArchivePet';

interface ArchivePetDialogProps extends DialogSharedProps {
  // pet is the pet that will be archived.
  pet: PetAndClinicInfo;

  // returnUrl is the URL to navigate to after the checkup is archived.
  returnUrl: string;
}

// ArchivePetDialog is a dialog that allows the user to archive a pet. This will also archive any checkups associated
// with the pet.
export const ArchivePetDialog: FunctionComponent<ArchivePetDialogProps> = ({
  children,
  pet,
  open,
  onOpenChange = () => {},
  returnUrl
}) => {
  const navigate = useNavigate();
  const { archivePet, error, isPending } = useArchivePet();

  return (
    <Dialog open={open} onOpenChange={(o) => onOpenChange(o)}>
      {children ? <DialogTrigger asChild>{children}</DialogTrigger> : null}
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Archive Pet?</DialogTitle>
          <DialogDescription>
            Are you sure you want to archive {pet.name}? <br />
            NOTE: This will archive all checkups associated with this pet.
          </DialogDescription>
        </DialogHeader>
        <QueryLoaderWrapper isPending={isPending} error={error} />
        <DialogFooter className="sm:justify-end">
          <DialogClose asChild>
            <Button size="small" type="button" variant="outline">
              Cancel
            </Button>
          </DialogClose>
          <Button
            size="small"
            type="button"
            variant="destructive"
            onClick={() => {
              archivePet({
                petId: pet.id
              }).then(() => {
                navigate(returnUrl);
              });
            }}
          >
            Archive
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
