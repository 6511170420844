import React, { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClipboardList } from 'lucide-react';
import { ColumnDef, Row } from '@tanstack/react-table';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CheckupRequestStatusResponse, SurveyInfo } from '@companion-professional/dctypes';
import { BulkOptionMenuItem, DataTable, FilterSettings } from '@companion-professional/components';
import { getCheckupColumns } from './columns';
import { QRCodeDialog } from '../QRCodeDialog';
import { analysisStatuses, statuses } from './filterOptions';
import { FeatureFlags } from '../../types/featureFlags';
import { getSurveyFilterSettings } from './surveyFilterOptions';

export type CheckupTableOptions = {
  columns: ColumnDef<CheckupRequestStatusResponse>[];
  bulkOptionMenuItems: BulkOptionMenuItem<CheckupRequestStatusResponse>[];
  filters: FilterSettings[];
  customRowClassName?: (row: Row<CheckupRequestStatusResponse>) => string;
};

export interface CheckupTableProps {
  title?: string;
  name?: string;
  onOpen: (checkup: CheckupRequestStatusResponse) => void;
  checkups?: CheckupRequestStatusResponse[];

  // enableBulkHealthDataSelect is a boolean that determines whether the user is presented with the option to select
  // multiple checkups for use in a creating a health data survey.
  enableBulkHealthDataSelect?: boolean;

  // surveys are the surveys that have been assigned to the clinic.  If surveys are provided, then the table will
  // include filter options for the surveys (e.g. "Show only checkups that have not completed this survey").
  surveys?: SurveyInfo[];
}

// CheckupTable is a reusable component that displays a list of checkups: this might be used to show all checkups or
// maybe just the checkups for a given pet.
export const CheckupTable: FunctionComponent<CheckupTableProps> = ({
  title,
  name = 'checkupTable',
  checkups = [],
  surveys = [],
  onOpen,
  enableBulkHealthDataSelect
}) => {
  const flags = useFlags<FeatureFlags>();
  const navigate = useNavigate();
  const [tableOptions, setTableOptions] = useState<CheckupTableOptions | null>(null);
  const [selectedCheckups, setSelectedCheckups] = useState<CheckupRequestStatusResponse[]>([]);
  const [qrCodeDialogOpen, setQrCodeDialogOpen] = useState(false);
  const [selectingSurveyId, setSelectingSurveyId] = useState<string>('');

  useEffect(() => {
    const columns: ColumnDef<CheckupRequestStatusResponse>[] = getCheckupColumns(
      onOpen,
      (checkup) => {
        setSelectedCheckups([checkup]);
        setQrCodeDialogOpen(true);
      },
      flags?.enableAnalysisDisplay,
      surveys
    );

    const bulkOptionMenuItems: BulkOptionMenuItem<CheckupRequestStatusResponse>[] = enableBulkHealthDataSelect
      ? surveys.map((survey) => ({
          label: `${survey.title || survey.name}`,
          icon: <ClipboardList className="mr-2 size-4" />,
          onActive: () => {
            setSelectingSurveyId(survey.survey_id);
          },
          operations: [
            {
              label: `Add ${survey.title || survey.name}`,
              onClick: (sc) => {
                if (sc[0]) {
                  const petId = sc[0].original.pet_id;
                  navigate(`/pets/${petId}/survey/${survey.survey_id}`, {
                    state: { checkupRequestIds: sc.map((r) => r.original.id) }
                  });
                }
              }
            }
          ]
        }))
      : [];

    const filters: FilterSettings[] = [
      {
        title: 'Status',
        options: statuses,
        columnId: 'status',
        defaultSelections: ['complete'],
        makeSticky: true
      }
    ];

    if (flags?.enableAnalysisDisplay) {
      filters.push({
        title: 'Analysis',
        options: analysisStatuses,
        columnId: 'analysis_status'
      });
    }

    // TODO: This doesn't really scale well in the UI: more than 3 surveys will make the filter bar very busy.  If we
    //   start having more than 3 surveys per clinic, we should consider a different UI for filtering by surveys.
    if (surveys && surveys.length > 0) {
      filters.push(...getSurveyFilterSettings(surveys));
    }

    setTableOptions({
      columns,
      bulkOptionMenuItems,
      filters,
      customRowClassName: flags?.enableAnalysisDisplay
        ? (row) => (row.original.analysis_status === 'gait_anomaly' ? 'bg-secondary/40' : '')
        : () => ''
    });
  }, [flags?.enableAnalysisDisplay, enableBulkHealthDataSelect]);

  return (
    <>
      <QRCodeDialog
        checkupRequestId={selectedCheckups?.[0]?.id || ''}
        open={qrCodeDialogOpen}
        onOpenChange={setQrCodeDialogOpen}
      />

      {tableOptions ? (
        <DataTable
          name={name}
          title={title}
          columns={tableOptions.columns}
          data={checkups}
          filterPlaceholder="Enter Pet Name, ID, etc here to filter results..."
          resultsPerPage={20}
          enableSearch
          enablePagination
          enableBulkOperationHeader={enableBulkHealthDataSelect}
          bulkOptionMenuItems={tableOptions.bulkOptionMenuItems}
          getRowTypeName={(numRows) => (numRows === 1 ? 'checkup' : 'checkups')}
          rowCanBeSelected={(row) =>
            !(
              row.getValue('status') !== 'complete' ||
              row.original?.answered_surveys?.some((s) => s.survey_id === selectingSurveyId)
            )
          }
          dateRangeFilterColumnId="request_created"
          filters={tableOptions.filters}
          customRowClassName={tableOptions.customRowClassName}
        />
      ) : null}
    </>
  );
};
