import { useCallback, useState } from 'react';
import { getErrorMessageFromAxiosError } from '@companion-professional/webutils';
import { useSetToken } from '../state/auth';
import { changePassword } from '../lib/api';

export interface UpdatePasswordFormValues {
  newPassword: string;
  confirmPassword: string;
  currentPassword: string;
}

// useChangePassword is a hook that returns the state and functions necessary to change a user's password.
// It returns:
// - isPending: A boolean that indicates if the reset process is currently in progress.
// - errorMessage: A string that contains an error message if the reset process failed.
// - submitPasswordResetForm: A function that takes the form values and submits the login request.  This function is a
//      promise that resolves to a boolean that indicates if the reset was successful.
export const useChangePassword = () => {
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const setToken = useSetToken();

  const submitPasswordChangeForm = useCallback(async (formValues: UpdatePasswordFormValues): Promise<boolean> => {
    const { currentPassword, newPassword, confirmPassword } = formValues;

    if (newPassword !== confirmPassword) {
      setErrorMessage('New password and the confirmation do not match.');
      return false;
    }

    setErrorMessage(null);
    setIsPending(true);

    try {
      const token = await changePassword(currentPassword, newPassword);
      setToken(token);
      setPasswordUpdated(true);
    } catch (err) {
      setErrorMessage(getErrorMessageFromAxiosError(err));
      setIsPending(false);
      return false;
    }

    setIsPending(false);
    return true;
  }, []);

  return {
    isPending,
    errorMessage,
    passwordUpdated,
    submitPasswordChangeForm
  };
};
