import axios from 'axios';
import { baseUrl, Printer } from './config';
import { parsePrintersXml } from './utils';

// TODO: Need to add better error messaging for when the Dymo service is running.  Currently this just returns a generic
//   "Error" message.

// getDymoIsServiceConnected returns true if the Dymo service is connected (i.e. the Dymo Label Software is running).
export const getDymoIsServiceConnected = async (): Promise<boolean> => {
  const result = await axios.get(`${baseUrl}/StatusConnected`);
  if (result.status === 200 && result.data) {
    return !!result.data;
  }
  return false;
};

// getDymoPrinters returns a list of available Dymo printers.
export const getDymoPrinters = async (): Promise<Printer[]> => {
  const result = await axios.get(`${baseUrl}/GetPrinters`);
  if (result.status === 200) {
    return parsePrintersXml(result.data);
  }

  return [];
};

// printDymoLabel sends a request to the server to print a label.
export const printDymoLabel = async (printerName: string, labelXml: string): Promise<boolean> => {
  const result = await axios.post(
    `${baseUrl}/PrintLabel`,
    `printerName=${encodeURIComponent(printerName)}&` +
      `printParamsXml=&labelXml=${encodeURIComponent(labelXml)}&labelSetXml=`
  );
  return result.status === 200;
};

// getDymoLabelPreview sends a request to the server to get a preview of a label.  The result is a base64 encoded png
// image.
export const getDymoLabelPreview = async (printerName: string, labelXml: string): Promise<string> => {
  const result = await axios.post(
    `${baseUrl}/RenderLabel`,
    `printerName=${encodeURIComponent(printerName)}&` +
      `printParamsXml=&labelXml=${encodeURIComponent(labelXml)}&labelSetXml=`
  );
  if (result.status !== 200 || !result.data) {
    throw new Error('Error getting label preview');
  }

  return result.data;
};
