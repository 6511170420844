import * as React from 'react';
import { cn } from '@companion-professional/webutils';
import { ButtonProps, buttonVariants } from '../Button';

type PaginationLinkProps = {
  isActive?: boolean;
} & Pick<ButtonProps, 'size'> &
  React.ComponentProps<'a'>;

export const PaginationLink = ({ className, isActive, size = 'icon', ...props }: PaginationLinkProps) => (
  <a
    aria-current={isActive ? 'page' : undefined}
    aria-disabled={!isActive}
    className={cn(
      'select-none',
      buttonVariants({
        variant: 'outline',
        size
      }),
      {
        'pointer-events-none opacity-20': !isActive,
        'cursor-pointer': isActive
      },
      className
    )}
    {...props}
  />
);
PaginationLink.displayName = 'PaginationLink';
