import React, { FunctionComponent } from 'react';

// MaintenanceMode is a page that is displayed when the site is under maintenance or anytime we want to limit site
// access for a short period of time.  This can be turned on/off using a feature flag located in LaunchDarkly:
// https://app.launchdarkly.com/default/production/features/site-in-maintenance-mode
export const MaintenanceMode: FunctionComponent = () => {
  return (
    <div className="mx-10 my-20 flex flex-col items-center">
      <h1 className="text-4xl font-bold text-destructive">Site Under Maintenance</h1>
      <p className="text-2xl">
        We're currently making improvements to our site. We apologize for any inconvenience and appreciate your
        patience. Please check back soon.
      </p>
    </div>
  );
};
