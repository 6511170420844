import React, { FunctionComponent } from 'react';
import { QuickCheckup } from '../../components/QuickCheckup';
import { Page } from '../../components/Page';

import { CheckupActivity } from './CheckupActivity';

export const Dashboard: FunctionComponent = () => {
  return (
    <Page title="Dashboard" rightButton={<QuickCheckup />}>
      <CheckupActivity />
    </Page>
  );
};
