import React, { FunctionComponent } from 'react';
import { PetAndClinicInfo } from '@companion-professional/dctypes';
import { PetsTable } from '../../components/PetsTable';

interface AllPetsForClinicProps {
  pets?: PetAndClinicInfo[];

  // onOpen is called when a user select a pet from the list
  onOpen: (petInfo: PetAndClinicInfo) => void;
}

export const AllPetsForClinic: FunctionComponent<AllPetsForClinicProps> = ({ onOpen, pets }) => {
  if (!pets) {
    return null;
  }

  return <PetsTable pets={pets} onOpen={onOpen} />;
};
