import { XMLParser } from 'fast-xml-parser';
import { Printer } from './config';

const boolTags = ['IsConnected', 'IsLocal', 'IsTwinTurbo'];

export interface DymoPrinters {
  Printers: {
    LabelWriterPrinter: {
      Name: string;
      ModelName: string;
      IsLocal: boolean;
      IsConnected: boolean;
      IsTwinTurbo: boolean;
    }[];
  };
}

// parsePrintersXml parses the XML returned by the Dymo service and returns js object of printers.
export const parsePrintersXml = (xml: string): Printer[] => {
  const parser = new XMLParser({
    isArray: (tagName) => tagName === 'LabelWriterPrinter',
    tagValueProcessor: (tagName, value) => {
      if (boolTags.includes(tagName)) {
        return value === 'True';
      }
      return value;
    }
  });
  const dymoPrinters = parser.parse(xml) as DymoPrinters;
  if (Array.isArray(dymoPrinters?.Printers?.LabelWriterPrinter)) {
    return dymoPrinters.Printers.LabelWriterPrinter.map((printer) => ({
      name: printer.Name,
      modelName: printer.ModelName,
      isLocal: printer.IsLocal,
      isConnected: printer.IsConnected,
      isTwinTurbo: printer.IsTwinTurbo
    }));
  }

  return [];
};
