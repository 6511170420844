// This file holds the version and commit hash of the current build.  __VERSION__ comes from version property in the
// package.json file and __COMMIT_HASH__ is pulled from git.  Both of these variables are injected into the code by
// the define section in vite.config.ts

declare const __VERSION__: string;
declare const __COMMIT_HASH__: string;

export let version = 'UNKNOWN';
try {
  version = __VERSION__;
} catch (e) {
  console.log('Failed to get commit hash');
}

export let commitHash = 'UNKNOWN';
try {
  commitHash = __COMMIT_HASH__;
} catch (e) {
  console.log('Failed to get commit hash');
}
