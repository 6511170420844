import { ItemToReview } from '@companion-professional/dctypes';
import { ColumnDef } from '@tanstack/react-table';
import React from 'react';
import { ItemTypeIcon } from './ItemTypeIcon';
import { format, parseISO } from 'date-fns';

export const getItemsToReviewColumns = (onOpen: (item: ItemToReview) => void): ColumnDef<ItemToReview>[] => {
  return [
    {
      accessorKey: 'item_type',
      header: () => <div className="flex w-20 justify-center align-middle">Type</div>,
      cell: ({ row }) => (
        <div className="flex w-20 justify-center align-middle" onClick={() => onOpen(row.original)}>
          <ItemTypeIcon itemType={row.getValue('item_type')} />
        </div>
      ),
      enableSorting: false,
      enableHiding: false
    },
    {
      accessorKey: 'pet_name',
      header: () => <div>Pet Name</div>,
      cell: ({ row }) => <div onClick={() => onOpen(row.original)}>{row.getValue('pet_name')}</div>
    },
    {
      accessorKey: 'clinic_name',
      header: () => <div>Clinic Name</div>,
      cell: ({ row }) => <div onClick={() => onOpen(row.original)}>{row.getValue('clinic_name')}</div>
    },
    {
      accessorKey: 'requested_at',
      header: () => <div>Request Created At</div>,
      cell: ({ row }) => (
        <div onClick={() => onOpen(row.original)}>
          {format(parseISO(row.getValue('requested_at')), 'MM/dd/yyyy hh:mm a')}
        </div>
      )
    },
    {
      accessorKey: 'requested_by',
      header: () => <div>Requested By</div>,
      cell: ({ row }) => (
        <div onClick={() => onOpen(row.original)}>{`${row.original.first_name} ${row.original.last_name}`.trim()}</div>
      )
    }
  ];
};
