import React from 'react';
import { Table } from '@tanstack/react-table';
import { Button } from '../Button';
import { BulkOperation } from './dataTableTypes';

interface BulkOperationFooterProps<TData> {
  table: Table<TData>;
  getRowTypeName: (numRows: number) => string;
  operations?: BulkOperation<TData>[];
}

// BulkOperationFooter is show at the bottom of the DataTable when the table is in the bulk selecting mode.  It shows
// the number of rows that are selected and the bulk operations that can be performed on those rows.
export function BulkOperationFooter<TData>({
  table,
  getRowTypeName,
  operations = []
}: BulkOperationFooterProps<TData>) {
  const numSelected = table.getSelectedRowModel().rows.length;
  return (
    <div className="my-4 flex flex-col items-center justify-center gap-2">
      <div className="text-center">
        <span className="capitalize">
          {numSelected}&nbsp;{getRowTypeName(numSelected)}&nbsp;Selected
        </span>
      </div>
      <div className="space-x-2">
        {operations.map((operation, index) => (
          <Button
            key={index}
            size="small"
            variant="outline"
            onClick={() => {
              if (operation?.onClick && table.getSelectedRowModel().rows) {
                operation.onClick(table.getSelectedRowModel().rows);
              }
            }}
            disabled={numSelected === 0}
          >
            {operation.label}
          </Button>
        ))}
      </div>
    </div>
  );
}
