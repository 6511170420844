import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Clipboard, ClipboardCheck } from 'lucide-react';
import { ListButton } from '@companion-professional/components';
import { CheckupRequestInfo, SurveyInfo } from '@companion-professional/dctypes';
import { cn } from '@companion-professional/webutils';

interface SurveyListItemProps {
  survey: SurveyInfo;
  checkupRequest: CheckupRequestInfo;
  petId: string;
}

// SurveyListItem displays a single survey in a list of surveys that the user select to fill out for a checkup.
export const SurveyListItem: FunctionComponent<SurveyListItemProps> = ({ survey, checkupRequest, petId }) => {
  const navigate = useNavigate();
  const answeredDetails =
    checkupRequest?.answered_surveys && checkupRequest.answered_surveys.find((s) => s.survey_id === survey.survey_id);

  return (
    <ListButton
      onClick={() => {
        if (!answeredDetails) {
          navigate(`/pets/${petId}/survey/${survey.survey_id}`, { state: { checkupRequestIds: [checkupRequest.id] } });
        } else {
          navigate(`/pets/${petId}/answers/${answeredDetails.answers_id}`, {
            state: { checkupRequestIds: [checkupRequest.id] }
          });
        }
      }}
      Icon={answeredDetails ? ClipboardCheck : Clipboard}
      iconClassName={cn({ 'text-destructive': !answeredDetails })}
    >
      {survey.title || survey.name}
      {answeredDetails ? <span className="ml-2 text-sm">(complete)</span> : null}
    </ListButton>
  );
};
