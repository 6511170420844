import React, { FunctionComponent, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  ErrorAlert,
  Separator,
  toast
} from '@companion-professional/components';
import { useChangePassword } from '../../hooks/useChangePassword';
import { UpdatePasswordForm } from '../UpdatePasswordForm';

export const UpdatePasswordDialog: FunctionComponent = () => {
  const [open, setOpen] = useState(false);
  const { submitPasswordChangeForm, errorMessage, isPending } = useChangePassword();

  return (
    <Dialog
      open={open}
      onOpenChange={(openChange) => {
        setOpen(openChange);
      }}
    >
      <DialogTrigger asChild>
        <Button variant="primary" size="small">
          Change Password
        </Button>
      </DialogTrigger>

      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Change Password</DialogTitle>
        </DialogHeader>
        <Separator />
        {errorMessage ? <ErrorAlert title="Unable to change password" message={errorMessage} /> : null}
        <UpdatePasswordForm
          onFormSubmit={async (data) => {
            return submitPasswordChangeForm(data).then((success) => {
              if (success) {
                // If the submission was successful, then close the dialog and show a success toast
                setOpen(false);
                toast.success('Password updated');
              }
              return success;
            });
          }}
        >
          <DialogFooter className="mt-6 flex flex-row items-end space-x-2 md:items-center">
            <Button type="submit" disabled={isPending}>
              Apply
            </Button>
          </DialogFooter>
        </UpdatePasswordForm>
      </DialogContent>
    </Dialog>
  );
};
