import { useQuery } from '@tanstack/react-query';
import { SurveyFull } from '@companion-professional/dctypes';
import { DynamicFormQuestionField } from '@companion-professional/components';
import { getCheckupSurveyForItemReview } from '../lib/api';

// useCheckupSurveyForItemReview is a hook that fetches a single survey along with its questions.  This will return a
// checkup survey (if it exists) regardless of what clinic the user is longed in as, but will only do so for a user
// with the proper role (e.g. admin).  It takes in a surveyId and returns the latest version of the survey.
export const useCheckupSurveyForItemReview = (surveyId: string) => {
  const {
    isPending,
    error,
    data: survey
  } = useQuery<SurveyFull<DynamicFormQuestionField>>({
    queryKey: ['checkup-survey-for-item-review', surveyId],
    queryFn: () => getCheckupSurveyForItemReview(surveyId)
  });

  return {
    isPending,
    error,
    survey
  };
};
