import React, { FunctionComponent } from 'react';
import { cn } from '@companion-professional/webutils';
import { TooltipProvider } from '../Tooltip';

interface ImageViewportProps {
  children?: React.ReactNode;
  className?: string;
}

// ImageViewport is a wrapper for an image that maintains a 4:3 aspect ratio and a 500px height.  That way all the
// points are calculated based on the 666px wide and 500px high.
// TODO: Change this so that the height/width can either be dynamic or set by the parent.
export const ImageViewport: FunctionComponent<ImageViewportProps> = ({ children, className }) => {
  return (
    <TooltipProvider>
      <div className={cn('relative aspect-[4/3] h-[500px] overflow-hidden', className)}>{children}</div>
    </TooltipProvider>
  );
};
