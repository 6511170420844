import React, { FunctionComponent } from 'react';
import { Button, ErrorAlert, LoaderSpinner } from '@companion-professional/components';
import { useChangePassword } from '../../hooks/useChangePassword';
import { LoginLayout } from '../../components/LoginLayout';
import { UpdatePasswordForm } from '../../components/UpdatePasswordForm';
import { LoginSplash } from '../../components/LoginSplash';
import { LoginHeading } from '../../components/LoginHeading';

// LoginPasswordChange is the page used for a user that is logged in, but needs to change their password (the
// password_needs_reset flag is set to true on the JWT).
export const LoginPasswordChange: FunctionComponent = () => {
  const { submitPasswordChangeForm, isPending, errorMessage } = useChangePassword();

  return (
    <LoginLayout>
      <LoginSplash />
      <div className="flex-1 bg-white p-5">
        <LoginHeading title="Update Password">A password change is required to continue.</LoginHeading>
        {errorMessage ? <ErrorAlert title="Error Updating Password" message={errorMessage} /> : null}
        <UpdatePasswordForm onFormSubmit={submitPasswordChangeForm}>
          <Button
            type="submit"
            variant={isPending ? 'outline' : 'primary'}
            size="large"
            className="mt-6 w-full"
            disabled={isPending}
          >
            {isPending ? (
              <span className="flex flex-row items-center space-x-2">
                <LoaderSpinner size="small" />
                Updating Password...
              </span>
            ) : (
              'Change Password'
            )}
          </Button>
        </UpdatePasswordForm>
      </div>
    </LoginLayout>
  );
};
