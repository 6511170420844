import React, { FunctionComponent, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { PetAndClinicInfo } from '@companion-professional/dctypes';
import { useSaveAnswers } from '../../hooks/useSaveAnswers';
import { SurveyDisplay } from '../../components/SurveyDisplay';
import { PageNotFound } from '../PageNotFound';

type SurveyParams = {
  surveyId: string;
};

interface SurveyProps {
  pet: PetAndClinicInfo;
}

export const Survey: FunctionComponent<SurveyProps> = ({ pet }) => {
  const [surveySaved, setSurveySaved] = useState(false);
  const location = useLocation();
  const { saveAnswers, error: errorSavingAnswers, isPending: isSavingAnswersPending } = useSaveAnswers();
  const { surveyId } = useParams<SurveyParams>();
  if (!surveyId) {
    return <PageNotFound />;
  }
  let checkupRequestIds: string[] = [];
  if (location.state) {
    const state = location.state as { checkupRequestIds: string[] };
    checkupRequestIds = state.checkupRequestIds;
  }

  return (
    <div className="flex flex-1 flex-col items-center">
      <div className="w-full max-w-screen-xl">
        <SurveyDisplay
          surveyId={surveyId}
          pet={pet}
          surveySaved={surveySaved}
          checkupRequestIds={checkupRequestIds}
          onSurveySubmit={async (formData, survey, checkupsToAdd = []) => {
            try {
              await saveAnswers({
                surveyId: surveyId,
                version: survey.version,
                checkupRequestIds: [...checkupRequestIds, ...checkupsToAdd],
                answers: formData
              });
              setSurveySaved(true);
            } catch (e) {
              // The hook should handle the error, also logging here for good measure.
              console.error(e);
            }
          }}
          isSavePending={isSavingAnswersPending}
          saveError={errorSavingAnswers?.message}
        />
      </div>
    </div>
  );
};
