import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getErrorMessageFromAxiosError } from '@companion-professional/webutils';
import { updatePetInfo } from '../lib/api';

export type UpdatePetInfoFormValues = {
  petId: string;
  petName: string;
  clinicPetId: string;
};

// useSavePetInfo is a hook that returns a function that updates pet info.
//
// It returns:
// - isPending: A boolean that indicates if the request is currently in progress.
// - errormessage: A string that contains an error message if the request failed.
// - savePetInfo: Mutation function that updates the pet information (pet name and clinic pet id).
export const useSavePetInfo = () => {
  const queryClient = useQueryClient();

  const {
    mutateAsync: savePetInfo,
    isPending,
    error
  } = useMutation({
    mutationFn: async (values: UpdatePetInfoFormValues) => {
      const res = await updatePetInfo(values.petId, values.petName, values.clinicPetId);
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['active-checkups']
        }),
        queryClient.invalidateQueries({
          queryKey: ['pet', values.petId]
        }),
        queryClient.invalidateQueries({
          queryKey: ['pet-checkups', values.petId]
        })
      ]);
      return res;
    }
  });

  return {
    savePetInfo,
    isPending,
    errorMessage: error ? getErrorMessageFromAxiosError(error, 'Unable to save pet info.') : null
  };
};
