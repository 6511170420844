import React, { Component, ErrorInfo, ReactNode } from 'react';
import { ErrorAlert } from '../ErrorAlert';

interface ErrorBoundaryProps {
  // The children to attempt to render
  children: ReactNode;

  // The fallback component to render if an error occurs. If not provided, an ErrorAlert component with the error
  // message and stack trace will be rendered.
  fallback?: ReactNode;
}

type ErrorBoundaryState = {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
};

// ErrorBoundary is like a catch block for React components. If no errors happen in the children; they are rendered as
// normal.  If errors are thrown anywhere in the child component tree, then this component will display an alternative
// UI:  An ErrorAlert with the error message and stack trace is shown by default, but if a fallback is provided, it
// will be rendered instead.
// NOTE: The ErrorBoundary component will not catch errors in event handlers, async code, or server side rendering. If
// we want something more sophisticated (that can catch such errors), we might want to look into a library like
// react-error-boundary.
export class ErrorBoundary extends Component<ErrorBoundaryProps> {
  state: ErrorBoundaryState = { hasError: false, error: null, errorInfo: null };

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('ErrorBoundary caught an error', error, errorInfo);
    this.setState({ error, errorInfo });
  }

  render() {
    const { hasError, error, errorInfo } = this.state;

    if (hasError) {
      if (this.props.fallback) {
        return this.props.fallback;
      }

      const message = errorInfo?.componentStack || 'Unknown Error';
      return <ErrorAlert title={error?.message || 'Error'} message={message} />;
    }

    return this.props.children;
  }
}
