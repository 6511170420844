import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  QueryLoaderWrapper
} from '@companion-professional/components';
import { CheckupRequestInfo } from '@companion-professional/dctypes';
import { DialogSharedProps } from '../DialogSharedProps';

import { useArchiveCheckup } from '../../hooks/useArchiveCheckup';

interface ArchiveCheckupDialogProps extends DialogSharedProps {
  // checkupRequest is the checkup that would be archived.
  checkupRequest: CheckupRequestInfo;

  // returnUrl is the URL to navigate to after the checkup is archived.
  returnUrl: string;
}

// ArchiveCheckupDialog is a dialog that allows the user to archive a checkup.  This essentially removes the checkup
// from all the displays, but keeps it in the database in case it needs to be restored.
export const ArchiveCheckupDialog: FunctionComponent<ArchiveCheckupDialogProps> = ({
  children,
  checkupRequest,
  open,
  onOpenChange = () => {},
  returnUrl
}) => {
  const navigate = useNavigate();
  const { archiveCheckup, error, isPending } = useArchiveCheckup();

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      {children ? <DialogTrigger asChild>{children}</DialogTrigger> : null}
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Archive Checkup?</DialogTitle>
          <DialogDescription>
            NOTE: archiving will invalidate the checkup link associated with this checkup.
          </DialogDescription>
        </DialogHeader>
        <QueryLoaderWrapper isPending={isPending} error={error} />
        <DialogFooter className="sm:justify-end">
          <DialogClose asChild>
            <Button size="small" type="button" variant="outline">
              Cancel
            </Button>
          </DialogClose>
          <Button
            size="small"
            type="button"
            variant="destructive"
            onClick={() => {
              archiveCheckup({
                checkupRequestId: checkupRequest.id,
                petId: checkupRequest.pet_id
              }).then(() => {
                navigate(returnUrl);
              });
            }}
          >
            Archive
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
