import { useQuery } from '@tanstack/react-query';
import { PetAndClinicInfo } from '@companion-professional/dctypes';
import { getPets } from '../lib/api';

// usePets is a hook that fetches all the pets for the currently logged-in user.
//
// It returns:
// - isPending: A boolean that indicates if the request is currently in progress.
// - error: An error object if the request failed.
// - pets: An array of pets for the currently logged-in user.
export const usePets = () => {
  const {
    isPending,
    error,
    data: pets
  } = useQuery<PetAndClinicInfo[]>({
    queryKey: ['pets-for-clinic'],
    queryFn: () => getPets()
  });

  return {
    isPending,
    error,
    pets
  };
};
