import React, { FunctionComponent, useMemo } from 'react';
import { Field } from './dynamicFormSchema';
import { DynamicFormDisplay } from './DynamicFormDisplay';
import { getDynamicFieldComponent } from './dynamicFields';

export type DynamicFormQuestionField = Field;

export interface DynamicFormProps {
  // onSubmit is a function that is called when the form is submitted. It is passed the data from the form.
  onSubmit?: (data: Record<string, unknown>) => void;

  // showNumbers is a boolean that determines whether to display numbers next to each field.
  showNumbers?: boolean;

  // isSavePending is a boolean that indicates if the request is currently being saved.
  isSavePending?: boolean;

  // saveError is an error string if the request to save failed.
  saveError?: string;

  // answers is a list of answers to display in the form.  NOTE: this is not currently validated against the fields, so
  // any answers that don't match the fields will be ignored.
  answers?: Record<string, unknown>;

  // showSubmitButton is a boolean that determines whether to display the submit button.
  showSubmitButton?: boolean;

  // disabled is a boolean that determines whether to disable the entire form.  This is useful for view-only form and
  // to disable input while saving.
  disabled?: boolean;
}

interface DynamicFormWithFieldsProps extends DynamicFormProps {
  // fields is a list of input fields to display (text, radio, checkbox, etc).
  fields: Field[];
}

// DynamicForm display a list of input fields.
export const DynamicForm: FunctionComponent<DynamicFormWithFieldsProps> = ({ fields, onSubmit, ...props }) => {
  const dynamicFields = useMemo(() => fields.map((field) => getDynamicFieldComponent(field)), [fields]);
  return (
    <DynamicFormDisplay
      dynamicFields={dynamicFields}
      onSubmit={(data) => {
        if (onSubmit) {
          // Apply type conversion to the data before submitting it.
          const convertedData: Record<string, unknown> = {};
          dynamicFields.forEach((df) => {
            if (df.providesAnswer()) {
              convertedData[df.field.name] = df.getValueToSubmit(data[df.field.name]);
            }
          });
          onSubmit(convertedData);
        }
      }}
      {...props}
    />
  );
};
