import React, { useEffect, useState } from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import { cn } from '@companion-professional/webutils';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../Table';
import { Pagination, PaginationNext, PaginationPrevious } from '../Pagination';
import { getCheckboxColumn } from './checkboxColumn';
import { dateBetweenFilterFn } from './filterFunctions';
import { BulkOperationWrapper } from './BulkOperationWrapper';
import { FilterBar } from './FilterBar';
import { DataTableProps } from './index';
import { filterStore } from './filterState';

export interface DataTableDisplayProps<TData, TValue> extends DataTableProps<TData, TValue> {}

// DataTableDisplay is a component that displays a table with the given columns and data.  See DataTableProps for
// documentation on the props.
export function DataTableDisplay<TData extends object, TValue extends object>({
  title,
  name,
  columns,
  data = [],
  filters = [],
  enablePagination = false,
  enableSearch = false,
  filterPlaceholder = 'Enter a search term to filter the table ...',
  resultsNotFound = 'No results found.',
  resultsPerPage = 15,
  getRowTypeName = (numRows) => (numRows === 1 ? 'row' : 'rows'),
  enableBulkOperationHeader,
  bulkOptionMenuItems = [],
  rowCanBeSelected = true,
  dateRangeFilterColumnId,
  dateRangeStartingValue,
  initialState,
  customRowClassName = () => ''
}: DataTableDisplayProps<TData, TValue>) {
  const [selectingRows, setSelectingRows] = useState(false);
  const [displayColumns, setDisplayColumns] = useState<ColumnDef<TData, TValue>[]>(columns);
  const [pageIndex, setPageIndex] = useState(0);
  const [filterString, setFilterString] = useState<string>('');

  useEffect(() => {
    if (selectingRows) {
      setDisplayColumns([getCheckboxColumn<TData, TValue>()].concat(columns));
    } else {
      setDisplayColumns(columns);
    }
  }, [columns, selectingRows]);

  const table = useReactTable<TData>({
    columns: displayColumns,
    data: Array.isArray(data) ? data : [],
    enableFilters: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: enablePagination ? getPaginationRowModel() : undefined,
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    onGlobalFilterChange: setFilterString,
    enableRowSelection: rowCanBeSelected,
    state: { pagination: { pageSize: resultsPerPage, pageIndex: pageIndex }, globalFilter: filterString },
    filterFns: {
      dateBetweenFilterFn
    },
    // The following (when set to true) can be used to turn this component into a server-side component (where the
    // server is used for pagination, sorting).  This is not yet implemented, but adding logic to these options would
    // be the first step.
    manualSorting: false,
    manualPagination: false,
    initialState
  });

  // Apply initial filters to the table.
  useEffect(() => {
    filters.forEach((filter) => {
      const column = table.getColumn(filter.columnId);
      if (!column) {
        // Other errors will most likely be thrown before we reach this point, so if we see this error, something
        // really went wrong.
        throw new Error(`Unable to apply filter ${filter.title}.  Column ${filter.columnId} not found in table.`);
      }
      const setFilterValue = filterStore.getState().setSelectedOptionsForColumn;
      const stickySelectedStatuses = filterStore.getState().selectedFilterOptions?.[name];

      // If the filter is marked as "sticky" and a value if found in the store (which is built from localstorage) then
      // apply the filter.  If the value is an empty array, then the filter is cleared. A filled or empty array is
      // considered a valid sticky filter value; if either exists, the filter is applied, and we will NOT apply the
      // default selections.
      const stickyStatusArray = stickySelectedStatuses?.[column.id];
      if (filter.makeSticky && stickyStatusArray !== undefined) {
        // If the sticky filter value is an empty array, then the setFilterValue must be called with undefined to clear
        // the filter.
        column.setFilterValue(stickyStatusArray.length === 0 ? undefined : stickyStatusArray);
        return;
      }

      // Apply the default selections to the filter if they are provided (defaultSelections on the filter settings).
      column.setFilterValue(filter.defaultSelections);
      if (filter.makeSticky) {
        setFilterValue(name, column.id, filter.defaultSelections || []);
      }
    });
  }, [filters, table]);

  useEffect(() => {
    if (dateRangeFilterColumnId) {
      const column = table.getColumn(dateRangeFilterColumnId);
      if (column) {
        column.setFilterValue(dateRangeStartingValue);
      }
    }
  }, [dateRangeFilterColumnId, dateRangeStartingValue, table]);

  // TODO: There is a brief flash of all the contents of the table before all the filters are applied.  This is only
  //  noticeable when the table is first rendered.  This is because the table is rendered before the filters (if
  //  initially set) are applied.  Look into ways to prevent this.
  return (
    <BulkOperationWrapper
      title={title}
      table={table}
      enableBulkOperationHeader={enableBulkOperationHeader}
      bulkOptionMenuItems={bulkOptionMenuItems}
      selectingRows={selectingRows}
      setSelectingRows={setSelectingRows}
      getRowTypeName={getRowTypeName}
    >
      {enableSearch || filters?.length > 0 || dateRangeFilterColumnId ? (
        <FilterBar
          table={table}
          tableName={name}
          enableSearch={enableSearch}
          filters={filters}
          filterPlaceholder={filterPlaceholder}
          filterString={filterString}
          setFilterString={setFilterString}
          dateRangeFilterColumnId={dateRangeFilterColumnId}
        />
      ) : null}
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id} disableRowHoverEffect>
              {headerGroup.headers.map((header) => (
                <TableHead
                  key={header.id}
                  colSpan={header.colSpan}
                  hideWhenMd={header.column.columnDef?.meta?.hideWhenMd}
                >
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && 'selected'}
                className={cn(customRowClassName(row))}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} hideWhenMd={cell.column.columnDef?.meta?.hideWhenMd}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow disableRowHoverEffect>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                {resultsNotFound}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {enablePagination && data?.length > resultsPerPage ? (
        <Pagination className="mt-4">
          <PaginationPrevious
            onClick={() => {
              if (table.getCanPreviousPage()) {
                setPageIndex(pageIndex - 1);
              }
            }}
            isActive={table.getCanPreviousPage()}
            className="mr-3"
          />
          <PaginationNext
            onClick={() => {
              if (table.getCanNextPage()) {
                setPageIndex(pageIndex + 1);
              }
            }}
            isActive={table.getCanNextPage()}
          />
        </Pagination>
      ) : null}
    </BulkOperationWrapper>
  );
}
