import { useQuery } from '@tanstack/react-query';
import { ItemPlaybackUrl } from '@companion-professional/dctypes';
import { getPlaybackUrl } from '../lib/api';

// usePlaybackUrl is a hook that fetches a single signed playback url for the provided item id.
//
// It returns:
// - isPending: A boolean that indicates if the request is currently in progress.
// - error: An error object if the request failed.
// - playbackUrlResponse: A ItemPlaybackUrl object.
export const usePlaybackUrl = (itemId: string) => {
  const {
    isPending,
    error,
    data: playbackUrlResponse
  } = useQuery<ItemPlaybackUrl>({
    queryKey: ['playback-url', itemId],
    queryFn: () => getPlaybackUrl(itemId)
  });

  return {
    isPending,
    error,
    playbackUrlResponse
  };
};
