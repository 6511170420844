import { useQuery } from '@tanstack/react-query';
import { SurveyFull } from '@companion-professional/dctypes';
import { DynamicFormQuestionField } from '@companion-professional/components';
import { getSurvey } from '../lib/api';

// useSurvey is a hook that fetches a single survey along with its questions.  It takes in a surveyId and an optional
// version.  If the version is not provided, the latest version of the survey will be retrieved.
//
// It returns:
// - isPending: A boolean that indicates if the request is currently in progress.
// - error: An error object if the request failed.
// - survey: A SurveyFull object.
export const useSurvey = (surveyId: string, version?: number) => {
  const {
    isPending,
    error,
    data: survey
  } = useQuery<SurveyFull<DynamicFormQuestionField>>({
    queryKey: ['survey', surveyId, version ?? 0],
    queryFn: () => getSurvey(surveyId, version)
  });

  return {
    isPending,
    error,
    survey
  };
};
