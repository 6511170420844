import React, { FunctionComponent } from 'react';

interface ProfileSectionElementProps {
  title: string;
  description: string;
  children: React.ReactNode;
}

export const ProfileSectionElement: FunctionComponent<ProfileSectionElementProps> = ({
  title,
  description,
  children
}) => {
  return (
    <div className="flex items-center justify-between py-4">
      <div>
        <p className="font-bold">{title}</p>
        <p className="text-sm text-widget-foreground/50">{description}</p>
      </div>
      <div>{children}</div>
    </div>
  );
};
