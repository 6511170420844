import React, { FunctionComponent } from 'react';
import { Section } from '../Section';

interface PageProps {
  // title is the title of the page.
  title: string;

  // children is the content of the page.
  children?: React.ReactNode;

  // rightButton is a button that is displayed on the right side top of the page.
  rightButton?: React.ReactNode;

  // wrapWithSection is a boolean that determines if the children should be wrapped in a Section component.  Defaults to
  // true.
  wrapWithSection?: boolean;
}

// Page is a component that is used to wrap a page in the app. It displays a title and a button on the right (if
// provided).  The content of the page is passed in as children.
export const Page: FunctionComponent<PageProps> = ({ title, children, rightButton, wrapWithSection = true }) => {
  return (
    <div className="flex flex-1 flex-col items-center">
      <div className="w-full max-w-screen-xl">
        <div className="flex justify-between">
          <h1 className="mb-2 text-2xl font-bold">{title}</h1>
          {rightButton}
        </div>
        {wrapWithSection ? <Section>{children}</Section> : children}
      </div>
    </div>
  );
};
