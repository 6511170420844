import React, { FunctionComponent } from 'react';
import QRCode from 'react-qr-code';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  QueryLoaderWrapper
} from '@companion-professional/components';
import { useCheckupUrl } from '../../hooks/useCheckupUrl';
import { DialogSharedProps } from '../DialogSharedProps';

interface QRCodeDialogProps extends DialogSharedProps {
  // checkupRequestId is the ID of the checkup request that the QR code will link to.
  checkupRequestId: string;
}

// QRCodeDialog is a dialog that shows a QR code for the checkup URL.  This also demonstrates how to use the Dialog
// component using the DialogTrigger (if children are provided) or the open and onOpenChange props to control the
// dialog using state from the parent component.
export const QRCodeDialog: FunctionComponent<QRCodeDialogProps> = ({
  children,
  checkupRequestId,
  open,
  onOpenChange = () => {}
}) => {
  const { getCheckupUrl, error, isPending } = useCheckupUrl();
  const checkupUrl = getCheckupUrl(checkupRequestId);

  return (
    <Dialog open={open} onOpenChange={(o) => onOpenChange(o)}>
      {children ? <DialogTrigger asChild>{children}</DialogTrigger> : null}
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Checkup QR Code</DialogTitle>
          <DialogDescription>Point your phone's camera at this QR code to open the checkup.</DialogDescription>
        </DialogHeader>
        <QueryLoaderWrapper isPending={isPending} error={error}>
          <div className="flex justify-center">{checkupUrl ? <QRCode value={checkupUrl} size={256} /> : null}</div>
        </QueryLoaderWrapper>
        <DialogFooter className="sm:justify-start">
          <DialogClose asChild>
            <Button size="small" type="button" variant="secondary">
              Close
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
