import React, { FunctionComponent } from 'react';
import { RadioGroupItem, RadioGroupItemProps } from './RadioGroupItem';
import { Label } from '../Label';
import { cn } from '@companion-professional/webutils';

interface RadioGroupItemWithLabelProps extends RadioGroupItemProps {
  // orientation controls how the label is positioned relative to the radio button.  Defaults to 'horizontal'
  orientation?: 'horizontal' | 'vertical';

  // label is the text that will be displayed next to the radio button when orientation is 'horizontal' or above the
  // radio button when orientation is 'vertical'
  label: string;
}

export const RadioGroupItemWithLabel: FunctionComponent<RadioGroupItemWithLabelProps> = ({
  label,
  id,
  orientation = 'horizontal',
  ...props
}) => {
  let htmlForId = id;
  if (!htmlForId) {
    htmlForId = `radio-${label}`;
  }

  return (
    <div
      className={cn('mb-3 flex flex-row items-center gap-2', {
        'flex-col-reverse': orientation === 'vertical',
        'flex-row': orientation === 'horizontal'
      })}
    >
      <RadioGroupItem id={htmlForId} {...props} />
      <Label htmlFor={htmlForId}>{label}</Label>
    </div>
  );
};
