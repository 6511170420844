import { toast } from '@companion-professional/components';

export const copyRequestUrlToClipboard = (requestUrl?: string) => {
  if (!requestUrl) {
    console.error('Request URL was empty.');
    return;
  }

  navigator.clipboard
    .writeText(requestUrl)
    .then(() => {
      toast('Request URL copied to clipboard', {
        description: requestUrl
      });
    })
    .catch((err) => {
      console.error('Could not copy text: ', err);
    });
};
