import { useMutation } from '@tanstack/react-query';
import { addSurveyAnswers } from '../lib/api';

export type SaveAnswersFormValues = {
  surveyId: string;
  version: number;
  checkupRequestIds: string[];
  answers: Record<string, unknown>;
};

// useSaveAnswers is a hook that returns a mutation function to save the answers for a survey.
//
// It returns:
// - isPending: A boolean that indicates if the mutation is currently pending.
// - error: An error object if the request failed.
// - saveAnswers: Mutation function that saves the answers for a survey (takes SaveAnswersFormValues).
export const useSaveAnswers = () => {
  const {
    mutateAsync: saveAnswers,
    isPending,
    error
  } = useMutation({
    mutationFn: async (values: SaveAnswersFormValues) =>
      await addSurveyAnswers(values.surveyId, values.version, values.checkupRequestIds, values.answers)
  });

  return {
    saveAnswers,
    isPending,
    error
  };
};
