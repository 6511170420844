import React, { FunctionComponent } from 'react';
import { CompanionProLogo } from '@companion-professional/components';
import { companionProBranding } from '@companion-professional/branding';

// LoginSplash is the section of the login page that includes the logo, name, and some links.
export const LoginSplash: FunctionComponent = () => {
  return (
    <div className="bg-primary p-4 py-6 text-primary-foreground md:flex md:w-80 md:flex-shrink-0 md:flex-col md:items-center md:justify-evenly">
      <div className="my-3 flex flex-col items-center justify-center font-bold">
        <div className="my-2 h-24 w-24">
          <CompanionProLogo lineColor="white" backgroundColor={companionProBranding.logoColor} />
        </div>
        <div className="text-center text-3xl leading-10 tracking-wider">
          <a href="#">Companion Vision</a>
        </div>
      </div>
      {/* TODO: Create terms and conditions pages (when/if needed) or remove this links  This could also be a good
                place for signup and demo links */}
      <p className="mt-6 text-center text-sm text-gray-300">
        Read our{' '}
        <a href="#" className="underline">
          terms
        </a>{' '}
        and{' '}
        <a href="#" className="underline">
          conditions
        </a>
      </p>
    </div>
  );
};
