import { FunctionComponent } from 'react';
import { DynamicFieldProps } from '../DynamicField';
import { Answers, Field, TypedAnswer } from '../dynamicFormSchema';

// DynamicFieldComponent is a base class for all dynamic field components. It provides a common interface for all
// dynamic field components.
export abstract class DynamicFieldComponent<T extends Field = Field, V = TypedAnswer> {
  abstract Component: FunctionComponent<DynamicFieldProps>;

  protected constructor(public field: T) {}

  // getAnswer should return the answer for the field from the answers object. If the answer is not present, it should
  // return undefined.
  abstract getAnswer(answers: Answers): V;

  // getDefaultValue should return the default value for the field.
  abstract getDefaultValue(): V;

  // getValueToSubmit is used when the fields value is being submitted (i.e. before it is sent to the server). This
  // allows the field to convert the value to a different type before it is submitted.
  abstract getValueToSubmit(value: unknown): unknown;

  // providesAnswer should return true if the field contributes an answer that should be included in a submitted form
  // (this would be the case for most component like text, checkboxes, radio groups, etc).  If the component doesn't
  // provide an answer (something that's display-only like the markdown field), then this function should return false.
  providesAnswer(): boolean {
    return true;
  }
}
