import { AnsweredCheckupsWithBonusVideos } from '@companion-professional/dctypes';
import { ColumnDef } from '@tanstack/react-table';
import React from 'react';
import { format, parseISO } from 'date-fns';

export const getCheckupsWithBonusVideosColumns = (
  onOpen: (checkup: AnsweredCheckupsWithBonusVideos) => void
): ColumnDef<AnsweredCheckupsWithBonusVideos>[] => {
  return [
    {
      accessorKey: 'pet_name',
      header: () => <div>Pet Name</div>,
      cell: ({ row }) => <div onClick={() => onOpen(row.original)}>{row.getValue('pet_name')}</div>
    },
    {
      accessorKey: 'clinic_name',
      header: () => <div>Clinic Name</div>,
      cell: ({ row }) => <div onClick={() => onOpen(row.original)}>{row.getValue('clinic_name')}</div>
    },
    {
      accessorKey: 'requested_at',
      header: () => <div>Request Created At</div>,
      cell: ({ row }) => (
        <div onClick={() => onOpen(row.original)}>
          {format(parseISO(row.getValue('requested_at')), 'MM/dd/yyyy hh:mm a')}
        </div>
      )
    },
    {
      accessorKey: 'num_unanswered_checkups',
      header: () => <div>Pet's Unanswered Checkups</div>,
      cell: ({ row }) => <div onClick={() => onOpen(row.original)}>{row.getValue('num_unanswered_checkups')}</div>
    }
  ];
};
