import React, { FunctionComponent } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useRoles } from '../../state/auth';

interface RoleProtectedRoutesProps {
  // allowRoles is an array of roles that the user must have at least one of in order to access the route.
  allowRoles: string[];

  // redirectPath is the path that the user will be redirected to if they do not have the correct role.
  redirectPath: string;
}

// RoleProtectedRoutes can be used in a react-router Routes component to protect encapsulated routes based on the
// user's role.  This looks something like this in practice:
//
// <Routes>
//   <Route path="/" element={<Home />} />
//   <Route element={<RoleProtectedRoutes allowRoles={['global_admin']} redirectPath="/" />}>
//     <Route path="/protected" element={<SuperSecret />} />
//   </Route>
// </Routes>
//
// NOTE: this should not be used to enforce any kind of security.  It is purely for UI purposes.
export const RoleProtectedRoutes: FunctionComponent<RoleProtectedRoutesProps> = ({ allowRoles, redirectPath }) => {
  const roles = useRoles();

  if (roles.some((role) => allowRoles.includes(role))) {
    return <Outlet />;
  }

  return <Navigate to={redirectPath} />;
};
