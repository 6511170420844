import React, { FunctionComponent } from 'react';
import { PawPrint as PetsIcon, Table as DashboardIcon, View } from 'lucide-react';
import { CompanionProLogo } from '@companion-professional/components';
import { cn, useOutsideClick } from '@companion-professional/webutils';
import { companionProBranding } from '@companion-professional/branding';
import { useMobileMenuOpen, useSetMobileMenuOpen } from '../../state/layout';
import { SideNavLink } from './SideNavLink';
import { AuthRoles } from '@companion-professional/dctypes';

// SideNav is the side navigation section of the application. It is the left-most section of the application and
// contains links to various pages of the application. When the screen size is reduced, it is hidden and can be opened
// by clicking the hamburger icon in the header.
export const SideNav: FunctionComponent = () => {
  const mobileMenuOpen = useMobileMenuOpen();
  const setMobileMenuOpen = useSetMobileMenuOpen();

  // This is a custom hook that will close the mobile menu when the user clicks outside of it.
  const sideNavRef = React.useRef<HTMLDivElement>(null);
  useOutsideClick(sideNavRef, () => setMobileMenuOpen(false));

  return (
    <aside
      className={cn([
        'absolute',
        'inset-y-0',
        'left-0',
        'w-64',
        'transform',
        'space-y-4',
        'bg-primary',
        'px-3',
        'text-primary-foreground',
        'transition',
        'duration-200',
        'ease-in-out',
        'xl:relative',
        'z-40',
        'overflow-hidden',
        {
          'translate-x-0': mobileMenuOpen,
          '-translate-x-full': !mobileMenuOpen,
          'xl:translate-x-0': !mobileMenuOpen
        }
      ])}
      ref={sideNavRef}
    >
      <div
        className="mb-8 mt-4 flex flex-row items-center space-x-2"
        onClick={() => {
          setMobileMenuOpen(false);
        }}
      >
        <CompanionProLogo className="h-8 w-auto" lineColor="white" backgroundColor={companionProBranding.logoColor} />
        <span className="text-lg font-bold">Companion Vision</span>
      </div>
      <nav>
        <SideNavLink to="/">
          <DashboardIcon />
          <span>Dashboard</span>
        </SideNavLink>
        <SideNavLink to="/pets">
          <PetsIcon />
          <span>Pets</span>
        </SideNavLink>
        <SideNavLink to="/reviews" restrictToRoles={[AuthRoles.global_admin]}>
          <View />
          <span>Reviews</span>
        </SideNavLink>
      </nav>
    </aside>
  );
};
