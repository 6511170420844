import React, { FunctionComponent, useCallback } from 'react';
import { Label } from '../Label';
import { Input } from '../Input';
import {
  RadioOption,
  RadioGroupWithOther,
  getValueFromRadioValue,
  getOtherFromRadioValue
} from '../RadioGroupWithOther';
import { CheckboxExpander } from '../CheckboxExpander';
import {
  CheckboxGroupOption,
  CheckboxGroupValue,
  CheckboxGroupValues,
  RadioValue
} from '../DynamicForm/dynamicFormSchema';

interface CheckboxGroupProps {
  // name is the name of this form entry.  This is used to generate the ids/keys for the various components.  This
  // should be unique to the form.
  name: string;

  // label is the text that appears above the checkbox group.
  label: string;

  // description (if provided) is some smaller text that appears below the label.
  description?: string;

  // options is a list of options for the checkbox group.  Each option should have a label (what's displayed) and a
  // value.
  options: CheckboxGroupOption[];

  // onChange is a function that is called when the value of the checkbox group changes.
  onChange?: (values: CheckboxGroupValues) => void;

  // disabled is a boolean that determines if this checkbox group is disabled. This will disable all the checkboxes and
  // the input fields that are associated with the "other" option.
  disabled?: boolean;

  values?: CheckboxGroupValues;

  expandedRadioOptions?: RadioOption[];
}

// CheckboxGroup is a group (list) of checkboxes.  It can also include an "other" option that when checked, shows an
// input field.
export const CheckboxGroup: FunctionComponent<CheckboxGroupProps> = ({
  name,
  label,
  description,
  options = [],
  onChange = () => {},
  values = {},
  disabled = false,
  expandedRadioOptions
}) => {
  const isChecked = useCallback(
    (option: CheckboxGroupOption) => {
      return !!(option?.value && values?.[option.value]?.checked);
    },
    [values]
  );

  const onChangeHandler = useCallback(
    (checked: boolean | string, option: CheckboxGroupOption, radioValue?: RadioValue) => {
      const newValues: CheckboxGroupValues = { ...values };
      if (checked) {
        newValues[option.value] = { ...values[option.value], checked: !!checked };
      } else {
        delete newValues[option.value];
      }

      if (radioValue) {
        newValues[option.value] = {
          ...(newValues[option.value] as CheckboxGroupValue),
          radioValue
        };
      }

      onChange(newValues);
    },
    [values, onChange]
  );

  return (
    <div>
      <Label description={description} className="mb-4">
        {label}
      </Label>
      <div className="flex flex-col gap-y-4">
        {options.map((option, i) => (
          <div className="flex flex-row items-center gap-x-2" key={`${name}-${i}-${option.label}`}>
            <CheckboxExpander
              id={`${name}-${option.label}`}
              name={option.label}
              label={option.label}
              checked={isChecked(option)}
              disabled={disabled}
              onCheckedChange={(v) => onChangeHandler(v, option)}
            >
              {option.enableOtherText || expandedRadioOptions ? (
                <>
                  {option.enableOtherText ? (
                    <Input
                      name={`${name}-${option.label}-other`}
                      disabled={!isChecked(option) || disabled}
                      // TODO: Setting this to required makes it so the browser will not allow the form to be submitted
                      //   if the input is empty.  We should change this so it uses the react-hook-form validation
                      //   instead. That way the UI looks the same for all the form validation.
                      required={isChecked(option)}
                      value={values[option.value]?.otherText || ''}
                      onChange={(e) => {
                        const newValues = { ...values };
                        newValues[option.value] = {
                          ...(newValues[option.value] as CheckboxGroupValue),
                          otherText: e.target.value
                        };
                        onChange(newValues);
                      }}
                      placeholder={option.otherOptionPlaceholder || ''}
                      className="focus-visible:outline-none focus-visible:ring-0"
                    />
                  ) : null}
                  {expandedRadioOptions ? (
                    <RadioGroupWithOther
                      name={`${name}-${option.value}`}
                      options={expandedRadioOptions}
                      onChange={(v) => onChangeHandler(true, option, v)}
                      value={getValueFromRadioValue(values?.[option.value]?.radioValue)}
                      other={getOtherFromRadioValue(values?.[option.value]?.radioValue)}
                      disabled={!isChecked(option) || disabled}
                      // NOTE: If a "required" is added here, and a value is not selected, the entire page will go
                      // empty when the form is submitted. More specifically the page is empty except for the
                      // browser's built-in validation message (in Chrome version 125.0.6422.78). I think this is due
                      // to some issues between react-hook-form and the built-in browser validation.  The validation
                      // should (and currently is) being handled by react-hook-form anyway.
                    />
                  ) : null}
                </>
              ) : null}
            </CheckboxExpander>
          </div>
        ))}
      </div>
    </div>
  );
};
