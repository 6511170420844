import { useQuery } from '@tanstack/react-query';
import { PetCheckupStats } from '@companion-professional/dctypes';
import { getPetStats } from '../lib/api';

// usePetStats is a hook that fetches the stats for a single pet using the provided petId.
export const usePetStats = (petId: string) => {
  const {
    isPending,
    error,
    data: petStats
  } = useQuery<PetCheckupStats>({
    queryKey: ['pet_stats', petId],
    queryFn: () => getPetStats(petId)
  });

  return {
    isPending,
    error,
    petStats
  };
};
