import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { PetAndClinicInfo } from '@companion-professional/dctypes';
import { QueryLoaderWrapper } from '@companion-professional/components';
import { CheckupTable } from '../../components/CheckupTable';
import { useCheckupsForPet } from '../../hooks/useCheckupsForPet';
import { useClinicSurveysForCheckups } from '../../hooks/useClinicSurveysForCheckups';

interface PetCheckupsProps {
  pet: PetAndClinicInfo;
}

// PetCheckups is the page that shows checkup table for the provided pet.
export const PetCheckups: FunctionComponent<PetCheckupsProps> = ({ pet }) => {
  const navigate = useNavigate();
  const { surveys, error: surveyError, isPending: surveyIsPending } = useClinicSurveysForCheckups();
  const { isPending, error, checkups } = useCheckupsForPet(pet.id);

  return (
    <QueryLoaderWrapper isPending={isPending || surveyIsPending} error={error || surveyError}>
      <CheckupTable
        title="Checkups"
        name="petCheckups"
        checkups={checkups}
        onOpen={({ id }) => {
          navigate(`/pets/${pet.id}/checkup/${id}`);
        }}
        enableBulkHealthDataSelect={surveys.length > 0}
        surveys={surveys}
      />
    </QueryLoaderWrapper>
  );
};
