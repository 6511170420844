import axios from 'axios';
import { useAuthStore } from '../state/auth';
import { isAdminClinicSwitchErrorResponse } from './typeGuards';

// authAxios is an axios instance that is configured to automatically add the auth token to the request headers.  It
// uses the auth token from the auth state store.
export const authAxios = axios.create({
  // NOTE: if we wanted to use a different base URL for the API, we could set it here. (e.g.
  // https://api.companionpro.biz)
  baseURL: '/',
  headers: {
    'Content-Type': 'application/json'
  }
});

authAxios.interceptors.request.use(
  (config) => {
    const authToken = useAuthStore.getState().token;
    if (!authToken) {
      throw new Error('No auth token provided');
    }

    config.headers.Authorization = `Bearer ${authToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

authAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // If the API returns a 403 error with a switch clinic response, set the switch clinic id in the auth store.  This
    // will cause the switch clinic dialog to be shown allowing the user to change the clinic so that they can access
    // the data.
    if (error.response?.status === 403 && isAdminClinicSwitchErrorResponse(error.response.data)) {
      useAuthStore.getState().setSwitchClinicId(error.response.data.switch_clinic_id);
    }

    return Promise.reject(error);
  }
);
