import React, { FunctionComponent } from 'react';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  QueryLoaderWrapper
} from '@companion-professional/components';
import { DialogSharedProps } from '../DialogSharedProps';
import { DymoPrinterForm } from '../DymoPrinterForm';
import { CheckupRequestInfo } from '@companion-professional/dctypes';
import { useCheckupUrl } from '../../hooks/useCheckupUrl';
import { getSimpleQrCardXml } from '../../lib/dymoLabels';

interface CheckupPrinterDialogProps extends DialogSharedProps {
  checkupRequest: CheckupRequestInfo;
}

// CheckupPrinterDialog is a dialog that allows the user to print a QR code business card that contains the checkup URL.
export const CheckupPrinterDialog: FunctionComponent<CheckupPrinterDialogProps> = ({
  children,
  checkupRequest,
  open,
  onOpenChange = () => {}
}) => {
  const { getCheckupUrl, error: checkupUrlError, isPending: checkupUrlIsPending } = useCheckupUrl();
  const checkupUrl = getCheckupUrl(checkupRequest.id);

  // NOTE: we made this super generic for now but we'll want
  return (
    <Dialog open={open} onOpenChange={(o) => onOpenChange(o)}>
      {children ? <DialogTrigger asChild>{children}</DialogTrigger> : null}
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Checkup Reminder Card</DialogTitle>
          <DialogDescription>Print a reminder card with the checkup URL on it.</DialogDescription>
        </DialogHeader>
        <QueryLoaderWrapper isPending={checkupUrlIsPending} error={checkupUrlError}>
          {checkupUrl ? (
            <DymoPrinterForm
              createLabels={() => [
                getSimpleQrCardXml(
                  checkupUrl,
                  'For when you get home ...',
                  'Scan the QR code, follow the instructions to take a video of your dog.',
                  `For ${checkupRequest.pet_name}`
                )
              ]}
            />
          ) : null}
        </QueryLoaderWrapper>
        <DialogFooter className="sm:justify-start">
          <DialogClose asChild>
            <Button size="small" type="button" variant="secondary">
              Close
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
