import React, { FunctionComponent, useEffect, useState } from 'react';
import { ClinicInfo } from '@companion-professional/dctypes';
import {
  buttonVariants,
  Input,
  QueryLoaderWrapper,
  toast,
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from '@companion-professional/components';
import { cn } from '@companion-professional/webutils';
import { Copy } from 'lucide-react';
import { useCheckupUrl } from '../../hooks/useCheckupUrl';

interface ShepherdSettingsProps {
  currentClinic?: ClinicInfo;
}

// ShepherdSettings is the component that displays the Shepherd PIMS settings.  This includes the copy-and-paste-able
// Pet Checkup Template URL (the url that is used in Shepherd communications to auto insert the pet ids so the lander
// can look up the pet's information).
export const ShepherdSettings: FunctionComponent<ShepherdSettingsProps> = ({ currentClinic }) => {
  const [landerUrl, setLanderUrl] = useState<string | null>(null);
  const { getCheckupLanderUrl, isPending, error } = useCheckupUrl();

  useEffect(() => {
    if (currentClinic?.slug) {
      // The Shepherd communication system will replace the "{PATIENT IDS}" string with the actual patient ids when
      // sending the communication.
      setLanderUrl(getCheckupLanderUrl(currentClinic.slug) + '?p={PATIENT IDS}');
    }
  }, [currentClinic, getCheckupLanderUrl]);

  return (
    <div>
      <div className="text-xl font-bold">Shepherd PIMS</div>
      <div className="flex flex-row items-center gap-6 py-4">
        <div className="max-w-[500px]">
          <p className="font-bold">Checkup Template URL</p>
          <p className="text-sm text-widget-foreground/50">
            This is formatted link that can be used in a Shepherd Client Communication template. Copy and paste this
            link into your Shepherd Client Communication template. Shepherd will then automatically insert the needed
            patient ids into the link.
          </p>
        </div>
        <div className="w-full min-w-72">
          <QueryLoaderWrapper isPending={isPending} error={error} size="small">
            {landerUrl ? (
              <div className="flex flex-1 flex-row gap-2">
                <Input value={landerUrl} className="w-full flex-1" readOnly />
                <Tooltip>
                  <TooltipTrigger>
                    <div
                      className={cn('px-3', buttonVariants({ variant: 'primary', size: 'icon' }))}
                      onClick={async () => {
                        try {
                          await navigator.clipboard.writeText(landerUrl);
                          toast('Pet Checkup Template URL copied to clipboard', {
                            description: landerUrl
                          });
                        } catch (err) {
                          const msg = `Could not copy text "${landerUrl}" to clipboard`;
                          console.error(msg, err);
                        }
                      }}
                    >
                      <Copy size="18" />
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Copy the Pet Checkup Template URL to your clipboard.</p>
                  </TooltipContent>
                </Tooltip>
              </div>
            ) : null}
          </QueryLoaderWrapper>
        </div>
      </div>
    </div>
  );
};
