import React, { FunctionComponent } from 'react';
import { FormState, ControllerRenderProps } from 'react-hook-form';
import { cn } from '@companion-professional/webutils';
import { Field } from './dynamicFormSchema';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { DynamicFieldComponent } from './dynamicFields/DynamicFieldComponentBase';
import { FormFieldError } from '../FormFieldError';

interface DynamicFieldSharedProps {
  reactHookField: ControllerRenderProps;
  className?: string;
}

export interface DynamicFieldProps extends DynamicFieldSharedProps {
  field: Field;
}

interface DynamicFieldWithDynamicComponentAndFormStateProps extends DynamicFieldSharedProps {
  dynamicFieldComponent: DynamicFieldComponent<Field, any>;
  formState: FormState<FieldValues>;
}

// DynamicField is a component that takes a field and renders the appropriate input field.
export const DynamicField: FunctionComponent<DynamicFieldWithDynamicComponentAndFormStateProps> = ({
  dynamicFieldComponent,
  className,
  formState,
  ...props
}) => {
  const { field } = dynamicFieldComponent;

  return (
    <div className={cn('flex w-full flex-col justify-start leading-none', className)}>
      <dynamicFieldComponent.Component field={field} {...props} />
      <FormFieldError fieldName={field.name} formState={formState} />
    </div>
  );
};
