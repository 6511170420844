import React, { useEffect, useState } from 'react';
import { ColumnDef, FilterFn, Row } from '@tanstack/react-table';
import { InitialTableState } from '@tanstack/table-core';
import { DateRange } from 'react-day-picker';
import { ErrorAlert } from '../ErrorAlert';
import { BulkOptionMenuItem, FilterSettings } from './dataTableTypes';
import { DataTableDisplay } from './DataTableDisplay';

export * from './ColumnHeaderCycleSort';
export * from './dataTableTypes';

// Adding the declaration for react-table stuff here allows it to be exported properly (as opposed to in  something
// like react-table.d.ts declaration file).
declare module '@tanstack/react-table' {
  // @ts-ignore
  interface ColumnMeta {
    // When true, the column will be hidden on mobile devices
    hideWhenMd?: boolean;
  }

  interface FilterFns {
    // This needs to be set to the name of the filter function that is used set up in the useReactTable options to
    // make type checking function properly.  Additional filter functions should be added here.
    dateBetweenFilterFn: FilterFn<unknown>;
  }
}

export interface DataTableProps<TData, TValue> {
  // name is the name of the table and is used to differentiate between multiple tables throughout the application.
  // Currently, this is used as a part of a key for storing the state of the table's filters in local storage.
  name: string;

  // title is the title of the table that's displayed at the top of the header
  title?: string;

  // columns is an array of ColumnDef objects that define the columns of the table.
  columns: ColumnDef<TData, TValue>[];

  // data is an array of objects that represent the rows of the table.
  data: TData[];

  // rowTypeName is the name of the type of row (i.e. "checkup" or "pet").  This defaults to "row".
  rowTypeName?: string;

  // resultsNotFound is the text (or component) that is displayed when there are no results to display.
  resultsNotFound?: React.ReactNode;

  // filterPlaceholder is the text that is displayed in the global filter input when there is no text entered. (i.e.
  // Help text)
  filterPlaceholder?: string;

  // enablePagination determines whether pagination is enabled.
  enablePagination?: boolean;

  // enableSearch determines if the search box is enabled.  This uses the react-table "global filter" to filter the
  // results shown.
  enableSearch?: boolean;

  // filters is an array of multiple FilterSettings objects that define the filters that are displayed in the table.
  filters?: FilterSettings[];

  // resultsPerPage is the number of results to display per page.
  resultsPerPage?: number;

  // enableBulkOperationHeader determines whether the bulk operation header is enabled.  This is a header displays
  // the bump operation menu which allows for selecting multiple rows in the table by checking a checkbox in a leftmost
  // column.
  enableBulkOperationHeader?: boolean;

  // rowCanBeSelected sets if a row can be selected or not.  If this is set to true, then all rows are selectable.  If
  // a function is provided, then the function is called with the row and the return value determines if the row can be
  // selected.  Default is true.
  rowCanBeSelected?: boolean | ((row: Row<TData>) => boolean);

  bulkOptionMenuItems?: BulkOptionMenuItem<TData>[];

  // getRowTypeName is a function that returns a string that describes the type of row that's being selected (e.g.
  // "Checkup", "Task", etc.).  This is a function so that it can be customized based on the number of rows that are
  // selected (so "Checkups" for multiple rows, "Checkup" for a single row).
  getRowTypeName?: (numRows: number) => string;

  // dateRangeFilterColumnId is the column id of the column that will be used for date range filtering.  This is used
  // to determine if the date range filter should be displayed.  IMPORTANT: the column with this id must have a
  // filterFn of 'dateBetweenFilterFn' defined.
  // NOTE: at this time only one date range filter is supported.
  // TODO: Add support for multiple date range filters.
  dateRangeFilterColumnId?: string;

  // dateRangeStartingValue is the starting value of the date range filter.  This is used to set the initial value of
  // the date range filter.
  dateRangeStartingValue?: DateRange;

  // initialState is the initial state of the table.  This is passed to the useReactTable hook.
  initialState?: InitialTableState;

  // customRowClassName is a function that returns a className string that will be applied to the row.  This is useful
  // for applying custom styles to rows based on their data.
  customRowClassName?: (row: Row<TData>) => string;
}

// DataTable is a wrapper around react-table that provides a simple interface for rendering a table with pagination,
// sorting, and filtering.  In order to keep the hooks and render logic in order, this component is a wrapper around
// the DataTableDisplay component where the actual table is rendered.  We can put pre-processing logic here (like
// checking that a filterFn exists in the ColumnDef) if needed.
// TODO: Only local filtering and pagination is supported at the moment. Server-side will need to be implemented.  My
//  thinking is that this table will adapt to the data source: if there are under 1000 rows (just throwing that number
//  out there) then the sorting/filtering/pagination will be done in browser, otherwise it will use server-side
//  sorting/filtering/pagination.

export function DataTable<TData extends object, TValue extends object>({
  columns,
  dateRangeFilterColumnId,
  ...props
}: DataTableProps<TData, TValue>) {
  const [filterError, setFilterError] = useState<string | null>(null);

  // If dateRangeFilterColumnId is set (ie we want to show the date range picker), then check if there is a column
  // with filterFn 'dateBetweenFilterFn' defined.
  useEffect(() => {
    if (
      dateRangeFilterColumnId &&
      !columns.some((column: ColumnDef<TData, TValue>) => column.filterFn === 'dateBetweenFilterFn')
    ) {
      console.error("dateRangeFilterColumnId is set, but no column with filterFn 'dateBetweenFilterFn' is defined.");
      setFilterError('Unable to display table');
    } else {
      setFilterError(null);
    }
  }, [columns, dateRangeFilterColumnId]);

  if (filterError) {
    return <ErrorAlert title="Error" message={filterError} />;
  }

  return <DataTableDisplay columns={columns} dateRangeFilterColumnId={dateRangeFilterColumnId} {...props} />;
}
