import React, { FunctionComponent } from 'react';
import { QueryLoaderWrapper, VideoPlayer, ErrorAlert } from '@companion-professional/components';
import { companionProBranding } from '@companion-professional/branding';
import { usePlaybackUrl } from '../../hooks/usePlaybackUrl';

interface VideoTypeContainerProps {
  // videoItemId is the ID of the video item to play.
  videoItemId: string;

  // playbackRates is an optional list of playback rates to allow the user to select from.
  // Defaults to [0.125, 0.25, 0.5, 1, 2].
  playbackRates?: number[];
}

// VideoTypeContainer loads the proper signed url and displays a video player for a given video item id.
export const VideoTypeContainer: FunctionComponent<VideoTypeContainerProps> = ({
  videoItemId,
  playbackRates = [0.125, 0.25, 0.5, 1, 2]
}) => {
  const { isPending, error, playbackUrlResponse } = usePlaybackUrl(videoItemId);

  if (!isPending && !playbackUrlResponse) {
    return <ErrorAlert title="Unable to load video" message="No playback URL found" />;
  }

  return (
    <div className="flex flex-row items-center justify-center">
      <QueryLoaderWrapper isPending={isPending} error={error}>
        <VideoPlayer
          muxSignedPlaybackUrl={playbackUrlResponse?.url}
          primaryColor={companionProBranding.logoBackgroundColor}
          accentColor={companionProBranding.logoColor}
          className="aspect-video"
          playbackRates={playbackRates}
        />
      </QueryLoaderWrapper>
    </div>
  );
};
