import { useQuery } from '@tanstack/react-query';
import { ItemToReview } from '@companion-professional/dctypes';
import { getItemsToReviewForSurvey } from '../lib/api';

// useItemsToReview is a hook that fetches all the items (e.g. videos) that need to be reviewed.  The initial intention
// of these reviews is to verify that a video is usable for ML analysis, but this is built to be extensible to other
// types of items and QA surveys in the future.
// itemSurveyId is the survey ID for the item review survey (this will only return items that do not have a review for
// this survey yet).
// checkupSurveyId is the survey ID for the checkup survey that the items are associated with (this will only return
// items that have a parent checkup with this survey completed).
export const useItemsToReviewForSurvey = (itemSurveyId: string, checkupSurveyId: string) => {
  const {
    isPending,
    error,
    data: itemsToReviewForSurvey
  } = useQuery<ItemToReview[]>({
    queryKey: ['items-to-review', checkupSurveyId, itemSurveyId],
    queryFn: () => getItemsToReviewForSurvey(itemSurveyId, checkupSurveyId)
  });

  return {
    isPending,
    error,
    itemsToReviewForSurvey
  };
};
