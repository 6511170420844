import React, { FunctionComponent } from 'react';
import { useClinicSurveysForCheckups } from '../../hooks/useClinicSurveysForCheckups';
import { CheckupRequestInfo } from '@companion-professional/dctypes';
import { QueryLoaderWrapper, Separator, ListButtonsContainer } from '@companion-professional/components';
import { SurveyListItem } from './SurveyListItem';

interface SurveysProps {
  checkupRequest: CheckupRequestInfo;
}

// CheckupSurveys is a component that shows a list of surveys that, if all the requested items are uploaded, the user
// can fill out. This is used in the Checkup page.
export const CheckupSurveys: FunctionComponent<SurveysProps> = ({ checkupRequest }) => {
  const { surveys, error, isPending } = useClinicSurveysForCheckups();

  return (
    <>
      <Separator className="my-2" />
      <QueryLoaderWrapper isPending={isPending} error={error}>
        {surveys ? (
          <div>
            <h3 className="mb-2 font-semibold">Health Surveys</h3>
            <ListButtonsContainer>
              {surveys.map((survey) => (
                <SurveyListItem
                  key={survey.survey_id}
                  survey={survey}
                  checkupRequest={checkupRequest}
                  petId={checkupRequest.pet_id}
                />
              ))}
            </ListButtonsContainer>
          </div>
        ) : null}
      </QueryLoaderWrapper>
    </>
  );
};
