import * as DialogPrimitive from '@radix-ui/react-dialog';

// This directory contains the styled components for the dialog.  The Dialog component is a self-contained pop-up
// (modal) that can be used anywhere a pop-up is needed/wanted.  To see this component in action, checkout the Dialog
// story, the typescript/stories directory.
//
// The common structure of the dialog is:
//   <Dialog>
//     <DialogTrigger>...</DialogTrigger>
//     <DialogContent>
//       <DialogHeader>
//           <DialogTitle>...</DialogTitle>
//           <DialogDescription>...</DialogDescription>
//       </DialogHeader>
//       <div>...</div>
//       <DialogFooter>...
//          <DialogClose>...</DialogClose>
//       </DialogFooter>
//     </DialogPortal>
//   </Dialog>
//
// Under the hood, this is using the radix-ui dialog components.
//
// The dialog can be triggered to open/close by using <DialogTrigger /> component (with a button inside) or by setting
// the `open` prop on the <Dialog /> component to `true`/`false`.

export const Dialog = DialogPrimitive.Root;
export const DialogTrigger = DialogPrimitive.Trigger;
export const DialogClose = DialogPrimitive.Close;

export * from './DialogContent';
export * from './DialogHeader';
export * from './DialogFooter';
export * from './DialogTitle';
export * from './DialogDescription';
