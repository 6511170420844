import React, { FunctionComponent } from 'react';
import { cn } from '@companion-professional/webutils';

interface SectionProps {
  // children are the components that are displayed in the section.
  children?: React.ReactNode;

  // className can be used to add additional classes to the section component.
  className?: string;
}

export const Section: FunctionComponent<SectionProps> = ({ children, className }) => {
  return <div className={cn('border border-widget-foreground/20 bg-widget px-4 py-2', className)}>{children}</div>;
};
