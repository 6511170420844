import * as React from 'react';
import { CheckIcon, PlusCircledIcon } from '@radix-ui/react-icons';
import { Column } from '@tanstack/react-table';
import { cn } from '@companion-professional/webutils';
import { Badge } from '../Badge';
import { Button } from '../Button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator
} from '../Command';
import { Popover, PopoverContent, PopoverTrigger } from '../Popover';
import { Separator } from '../Separator';
import { FilterOption } from './dataTableTypes';
import { useSetSelectedOptionsForColumn } from './filterState';

interface FacetedFilterProps<TData, TValue> {
  // title is a string that is displayed in the button that opens the filter.
  title: string;

  // tableName is the name of the table that the filter is applied to.  In this component, it is used to store the
  // selected filter values in the filter state.
  tableName: string;

  // column is the react-table column that the filter will be applied to.
  column: Column<TData, TValue>;

  // options is an array of FilterOption objects that define the filter values that the user can select (by checking a
  // checkbox).
  options: FilterOption[];

  // makeSticky is a boolean that determines whether the filter should be sticky (the selections should persist between
  // page reloads) or not.  Default is false.
  makeSticky?: boolean;
}

// FacetedFilter is a component that displays a button that when clicked displays a list of filter options (defined in
// the options param).  By selecting one or more of the options, the user can filter the data in the table by the
// selected values.
// TODO: The UI flow of this component is that no filters are applied by default; the filtering isn't "activated" until
//  the user selects one or more options.  This might be unintuitive to some users.  I would like to look into ways to
//  make this more clear to the user.
export function FacetedFilter<TData, TValue>({
  title,
  tableName,
  column,
  options,
  makeSticky = false
}: FacetedFilterProps<TData, TValue>) {
  const setSelectedStatusesForColumn = useSetSelectedOptionsForColumn();
  const facets = column.getFacetedUniqueValues();
  const selectedValues = new Set(column.getFilterValue() as string[]);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" size="small" className="h-9 border-dashed border-secondary">
          <PlusCircledIcon className="mr-2 h-4 w-4" />
          {title}
          {selectedValues?.size > 0 ? (
            <>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <Badge variant="secondary" className="rounded-sm px-1 font-normal lg:hidden">
                {selectedValues.size}
              </Badge>
              <div className="hidden space-x-1 lg:flex">
                {selectedValues.size > 2 ? (
                  <Badge variant="secondary" className="rounded-sm px-1 font-normal">
                    {selectedValues.size} selected
                  </Badge>
                ) : (
                  options
                    .filter((option) => selectedValues.has(option.value))
                    .map((option) => (
                      <Badge variant="secondary" key={option.value} className="rounded-sm px-1 font-normal">
                        {option.label}
                      </Badge>
                    ))
                )}
              </div>
            </>
          ) : null}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" align="start">
        <Command>
          <CommandInput placeholder={title} />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {options.map((option) => {
                const isSelected = selectedValues.has(option.value);
                return (
                  <CommandItem
                    key={option.value}
                    data-checked={isSelected}
                    onSelect={() => {
                      if (isSelected) {
                        selectedValues.delete(option.value);
                      } else {
                        selectedValues.add(option.value);
                      }
                      const filterValues = Array.from(selectedValues);
                      column.setFilterValue(filterValues.length ? filterValues : undefined);
                      if (makeSticky) {
                        setSelectedStatusesForColumn(tableName, column.id, filterValues);
                      }
                    }}
                  >
                    <div
                      className={cn(
                        'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                        isSelected ? 'bg-primary text-primary-foreground' : 'opacity-50 [&_svg]:invisible'
                      )}
                    >
                      <CheckIcon className={cn('h-4 w-4')} />
                    </div>
                    {option.icon && <option.icon className="text-muted-foreground mr-2 h-4 w-4" />}
                    <span>{option.label}</span>
                    {facets?.get(option.value) && (
                      <span className="ml-auto flex h-4 w-4 items-center justify-center font-mono text-xs">
                        {facets.get(option.value)}
                      </span>
                    )}
                  </CommandItem>
                );
              })}
            </CommandGroup>
            {selectedValues.size > 0 && (
              <>
                <CommandSeparator />
                <CommandGroup>
                  <CommandItem
                    onSelect={() => {
                      column.setFilterValue(undefined);
                      if (makeSticky) {
                        setSelectedStatusesForColumn(tableName, column.id, []);
                      }
                    }}
                    className="justify-center text-center"
                  >
                    Clear filters
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
