import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

// The layout state is used to various layout and UI states (e.g. mobile menu open, dark/light mode, etc.)

export type LayoutState = {
  mobileMenuOpen: boolean;
  setMobileMenuOpen: (mobileMenuOpen: boolean) => void;
};

const useLayoutStore = create(
  devtools<LayoutState>(
    (set) => ({
      mobileMenuOpen: false,
      setMobileMenuOpen: (mobileMenuOpen = true) => set({ mobileMenuOpen })
    }),
    {
      name: 'vportal-layout'
    }
  )
);

export const useMobileMenuOpen = () => useLayoutStore((state) => state.mobileMenuOpen);
export const useSetMobileMenuOpen = () => useLayoutStore((state) => state.setMobileMenuOpen);
