import { useMutation } from '@tanstack/react-query';
import { addItemReviewAnswers } from '../lib/api';

export type SaveItemReviewAnswersFormValues = {
  surveyId: string;
  version: number;
  itemId: string;
  answers: Record<string, unknown>;
};

// useSaveItemReviewAnswers is a hook that returns a mutation function to save the answers for an item review survey.
export const useSaveItemReviewAnswers = () => {
  const {
    mutateAsync: saveItemReviewAnswers,
    isPending,
    error
  } = useMutation({
    mutationFn: async (values: SaveItemReviewAnswersFormValues) =>
      await addItemReviewAnswers(values.surveyId, values.version, values.itemId, values.answers)
  });

  return {
    saveItemReviewAnswers,
    isPending,
    error
  };
};
