import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  QueryLoaderWrapper
} from '@companion-professional/components';
import { DialogSharedProps } from '../DialogSharedProps';

import { useChangeClinic } from '../../hooks/useChangeClinic';

interface SwitchClinicDialogProps extends DialogSharedProps {
  // switchToClinicId is the ID of the clinic to switch to.
  switchToClinicId: string;

  // returnUrl is the URL to navigate to after switching the clinic.
  returnUrl: string;
}

// SwitchClinicDialog is a dialog that allows the user to switch to a different clinic.
export const SwitchClinicDialog: FunctionComponent<SwitchClinicDialogProps> = ({
  children,
  switchToClinicId,
  open,
  onOpenChange = () => {},
  returnUrl
}) => {
  const navigate = useNavigate();
  const { changeClinic, error, isPending } = useChangeClinic();

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      {children ? <DialogTrigger asChild>{children}</DialogTrigger> : null}
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Change Clinic?</DialogTitle>
          <DialogDescription>
            <p>A clinic change is needed to view this page.</p>
            <p>
              Clicking "<span className="font-semibold">Yes</span>" will switch you to the clinic associated with the
              requested data.
            </p>
          </DialogDescription>
        </DialogHeader>
        <QueryLoaderWrapper isPending={isPending} error={error} />
        <DialogFooter className="sm:justify-end">
          <DialogClose asChild>
            <Button size="small" type="button" variant="outline">
              No
            </Button>
          </DialogClose>
          <Button
            size="small"
            type="button"
            variant="primary"
            onClick={async () => {
              await changeClinic(switchToClinicId);
              // navigate and refresh the page to get the new correct data using the updated token.
              navigate(returnUrl);
              window.location.reload();
            }}
          >
            Yes
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
