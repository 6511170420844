import React, { FunctionComponent } from 'react';
import { ClinicInfo, PimsTypes } from '@companion-professional/dctypes';
import { Page } from '../../components/Page';
import { ShepherdSettings } from './ShepherdSettings';

interface SettingsProps {
  currentClinic?: ClinicInfo;
}

export const Settings: FunctionComponent<SettingsProps> = ({ currentClinic }) => {
  return (
    <Page title="Settings">
      {currentClinic?.pims_type === PimsTypes.shepherd ? <ShepherdSettings currentClinic={currentClinic} /> : null}
    </Page>
  );
};
