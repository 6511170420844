import { useQuery } from '@tanstack/react-query';
import { AnsweredCheckupsWithBonusVideos } from '@companion-professional/dctypes';
import { getAnsweredCheckupsWithUnlinkedVideos } from '../lib/api';

export const useAnsweredCheckupsWithUnlinkedVideos = (surveyType: string) => {
  const {
    isPending,
    error,
    data: answeredCheckupsWithUnlinkedVideos
  } = useQuery<AnsweredCheckupsWithBonusVideos[]>({
    queryKey: ['answered-checkups-with-unlinked-videos', surveyType],
    queryFn: () => getAnsweredCheckupsWithUnlinkedVideos(surveyType)
  });

  return {
    isPending,
    error,
    answeredCheckupsWithUnlinkedVideos
  };
};
