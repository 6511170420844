import { useQuery } from '@tanstack/react-query';
import { BaseCheckupUrl } from '@companion-professional/dctypes';
import { getBaseCheckupUrl } from '../lib/api';

// useCheckupURL is a hook returns a getCheckupUrl function that will create a checkup URL for the provided checkupId.
// NOTE: the getCheckupUrl function will return undefined if the base URL is not available (i.e. the request is still
// pending).
//
// It returns:
// - isPending: A boolean that indicates if the request is currently in progress.
// - error: An error object if the request failed.
// - getCheckupUrl: function that takes a checkup id and returns a Url to use for a checkup request.
// - getCheckupLanderUrl: function that takes a slug and returns a Url to use for a checkup lander (used in PIMS).
export const useCheckupUrl = () => {
  const { isPending, error, data } = useQuery<BaseCheckupUrl>({
    queryKey: ['base-checkup-url'],
    queryFn: () => getBaseCheckupUrl(),

    // Setting the stale time to something long so that we don't refetch this often (as it is unlikely to change).
    staleTime: 1000 * 60 * 60 * 24 // 24 hours
  });

  return {
    isPending,
    error,
    getCheckupUrl: (checkupId: string) => (data?.base_url ? `${data.base_url}/#/${checkupId}` : ''),
    getCheckupLanderUrl: (slug: string) => (data?.base_url ? `${data.base_url}/#/c/${slug}` : '')
  };
};
