import React, { FunctionComponent, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button } from '../Button';
import { ErrorBoundary } from '../ErrorBoundary';
import { ErrorAlert } from '../ErrorAlert';
import { Field, TypedAnswer } from './dynamicFormSchema';
import { DynamicField } from './DynamicField';
import { getValidationRulesForField } from './validators';
import { LoaderSpinner } from '../LoaderSpinner';
import { DynamicFormProps } from './index';
import { DynamicFieldComponent } from './dynamicFields/DynamicFieldComponentBase';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { FieldNumber } from './FieldNumber';

export type DynamicFormQuestionField = Field;

interface DynamicFormWithDynamicFieldsProps extends DynamicFormProps {
  dynamicFields: DynamicFieldComponent<Field, TypedAnswer>[];
}

// DynamicForm display a list of input fields.
export const DynamicFormDisplay: FunctionComponent<DynamicFormWithDynamicFieldsProps> = ({
  dynamicFields,
  showNumbers,
  onSubmit = () => {},
  isSavePending,
  saveError,
  answers,
  showSubmitButton = true,
  disabled = false
}) => {
  const { handleSubmit, control, formState, reset } = useForm();

  useEffect(() => {
    if (formState.isDirty) {
      // If the form is dirty, we don't want to overwrite the values with the answers.
      return;
    }

    // Apply all the values from the answers to the form (if they are provided).  This is used to fill out the form
    // for editing or viewing an existing submission.  Because answers and dynamicFields are retrieved in separate
    // requests, we need to have both in the dependency array (setValue is also included because it is a function that
    // might change between renders).  Also, using reset instead of setValue because setValue makes the form dirty and
    // we need to use reset to set the form to clean anyway.
    if (answers) {
      const cleanStateAnswers: FieldValues = {};
      dynamicFields.forEach((dynamicField) => {
        if (dynamicField.providesAnswer()) {
          const answerValue = dynamicField.getAnswer(answers);
          if (answerValue !== undefined) {
            cleanStateAnswers[dynamicField.field.name] = answerValue;
          }
        }
      });
      reset(cleanStateAnswers);
    } else {
      // If there are no answers, reset the form to its default state.
      reset();
    }
  }, [answers, dynamicFields, reset, formState.isDirty]);

  return (
    <ErrorBoundary fallback={<ErrorAlert title="Invalid Form" message="Unable to display form elements." />}>
      <form
        onSubmit={handleSubmit((data) => {
          onSubmit(data);
        })}
      >
        {/* TODO: the form is currently locked to a width of 800px. This should be removed once all the dynamic fields
              are resizable (I'm looking at you, ImageDiagnosis) */}
        <div className="flex w-[800px] flex-col gap-16">
          {dynamicFields.map((dynamicField, i) => (
            <div key={`field-${dynamicField.field.name}-${i}`} className="flex flex-row">
              {showNumbers ? (
                <FieldNumber number={i + 1} required={!!dynamicField.field.validators?.includes('required')} />
              ) : null}
              <Controller
                control={control}
                name={dynamicField.field.name}
                defaultValue={dynamicField.providesAnswer() ? dynamicField.getDefaultValue() : undefined}
                render={({ field: reactHookField }) => (
                  <DynamicField
                    reactHookField={reactHookField}
                    dynamicFieldComponent={dynamicField}
                    formState={formState}
                  />
                )}
                rules={getValidationRulesForField(dynamicField.field)}
                disabled={isSavePending || disabled}
              />
            </div>
          ))}
          {saveError ? <ErrorAlert title="Error Saving Survey" message={saveError} /> : null}
          {isSavePending ? (
            <div className="flex flex-col items-center rounded-lg border border-primary bg-primary/10 p-4">
              <div className="mb-4 text-lg font-bold text-primary">Saving Survey</div>
              <LoaderSpinner size="medium" />
            </div>
          ) : (
            showSubmitButton && (
              <Button name="submit" type="submit">
                Submit
              </Button>
            )
          )}
        </div>
      </form>
    </ErrorBoundary>
  );
};
