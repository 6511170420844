import React, { FunctionComponent, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Copy, PanelsTopLeft, ExternalLink } from 'lucide-react';
import QRCode from 'react-qr-code';
import {
  Button,
  DialogClose,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Input,
  toast,
  ErrorAlert,
  TooltipTrigger,
  TooltipContent,
  Tooltip,
  buttonVariants
} from '@companion-professional/components';
import { cn } from '@companion-professional/webutils';
import { CheckupData } from './CreateQuickCheckup';

interface CopyUrlContentProps {
  checkupData: CheckupData;
}

// CopyUrlContent is the final section of the quick checkup dialog.  It displays the checkup url and allows the user to
// copy it to the clipboard.
export const CopyUrlContent: FunctionComponent<CopyUrlContentProps> = ({ checkupData }) => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { checkupUrl, checkupId } = checkupData;

  const copyCheckupUrlToClipboard = useCallback(async () => {
    if (!checkupUrl) {
      // Do nothing if there is no checkup url
      return;
    }

    try {
      await navigator.clipboard.writeText(checkupUrl);
      toast('Request URL copied to clipboard', {
        description: checkupUrl
      });
    } catch (err) {
      const msg = `Could not copy text "${checkupUrl}" to clipboard`;
      setErrorMessage(msg);
      console.error(msg, err);
    }
  }, [checkupUrl]);

  return (
    <div className="flex flex-col space-y-5">
      <DialogHeader>
        <DialogTitle>Checkup Created</DialogTitle>
        <DialogDescription>
          The quick checkup has been created. You can now share the link below with your client or user your phone's
          camera to scan the QR code.
        </DialogDescription>
      </DialogHeader>
      <div className="flex flex-col gap-4">
        {errorMessage ? <ErrorAlert title="Error" message={errorMessage} /> : null}

        <div className="w-full">
          <Input id="link" defaultValue={checkupUrl} readOnly onClick={copyCheckupUrlToClipboard} />
        </div>

        <div className="flex justify-center">
          <QRCode value={checkupUrl} size={256} />
        </div>
      </div>
      <DialogFooter className="flex flex-row justify-between space-x-2">
        <div className="flex flex-1 flex-row gap-2">
          <Tooltip>
            <TooltipTrigger
              onClick={() => {
                navigate(`/checkup/${checkupId}`);
              }}
            >
              <div className={cn(buttonVariants({ variant: 'primary', size: 'icon' }))}>
                <PanelsTopLeft size="18" />
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <p>Open the checkup details page</p>
            </TooltipContent>
          </Tooltip>
          <Tooltip>
            <TooltipTrigger
              onClick={() => {
                window.open(checkupUrl, '_blank');
              }}
            >
              <div className={cn(buttonVariants({ variant: 'primary', size: 'icon' }))}>
                <ExternalLink size="18" />
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <p>Open the checkup in a new tab.</p>
            </TooltipContent>
          </Tooltip>
          <Tooltip>
            <TooltipTrigger>
              <div
                className={cn('px-3', buttonVariants({ variant: 'primary', size: 'icon' }))}
                onClick={copyCheckupUrlToClipboard}
              >
                <Copy size="18" />
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <p>Copy Checkup URL to your clipboard.</p>
            </TooltipContent>
          </Tooltip>
        </div>
        <DialogClose asChild>
          <div>
            <Button size="small" type="button" variant="secondary" className="h-10">
              Close
            </Button>
          </div>
        </DialogClose>
      </DialogFooter>
    </div>
  );
};
