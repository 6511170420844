import React, { FunctionComponent } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { QueryLoaderWrapper } from '@companion-professional/components';
import { usePet } from '../../hooks/usePet';
import { Pet } from '../Pet';
import { Checkup } from '../Checkup';
import { Survey } from './Survey';
import { Answers } from './Answers';
import { PageNotFound } from '../PageNotFound';

// IndividualPetRoutes contains all the routes for a single pet (i.e. all routes under the /pets/:petId).  The pet is
// loaded at this top level and passed down to the child components that need it.
export const IndividualPetRoutes: FunctionComponent = () => {
  const { petId } = useParams();
  if (!petId) {
    return <PageNotFound />;
  }
  const { isPending, error, pet } = usePet(petId);

  return (
    <QueryLoaderWrapper isPending={isPending} error={error} errorHeading="Error looking up pet">
      {pet ? (
        <Routes>
          <Route index element={<Pet pet={pet} />} />
          <Route path="/checkup/:checkupId" element={<Checkup />} />
          <Route path="/survey/:surveyId" element={<Survey pet={pet} />} />
          <Route path="/answers/:answersId" element={<Answers pet={pet} />} />
        </Routes>
      ) : null}
    </QueryLoaderWrapper>
  );
};
