import React, { FunctionComponent, useState } from 'react';
import { FastForward } from 'lucide-react';
import { Button, Dialog, DialogContent, DialogTrigger } from '@companion-professional/components';
import { CopyUrlContent } from './CopyUrlContent';
import { CheckupData, CreateQuickCheckup } from './CreateQuickCheckup';
import { AddPetName } from './AddPetName';

interface QuickCheckupProps {
  // autofillPetId is the pet id that the user entered in when using the QuickCheckup to create a checkup (we assume
  // that this comes from the clinic's PIMs system, but in practice this could be anything).  If this is provided, it
  // will be autofilled into the pet id field of the quick checkup dialog so the user doesn't have to type it in.
  autofillPetId?: string;
}

// QuickCheckup is an all-in-one quick checkup dialog that allows the user to create a new checkup request with just
// the clinic pet id (ie what comes from a PIMs system).  They can then copy the link to the clipboard and share it
// with their client (pet parent).
export const QuickCheckup: FunctionComponent<QuickCheckupProps> = ({ autofillPetId }) => {
  const [open, setOpen] = useState(false);
  const [checkupData, setCheckupData] = useState<CheckupData | null>(null);
  const [petIdFound, setPetIdFound] = useState(false);

  // The petId is the unique id for the pet in our system (not the clinic's PIMs).  This is used to create a new pet
  // entry if the pet has been newly created.
  const [petId, setPetId] = useState<string | null>(null);

  return (
    <Dialog
      open={open}
      onOpenChange={(openChange) => {
        setCheckupData(null);
        setOpen(openChange);
      }}
    >
      <DialogTrigger asChild>
        <Button variant="primary" size="small">
          <FastForward className="size-5" />
          &nbsp;Quick Checkup
        </Button>
      </DialogTrigger>

      <DialogContent className="sm:max-w-md">
        {checkupData ? (
          <>
            {petIdFound ? (
              <CopyUrlContent checkupData={checkupData} />
            ) : (
              <AddPetName setPetIdNotFound={setPetIdFound} petId={petId} />
            )}
          </>
        ) : (
          <CreateQuickCheckup
            setCheckupData={setCheckupData}
            setPetIdFound={setPetIdFound}
            setPetId={setPetId}
            autofillPetId={autofillPetId}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
