import React, { FunctionComponent } from 'react';

interface ProfileSectionProps {
  sectionTitle: string;
  children: React.ReactNode;
}

export const ProfileSection: FunctionComponent<ProfileSectionProps> = ({ sectionTitle, children }) => {
  return (
    <div>
      <div className="text-xl font-bold">{sectionTitle}</div>
      {children}
    </div>
  );
};
