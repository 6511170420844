import { useMutation } from '@tanstack/react-query';
import { getDymoLabelPreview } from '../api';
import { PrintLabelValues } from './usePrintLabel';

// usePrintLabel is a hook that returns a function (getPreview) that sends a request to the server to get a preview
// (base64 encoded png) of a label without actually printing it.
export const useGetDymoLabelPreview = () => {
  const {
    mutateAsync: getPreview,
    isPending,
    error
  } = useMutation({
    mutationFn: async (values: PrintLabelValues) => await getDymoLabelPreview(values.printerName, values.labelXml),
    retry: false
  });

  return {
    getPreview,
    isPending,
    error
  };
};
