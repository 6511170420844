import { Row } from '@tanstack/react-table';
import { DateRange } from 'react-day-picker';
import { isValid, parseISO, isBefore, isAfter } from 'date-fns';

// dateBetweenFilterFn is a filter function that can be used to filter rows based on whether a date column falls
// within a specified range.
export function dateBetweenFilterFn<TData>(row: Row<TData>, columnId: string, range: DateRange): boolean {
  // Show all if no range is set
  if (!range.from && !range.to) {
    return true;
  }

  const columnVal = row.getValue(columnId);
  let columnValDate: Date;

  if (columnVal instanceof Date) {
    columnValDate = columnVal;
  } else if (typeof columnVal === 'string' && isValid(parseISO(columnVal))) {
    columnValDate = parseISO(columnVal);
  } else {
    console.error(`Value of column "${columnId}" is expected to be a date, but got ${columnVal}`);
    return false;
  }

  return !((range.from && isBefore(columnValDate, range.from)) || (range.to && isAfter(columnValDate, range.to)));
}
