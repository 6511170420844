import { useQuery } from '@tanstack/react-query';
import { SurveyFull } from '@companion-professional/dctypes';
import { DynamicFormQuestionField } from '@companion-professional/components';
import { getItemReviewSurvey } from '../lib/api';

// useItemSurvey is a hook that fetches a single item review survey along with its questions.
export const useItemSurvey = (surveyId: string) => {
  const {
    isPending,
    error,
    data: itemSurvey
  } = useQuery<SurveyFull<DynamicFormQuestionField>>({
    queryKey: ['item-survey', surveyId],
    queryFn: () => getItemReviewSurvey(surveyId)
  });

  return {
    isPending,
    error,
    itemSurvey
  };
};
