import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const loadFormUrl = 'https://docs.google.com/forms/d/1H580hrYk3UqBReEo0cAlfSGSrbzYVys1kAd8ja_R8fI/edit';

// This state is used to store configuration options for the event cards in local storage.  This could later be moved to
// a database or some other storage mechanism.
export type EventCardsState = {
  healthSurveyUrl: string;
  setMobileMenuOpen: (healthSurveyUrl: string) => void;
};

const useLayoutStore = create(
  persist<EventCardsState>(
    (set) => ({
      healthSurveyUrl: loadFormUrl,
      setMobileMenuOpen: (healthSurveyUrl: string) => set({ healthSurveyUrl })
    }),
    {
      name: 'event-cards'
    }
  )
);

export const useHealthSurveyUrl = () => useLayoutStore((state) => state.healthSurveyUrl);
export const useSetHealthSurveyUrl = () => useLayoutStore((state) => state.setMobileMenuOpen);
