import React, { FunctionComponent, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, CheckCircle2 } from 'lucide-react';
import { PetAndClinicInfo, SurveyFull } from '@companion-professional/dctypes';
import {
  Button,
  DynamicForm,
  DynamicFormProps,
  DynamicFormQuestionField,
  IconTextButton,
  QueryLoaderWrapper
} from '@companion-professional/components';
import { useSurvey } from '../../hooks/useSurvey';
import { CheckupCard } from '../CheckupCard';
import { Section } from '../Section';
import { useCheckupsForPetWithoutSurveyAnswers } from '../../hooks/useCheckupsForPetWithoutSurveyAnswers';
import { AddCheckupToSurveyWizard } from '../CheckupCard/AddCheckupToSurveyWizard';

interface SurveyDisplayProps extends DynamicFormProps {
  // surveyId is the id of the survey to display.
  surveyId: string;

  // pet is the pet that the survey is for
  pet: PetAndClinicInfo;

  // checkupRequestIds is a list of checkup request ids that are associated with this survey.
  checkupRequestIds?: string[];

  // answersSurveyVersion is the version of the survey that the answers are for.  If provided, that version of the
  // survey will be loaded.  If not provided, the latest version of the survey will be retrieved.
  answersSurveyVersion?: number;

  // surveySaved is a flag to indicate that the survey has been saved.  If true, a success message will be displayed.
  surveySaved?: boolean;

  // onSurveySubmit is a function that is called when the survey is submitted.  It is passed the form data and the
  // loaded survey.
  onSurveySubmit?: (
    formData: Record<string, unknown>,
    survey: SurveyFull<DynamicFormQuestionField>,
    checkupsToAdd?: string[]
  ) => void;
}

// SurveyDisplay displays a survey with the given surveyId.  It also takes in all the props for DynamicForm and passes
// them into that component (so you can pass in extra parameters like answers, showSubmitButton, etc).
export const SurveyDisplay: FunctionComponent<SurveyDisplayProps> = ({
  surveyId,
  pet,
  checkupRequestIds = [],
  surveySaved = false,
  onSurveySubmit = () => {},
  answersSurveyVersion,
  ...props
}) => {
  const navigate = useNavigate();
  const {
    checkups = [],
    isPending: checkupsIsPending,
    error: checkupsError
  } = useCheckupsForPetWithoutSurveyAnswers(pet.id, surveyId);
  const [checkupsToAddIds, setCheckupsToAddIds] = useState<string[]>([]);

  // This fetches a survey with the given surveyId and version of the answers (if provided).  In the future (and if we
  // want to add the feature), we can use this to assign the answers to a different version of the survey.
  // New answers will not have an answersSurveyVersion, so they will be assigned to the latest version of the survey.
  const { survey, error, isPending } = useSurvey(surveyId, answersSurveyVersion);

  const surveyTitle = useMemo(
    () => (survey?.title && survey.title.length > 0 ? survey.title : survey?.name),
    [survey?.title, survey?.name]
  );

  // isNewSurvey is a flag to indicate if the survey is new (i.e. no answers have been provided yet).
  const isNewSurvey = props.answers === undefined;

  return (
    <QueryLoaderWrapper isPending={isPending && checkupsIsPending} error={error && checkupsError}>
      <div className="my-6">
        <div className="flex flex-row items-start justify-between gap-x-1">
          <div className="text-xl font-bold">{surveyTitle}</div>
          <div className="aspect-square rounded-lg bg-secondary p-2 text-sm font-semibold text-secondary-foreground">
            v{survey?.version}
          </div>
        </div>
        <p className="text-widget-foreground">{survey?.description}</p>
      </div>
      <div className="space-y-4">
        {surveySaved ? (
          <Section>
            <div className="flex flex-col items-center p-4">
              <div className="mb-4 text-lg font-semibold text-primary">{surveyTitle} has been saved.</div>
              <CheckCircle2 size={48} className="text-primary" />
            </div>
          </Section>
        ) : (
          <>
            <Section className="py-4">
              <h2 className="text-lg font-semibold">Visual Exam Insights</h2>
              <div className="mb-4">Checkups representing physical exam findings.</div>
              <div className="grid grid-cols-3 gap-2">
                {checkupRequestIds.map((cid) => (
                  <CheckupCard
                    key={`checkup-card-${cid}-${surveyId}`}
                    checkupRequestId={cid}
                    cardState={isNewSurvey ? 'to_be_attached' : 'attached'}
                  />
                ))}
                {checkups
                  .filter(({ id }) => !checkupRequestIds.includes(id))
                  .map(({ id }) => (
                    <CheckupCard
                      key={`checkup-to-add-card-${id}-${surveyId}`}
                      checkupRequestId={id}
                      cardState={checkupsToAddIds.includes(id) ? 'to_be_attached' : 'unattached'}
                    >
                      {checkupsToAddIds.includes(id) ? (
                        <Button
                          onClick={() => {
                            setCheckupsToAddIds((ids) => ids.filter((i) => i !== id));
                          }}
                          size="small"
                          variant="destructive"
                        >
                          Cancel Attach
                        </Button>
                      ) : (
                        <AddCheckupToSurveyWizard onAllAnswersYes={() => setCheckupsToAddIds((ids) => [...ids, id])} />
                      )}
                    </CheckupCard>
                  ))}
              </div>
            </Section>
            <Section className="py-4">
              <DynamicForm
                showNumbers
                fields={survey?.questions || []}
                {...props}
                onSubmit={(formData) => {
                  if (survey) {
                    onSurveySubmit(formData, survey, checkupsToAddIds);
                  }
                }}
              />
            </Section>
          </>
        )}
        <div>
          <IconTextButton
            size="small"
            variant="secondary"
            onClick={() => {
              navigate(`/pets/${pet.id}`);
            }}
            Icon={ArrowLeft}
          >
            Back To {pet.name}
          </IconTextButton>
        </div>
      </div>
    </QueryLoaderWrapper>
  );
};
