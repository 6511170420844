import React, { FunctionComponent } from 'react';
import { DataTable } from '@companion-professional/components';
import { AnsweredCheckupsWithBonusVideos } from '@companion-professional/dctypes';
import { getCheckupsWithBonusVideosColumns } from './columns';

interface ItemsToReviewTableProps {
  checkups: AnsweredCheckupsWithBonusVideos[];
  onOpen: (checkup: AnsweredCheckupsWithBonusVideos) => void;
}

export const ReviewedCheckupsWithBonusVideosTable: FunctionComponent<ItemsToReviewTableProps> = ({
  checkups,
  onOpen
}) => {
  return (
    <DataTable
      name="itemReviewTable"
      columns={getCheckupsWithBonusVideosColumns(onOpen)}
      data={checkups}
      resultsPerPage={50}
      enablePagination
    />
  );
};
