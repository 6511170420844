import React, { FunctionComponent } from 'react';
import { Row } from '@tanstack/react-table';
import { MoreHorizontal, ClipboardCheck, QrCode, Link, Clipboard, ExternalLink } from 'lucide-react';
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
  DropdownMenuLabel
} from '@companion-professional/components';
import { copyRequestUrlToClipboard } from '../../lib/copy';
import { useClinicSurveysForCheckups } from '../../hooks/useClinicSurveysForCheckups';
import { useNavigate } from 'react-router-dom';
import { CheckupRequestStatusResponse } from '@companion-professional/dctypes';
import { useCheckupUrl } from '../../hooks/useCheckupUrl';

interface CheckupActionRowMenuProps {
  // row is the row that the menu is being shown for.
  row: Row<CheckupRequestStatusResponse>;

  // openQRCodeDialog is a function that opens a dialog to show the QR code for the checkup.
  openQRCodeDialog: (checkup: CheckupRequestStatusResponse) => void;
}

// CheckupActionRowMenu is a dropdown menu that appears on each row of the CheckupTable.  It shows up as a "..." button
// at the end of each row.  It has the following options:
// - Copy Checkup URL
// - Show QR Code Checkup Link
// - Add Health Data (open a survey for that checkup)
export const CheckupActionRowMenu: FunctionComponent<CheckupActionRowMenuProps> = ({ row, openQRCodeDialog }) => {
  // Loading surveys here to check if there are surveys assigned to the clinic.  If there are, then we can show the
  // "Add Health Data" option.
  const { surveys } = useClinicSurveysForCheckups();
  const navigate = useNavigate();
  const { getCheckupUrl, error, isPending } = useCheckupUrl();

  return (
    <div className="flex justify-end space-x-2">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon">
            <MoreHorizontal className="h-4 w-4" />
            <span className="sr-only">More</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          {!isPending && !error ? (
            <>
              <DropdownMenuItem
                disabled={row.getValue('status') === 'complete'}
                onClick={() => copyRequestUrlToClipboard(getCheckupUrl(row.original.id))}
              >
                <Link className="mr-2 h-4 w-4" />
                <span>Copy Checkup URL</span>
              </DropdownMenuItem>

              <DropdownMenuItem
                disabled={row.getValue('status') === 'complete'}
                onClick={() => {
                  openQRCodeDialog(row.original);
                }}
              >
                <QrCode className="mr-2 h-4 w-4" />
                <span>Show QR Code Checkup Link</span>
              </DropdownMenuItem>

              <DropdownMenuItem
                disabled={row.getValue('status') === 'complete'}
                onClick={() => {
                  window.open(getCheckupUrl(row.original.id), '_blank');
                }}
              >
                <ExternalLink className="mr-2 h-4 w-4" />
                <span>Open Checkup in New Tab</span>
              </DropdownMenuItem>
            </>
          ) : null}

          {surveys && surveys.length > 0 ? (
            <>
              <DropdownMenuSeparator />
              <DropdownMenuLabel>Health Surveys</DropdownMenuLabel>
              {surveys.map((survey) => (
                <DropdownMenuItem
                  key={`${survey.survey_id}-${row.original.id}`}
                  disabled={row.original?.answered_surveys?.some((s) => s.survey_id === survey.survey_id)}
                  onClick={() => {
                    navigate(`/survey/${survey.survey_id}`, { state: { checkupRequestIds: [row.original.id] } });
                  }}
                >
                  {row.original?.answered_surveys?.some((s) => s.survey_id === survey.survey_id) ? (
                    <ClipboardCheck className="mr-2 h-4 w-4" />
                  ) : (
                    <Clipboard className="mr-2 h-4 w-4" />
                  )}
                  <span>{survey.title || survey.name}</span>
                </DropdownMenuItem>
              ))}
            </>
          ) : null}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};
