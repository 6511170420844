import React, { FunctionComponent } from 'react';
import { RadioRange } from '../../RadioRange';
import { Answers, ShortRangeField, ShortRangeFieldSchema } from '../dynamicFormSchema';
import { DynamicFieldProps } from '../DynamicField';
import { DynamicFieldComponent } from './DynamicFieldComponentBase';
import { TypeConverterName, typeConverters } from '../typeConverters';

export const ShortRangeFieldComponent: FunctionComponent<DynamicFieldProps> = ({ field, reactHookField }) => {
  const sr = ShortRangeFieldSchema.parse(field);

  // Pulling our ref because we don't want to pass it to the RadioRange component.
  const { onChange, ref, value, ...props } = reactHookField;

  return (
    <RadioRange
      {...props}
      value={String(value)}
      min={sr.min}
      max={sr.max}
      minLabel={sr.minLabel}
      maxLabel={sr.maxLabel}
      name={sr.name}
      label={sr.label}
      description={sr.description}
      onValueChange={(value) => {
        // This is a custom Radix thing (the onCheckedChange prop) that needs to be manually mapped back to the
        // react-hook-form onChange function.
        onChange(value);
      }}
    />
  );
};

type ShortRangeValue = number | string | boolean | undefined | null;

export class DynamicShortRangeField extends DynamicFieldComponent<ShortRangeField, ShortRangeValue> {
  Component = ShortRangeFieldComponent;

  constructor(field: ShortRangeField) {
    super(field);
  }

  getAnswer(answers: Answers): ShortRangeValue {
    const answer = answers[this.field.name];
    if (typeof answer === 'number' || typeof answer === 'string') {
      return answer;
    }
    return this.getDefaultValue();
  }

  getDefaultValue(): ShortRangeValue {
    return this.field.defaultValue || '';
  }

  getValueToSubmit(value: unknown): ShortRangeValue {
    const converter = typeConverters[this.field.fieldType as TypeConverterName];
    return converter(value || '');
  }
}
