import React, { FunctionComponent } from 'react';
import { Menu as MenuIcon } from 'lucide-react';
import { cn } from '@companion-professional/webutils';
import { useSetMobileMenuOpen } from '../../state/layout';

// MobileMenuButton is a button (a hamburger icon) that toggles the mobile menu.
export const MobileMenuButton: FunctionComponent = () => {
  const setMobileMenuOpen = useSetMobileMenuOpen();
  return (
    <button
      className={cn(
        'block',
        'border-0',
        'bg-transparent',
        'px-2',
        'text-primary',
        'hover:no-underline',
        'hover:shadow-none',
        'hover:text-primary',
        'focus:no-underline',
        'focus:shadow-none',
        'focus:outline-none',
        'focus:ring-0',
        'xl:hidden'
      )}
      type="button"
      aria-label="Toggle side navigation menu"
      onClick={() => setMobileMenuOpen(true)}
    >
      <MenuIcon />
    </button>
  );
};
