import React, { FunctionComponent } from 'react';
import { UploadItemType } from '@companion-professional/dctypes';
import { FileVideo, FileQuestion } from 'lucide-react';

interface ItemTypeIconProps {
  itemType: UploadItemType;
}

export const ItemTypeIcon: FunctionComponent<ItemTypeIconProps> = ({ itemType }) => {
  if (itemType === 'video') {
    return <FileVideo width={18} className="text-primary" />;
  }

  return <FileQuestion width={18} className="text-destructive" />;
};
