import React, { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Checkbox, Input, Label, LoaderSpinner, ErrorAlert } from '@companion-professional/components';
import { LoginFormValues } from '../../hooks/useLogin';
import { LoginHeading } from '../../components/LoginHeading';

export interface LoginFormProps {
  onFormSubmit?: (data: LoginFormValues) => void;

  // initialValues is an object that contains the initial values for the form. This might be used to pre-populate the
  // email address field if the user has previously logged in and selected "remember me".  Defaults to empty object.
  initialValues?: Partial<LoginFormValues>;

  // isLoggingIn is a boolean that indicates if the user is currently logging in.  This is used to disable the form
  // while the login is in progress.  Defaults to false.
  isLoggingIn?: boolean;

  // errorMessage is a string that contains an error message to display to the user (eg a failed login attempt).
  errorMessage: string | null;
}

// LoginForm is the login form component.  See the LoginFormProps interface (in the <Login /> component for the props
// that can be passed to this component).
export const LoginForm: FunctionComponent<LoginFormProps> = ({
  initialValues = {},
  onFormSubmit = (values: LoginFormValues) => {
    console.log('Form submitted with values: ', values);
  },
  isLoggingIn = false,
  errorMessage
}) => {
  const { register, handleSubmit, setValue, watch } = useForm<LoginFormValues>({ defaultValues: initialValues });
  const rememberChecked = watch('remember');

  return (
    <div className="flex-1 bg-white p-5">
      <LoginHeading title="Account Login" />
      <form className="flex flex-col space-y-5" onSubmit={handleSubmit(onFormSubmit)}>
        {errorMessage ? <ErrorAlert title="Error Logging In" message={errorMessage} /> : null}
        <div className="flex flex-col space-y-1">
          <Label htmlFor="email">Email Address</Label>
          <Input
            {...register('email')}
            type="email"
            id="email"
            placeholder="name@company.com"
            disabled={isLoggingIn}
            autoCorrect="off"
            autoCapitalize="none"
          />
        </div>
        <div className="flex flex-col space-y-1">
          <div className="flex items-center justify-between">
            <Label htmlFor="password">Password</Label>
            {/* TODO: Add support for forgot password functionality */}
            <a href="#" className="text-sm text-blue-600 hover:underline focus:text-blue-800">
              Forgot Password?
            </a>
          </div>
          <Input {...register('password')} id="password" type="password" disabled={isLoggingIn} />
        </div>
        <div className="flex items-center space-x-2">
          <Checkbox
            id="remember"
            checked={rememberChecked}
            onClick={() => {
              setValue('remember', !rememberChecked);
            }}
            disabled={isLoggingIn}
          />
          <Label htmlFor="remember">Remember me</Label>
        </div>
        <div>
          <Button
            type="submit"
            variant={isLoggingIn ? 'outline' : 'primary'}
            size="large"
            className="w-full"
            disabled={isLoggingIn}
          >
            {isLoggingIn ? (
              <span className="flex flex-row items-center space-x-2">
                <LoaderSpinner size="small" />
                Logging In...
              </span>
            ) : (
              'Log In'
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};
