import React, { FunctionComponent } from 'react';
import { getOtherFromRadioValue, getValueFromRadioValue, RadioGroupWithOther } from '../../RadioGroupWithOther';
import {
  Answers,
  RadioField,
  RadioFieldSchema,
  RadioValue,
  RadioValueSchema,
  RadioWithOtherValue
} from '../dynamicFormSchema';
import { DynamicFieldProps } from '../DynamicField';
import { DynamicFieldComponent } from './DynamicFieldComponentBase';
import { TypeConverterName, typeConverters } from '../typeConverters';

export const RadioFieldComponent: FunctionComponent<DynamicFieldProps> = ({ field, reactHookField }) => {
  const r = RadioFieldSchema.parse(field);

  return (
    <RadioGroupWithOther
      label={r.label}
      name={r.name}
      description={r.description}
      options={r.options}
      value={getValueFromRadioValue(reactHookField.value)}
      other={getOtherFromRadioValue(reactHookField.value, r.otherOptionInputName || 'other')}
      defaultValue={r.defaultValue}
      onChange={reactHookField.onChange}
      otherOption={r.otherOption}
      otherOptionInputName={r.otherOptionInputName}
      otherOptionPlaceholder={r.otherOptionPlaceholder}
      disabled={reactHookField.disabled}
    />
  );
};

export class DynamicRadioField extends DynamicFieldComponent<RadioField, RadioValue> {
  Component = RadioFieldComponent;

  constructor(field: RadioField) {
    super(field);
  }

  getAnswer(answers: Answers): RadioValue {
    const answer = answers[this.field.name];
    if (answer === undefined) {
      return this.getDefaultValue();
    }

    if (RadioValueSchema.safeParse(answer).success) {
      return answer as RadioWithOtherValue;
    }

    throw new Error(`Invalid answer for field ${this.field.name}`);
  }

  getDefaultValue(): RadioValue {
    if (this.field.otherOption && this.field.otherOptionInputName) {
      return {
        value: this.field.defaultValue || '',
        [this.field.otherOptionInputName]: this.field.otherOptionDefaultValue || ''
      };
    }

    return this.field.defaultValue || '';
  }

  getValueToSubmit(value: RadioValue): RadioValue {
    const converter = typeConverters[this.field.fieldType as TypeConverterName];
    if (this.field.otherOption && this.field.otherOptionInputName && typeof value === 'object') {
      return {
        value: converter(value?.value || ''),
        [this.field.otherOptionInputName]: value?.[this.field.otherOptionInputName] || ''
      };
    }

    // If this is a simple type, use the default getValueToSubmit method
    return converter(value);
  }
}
