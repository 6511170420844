import { useQuery } from '@tanstack/react-query';
import { SurveyInfo } from '@companion-professional/dctypes';
import { getAllSurveysForCheckups } from '../lib/api';

// useAllCheckupSurveys is a hook that fetches all the surveys that are available in the system.
export const useAllSurveysForCheckups = () => {
  const {
    isPending,
    error,
    data: checkupSurveys
  } = useQuery<SurveyInfo[]>({
    queryKey: ['all-checkup-surveys'],
    queryFn: () => getAllSurveysForCheckups()
  });

  return {
    isPending,
    error,
    checkupSurveys: Array.isArray(checkupSurveys) ? checkupSurveys : []
  };
};
