import React, { FunctionComponent } from 'react';
import { DataTable } from '@companion-professional/components';
import { ItemToReview } from '@companion-professional/dctypes';
import { getItemsToReviewColumns } from './columns';

interface ItemsToReviewTableProps {
  itemsToReview: ItemToReview[];
  onOpen: (item: ItemToReview) => void;
}

export const ItemsToReviewTable: FunctionComponent<ItemsToReviewTableProps> = ({ itemsToReview, onOpen }) => {
  return (
    <DataTable
      name="itemReviewTable"
      columns={getItemsToReviewColumns(onOpen)}
      data={itemsToReview}
      resultsPerPage={50}
      enablePagination
    />
  );
};
