import React, { FunctionComponent } from 'react';
import {
  QueryLoaderWrapper,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Separator
} from '@companion-professional/components';
import { Page } from '../../components/Page';
import { UpdatePasswordDialog } from '../../components/UpdatePasswordDialog';
import { useClinicId, useEmail } from '../../state/auth';
import { useUserAccounts } from '../../hooks/useUserAccounts';
import { ProfileSection } from './ProfileSection';
import { ProfileSectionElement } from './ProfileSectionElement';
import { useChangeClinic } from '../../hooks/useChangeClinic';

export const Profile: FunctionComponent = () => {
  const email = useEmail();
  const currentClinicId = useClinicId();
  const { changeClinic, isPending: changeClinicPending } = useChangeClinic();
  const { userAccounts, isPending: userAccountsPending, error: userAccountsError } = useUserAccounts();

  return (
    <Page title="Profile">
      <div className="flex flex-col space-y-6">
        <ProfileSection sectionTitle="Login &amp; Security">
          <ProfileSectionElement title="Email" description="The email address associated with your account.">
            {email}
          </ProfileSectionElement>
          <Separator />
          <ProfileSectionElement title="Password" description="A unique password for your account login.">
            <UpdatePasswordDialog />
          </ProfileSectionElement>
        </ProfileSection>
        <ProfileSection sectionTitle="Account">
          <ProfileSectionElement
            title="Clinics"
            description={
              'This is a list of clinics you are a part of.  To change the clinic you are currently ' +
              'viewing, select it from the dropdown.'
            }
          >
            <QueryLoaderWrapper
              error={userAccountsError}
              isPending={userAccountsPending || changeClinicPending}
              size="small"
            >
              <Select
                value={currentClinicId}
                onValueChange={(value) => {
                  changeClinic(value).catch((error) => {
                    console.error('Error changing clinic:', error);
                  });
                }}
              >
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Select a Clinic" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {(userAccounts || [])
                      .sort((u1, u2) => u1.clinic_name.localeCompare(u2.clinic_name))
                      .map((account) => (
                        <SelectItem key={`'select-clinic-${account.clinic_id}`} value={account.clinic_id}>
                          {account.clinic_name}
                        </SelectItem>
                      ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </QueryLoaderWrapper>
          </ProfileSectionElement>
        </ProfileSection>
      </div>
    </Page>
  );
};
