import React from 'react';
import { useEffect } from 'react';

// useOutsideClick is a hook that executes a callback when a click event occurs outside the provided HTMLDivElement
// ref.
export const useOutsideClick = (ref: React.RefObject<HTMLElement>, onOutsideClicked: () => void) => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onOutsideClicked();
      }
    }

    // Bind the event listener to the document
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
};
