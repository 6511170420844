import React, { FunctionComponent } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ReviewsDashboard } from './ReviewsDashboard';
import { ItemReviewRoutes } from './ItemReviewRoutes';
import { ReviewedCheckups } from './ReviewedCheckups';

// Reviews is a page that allows for the review of checkup videos.
export const Reviews: FunctionComponent = () => {
  return (
    <Routes>
      <Route path="/items-to-review/:checkupSurveyId/:itemReviewSurveyId/*" element={<ItemReviewRoutes />} />
      <Route path="/checkups-to-review/:surveyType" element={<ReviewedCheckups />} />
      <Route path="*" element={<ReviewsDashboard />} />
    </Routes>
  );
};
