import React, { FunctionComponent } from 'react';
import { cn } from '@companion-professional/webutils';

interface LoginHeadingProps {
  // title is the larger text in the heading.
  title: string;

  // children: what's displayed below the title.
  children?: React.ReactNode;
}

export const LoginHeading: FunctionComponent<LoginHeadingProps> = ({ title, children }) => {
  return (
    <>
      <h3
        className={cn('text-2xl font-semibold text-gray-700', {
          'mb-0': children,
          'mb-4': !children
        })}
      >
        {title}
      </h3>
      {children ? <div className="mb-2 text-md text-gray-700">{children}</div> : null}
    </>
  );
};
