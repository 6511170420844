import React, { FunctionComponent } from 'react';

export { ListButtonsContainer } from './ListButtonsContainer';

export interface ListButtonsProps {
  Icon?: React.ElementType;
  iconClassName?: string;
  onClick?: () => void;
  children?: React.ReactNode;
}

// ListButton is a button that is displayed as part of a list of buttons wrapped in a ListButtonsContainer.
// TODO: this is not the prettiest, more UI work should be done on this to make it better fit the design.
export const ListButton: FunctionComponent<ListButtonsProps> = ({
  onClick = () => {},
  children,
  Icon,
  iconClassName
}) => {
  return (
    <li
      className="cursor-pointer rounded-md border border-primary/20 p-2 text-primary hover:bg-primary hover:text-primary-foreground"
      onClick={onClick}
    >
      <div className="flex flex-row items-center gap-2">
        {Icon ? <Icon size={20} className={iconClassName} /> : null}
        <div>{children}</div>
      </div>
    </li>
  );
};
