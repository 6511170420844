import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateSurveyAnswers } from '../lib/api';

export type UpdateAnswersFormValues = {
  surveyId: string;
  version: number;
  answersId: string;
  answers: Record<string, unknown>;
  checkupsToAdd?: string[];
};

// useSaveAnswers is a hook that returns a mutation function to save the answers for a survey.
export const useUpdateAnswers = () => {
  const queryClient = useQueryClient();

  const {
    mutateAsync: updateAnswers,
    isPending,
    error
  } = useMutation({
    mutationFn: async (values: UpdateAnswersFormValues) => {
      await updateSurveyAnswers(
        values.surveyId,
        values.version,
        values.answersId,
        values.answers,
        values.checkupsToAdd
      );
      await queryClient.invalidateQueries({
        queryKey: ['survey-answers', values.answersId]
      });
    }
  });

  return {
    updateAnswers,
    isPending,
    error
  };
};
