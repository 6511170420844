import { useQuery } from '@tanstack/react-query';
import { CheckupRequestAnalysisStatus, CheckupRequestStatusResponse } from '@companion-professional/dctypes';
import { getActiveCheckups } from '../lib/api';
import { useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '../types/featureFlags';

// At the time of 4/3/2024, the API is not returning the analysis status of the checkup.  This function will apply an
// analysis status to a checkup based on if the checkup id is in the checkupAnalysisStatusOverrides feature flag
// (map of checkup id to analysis status).   The feature flag can be found in LaunchDarkly:
//  * Production: https://app.launchdarkly.com/default/production/features/CheckupAnalysisStatusOverrides
//  * Development/Test: https://app.launchdarkly.com/default/test/features/CheckupAnalysisStatusOverrides
const getAnalysisStatus = (
  checkup: CheckupRequestStatusResponse,
  demoCheckupAnalysisStatuses: Record<string, CheckupRequestAnalysisStatus>
): CheckupRequestAnalysisStatus => {
  const status = demoCheckupAnalysisStatuses?.[checkup.id];
  if (status) {
    return status;
  }

  // If the checkup is complete, and no status was found, then we will assume it is "processing".
  if (checkup.status === 'complete') {
    return 'processing';
  }

  // Default to not-submitted (i.e. not submitted for analysis in this case)
  return 'not_started';
};

// useActiveCheckups is a hook that fetches the active checkups for the currently logged-in user. It returns:
// - isPending: A boolean that indicates if the request is currently in progress.
// - error: An error object if the request failed.
// - checkups: An array of CheckupRequestStatusResponse objects.
export const useActiveCheckups = () => {
  const flags = useFlags<FeatureFlags>();
  const [checkupsWithAnalysisStatus, setCheckupsWithAnalysisStatus] = useState<CheckupRequestStatusResponse[]>([]);
  const {
    isPending,
    error,
    data: checkups
  } = useQuery<CheckupRequestStatusResponse[]>({
    queryKey: ['active-checkups'],
    queryFn: () => getActiveCheckups()
  });

  useEffect(() => {
    if (checkups) {
      if (flags?.checkupAnalysisStatusOverrides) {
        setCheckupsWithAnalysisStatus(
          checkups.map((checkup) => ({
            ...checkup,
            analysis_status: getAnalysisStatus(checkup, flags?.checkupAnalysisStatusOverrides || {})
          }))
        );
      } else {
        setCheckupsWithAnalysisStatus(checkups || []);
      }
    }
  }, [checkups, flags?.checkupAnalysisStatusOverrides]);

  return {
    isPending,
    error,
    checkups: checkupsWithAnalysisStatus
  };
};
