import React, { FunctionComponent, useEffect } from 'react';
import { CheckboxWithLabel, CheckboxWithLabelProps } from '../CheckboxWithLabel';

interface CheckboxExpanderProps extends CheckboxWithLabelProps {
  // If children is provided, it will be displayed when the checkbox is checked. Otherwise, nothing additional will be
  // displayed when the checkbox is checked.
  children: React.ReactNode;
}

// CheckboxExpands is a compound component that combines a CheckboxWithLabel and a collapsible content area.  If the
// checkbox is checked, the children content is displayed.
export const CheckboxExpander: FunctionComponent<CheckboxExpanderProps> = ({
  children,
  onCheckedChange,
  checked,
  ...props
}) => {
  const [expanded, setExpanded] = React.useState(false);

  useEffect(() => {
    setExpanded(!!checked);
  }, [checked]);

  return (
    <div className="w-full">
      <div className="mb-2">
        <CheckboxWithLabel
          onCheckedChange={(value) => {
            setExpanded(!!value);
            onCheckedChange?.(value);
          }}
          checked={expanded}
          {...props}
        />
      </div>
      {expanded && children ? (
        <div className="ml-8 flex w-full flex-col gap-y-4 border-l-4 border-l-primary pl-2 pt-2">{children}</div>
      ) : null}
    </div>
  );
};
