import React, { FunctionComponent, useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { cn } from '@companion-professional/webutils';
import { MarkdownFieldSchema, MarkdownField } from '../dynamicFormSchema';
import { DynamicFieldProps } from '../DynamicField';
import { DynamicFieldComponent } from './DynamicFieldComponentBase';

// MarkdownFieldComponent is a component for displaying markdown in a form.  It uses the 'react-markdown' library to
// render the markdown and the 'remark-gfm' plugin to enable GitHub Flavored Markdown (GFM) to make things a little
// more familiar.  This is a field component, but because it is only for display, it doesn't provide an answer when
// the form is submitted.
export const MarkdownFieldComponent: FunctionComponent<DynamicFieldProps> = ({ field }) => {
  const [markdown, setMarkdown] = useState<string>('');
  const m = MarkdownFieldSchema.parse(field);

  useEffect(() => {
    setMarkdown(m.markdown);
  }, [m]);

  // Using the 'prose' class from Tailwind CSS (tailwindcss-typography) to style the markdown.  This is a quick and
  // easy way to make the markdown look nice, but we might want to consider a more custom solution in the future if
  // we want the markdown section better match the rest of our UI.
  // Also, we are using the 'remark-gfm' plugin to enable GitHub Flavored Markdown.
  return (
    <div className="flex flex-col gap-y-6">
      <div>
        <h3 className="text-xl font-bold">{m.label}</h3>
        {m.description ? <div className="mt-2 text-sm">{m.description}</div> : null}
      </div>
      <div
        className={cn(
          'prose prose-h1:text-lg prose-h2:text-lg prose-h3:text-base prose-a:text-blue-700 hover:prose-a:text-blue-400'
        )}
      >
        <Markdown
          components={{
            a: ({ children, ...props }) => {
              // This is a custom component for the 'a' tag in the markdown that opens links in a new tab.
              return (
                <a {...props} target="_blank" rel="noreferrer">
                  {children}
                </a>
              );
            }
          }}
          remarkPlugins={[remarkGfm]}
        >
          {markdown}
        </Markdown>
      </div>
    </div>
  );
};

export class DynamicMarkdownField extends DynamicFieldComponent<MarkdownField, undefined> {
  Component = MarkdownFieldComponent;

  constructor(field: MarkdownField) {
    super(field);
  }

  getAnswer(): undefined {
    throw new Error('Markdown fields do not have an answer');
  }

  getDefaultValue(): undefined {
    throw new Error('Markdown fields do not have a default value');
  }

  getValueToSubmit(): undefined {
    throw new Error('Markdown fields do not have a value to submit');
  }

  providesAnswer(): boolean {
    return false;
  }
}
