import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { QueryLoaderWrapper } from '@companion-professional/components';
import { Page } from '../../components/Page';
import { AllPetsForClinic } from './AllPetsForClinic';
import { usePets } from '../../hooks/usePets';

export const AllPets: FunctionComponent = () => {
  const navigate = useNavigate();
  const { isPending, error, pets } = usePets();

  return (
    <Page title="Pets">
      <QueryLoaderWrapper isPending={isPending} error={error}>
        <AllPetsForClinic
          onOpen={(pet) => {
            navigate(`/pets/${pet.id}`);
          }}
          pets={pets}
        />
      </QueryLoaderWrapper>
    </Page>
  );
};
