import { useQuery } from '@tanstack/react-query';
import { UserAccount } from '@companion-professional/dctypes';
import { getUserAccounts } from '../lib/api';

// useUserAccounts is a hook that fetches all the accounts for the logged-in user.
//
// It returns:
// - isPending: A boolean that indicates if the request is currently in progress.
// - error: An error object if the request failed.
// - userAccounts: An array of accounts (UserAccount[]) for the logged-in user.
export const useUserAccounts = () => {
  const {
    isPending,
    error,
    data: userAccounts
  } = useQuery<UserAccount[]>({
    queryKey: ['user-accounts'],
    queryFn: () => getUserAccounts()
  });

  return {
    isPending,
    error,
    userAccounts
  };
};
