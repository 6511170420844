import React from 'react';
import { Toaster as Sonner, toast } from 'sonner';

type ToasterProps = React.ComponentProps<typeof Sonner>;

// Toaster is the component that displays toast notifications (short-lived popups on the bottom of the page) to the
// user.  The <Toaster /> component should placed be near the root of the app's dom.  The `toast` function is then
// used to display a toast notification.  This is just a wrapper around the sonner, library, more details on the toast
// options can be found here: https://sonner.emilkowal.ski/toast
// TODO: Create a story for this component
const Toaster = ({ ...props }: ToasterProps) => {
  return (
    <Sonner
      theme="system"
      className="toaster group"
      toastOptions={{
        classNames: {
          // TODO: I am not entirely sure why I have to force these classes.  Should research a better way or open an
          //  issue with sonner.
          toast: '!bg-widget !text-widget-foreground !border !border-primary !shadow-lg space-x-2',
          title: 'text-base font-bold',
          description: 'text-muted-foreground',
          actionButton: 'bg-primary text-primary-foreground',
          cancelButton: 'bg-muted text-muted-foreground'
        }
      }}
      {...props}
    />
  );
};

export { Toaster, toast };
