import React, { FunctionComponent, useMemo } from 'react';
import { RadioGroupWithLabel, RadioGroupItemWithLabel, RadioGroupWithLabelProps } from '../RadioGroup';
import { ErrorAlert } from '../ErrorAlert';

export interface RadioRangeProps extends RadioGroupWithLabelProps {
  // minLabel is the label that's placed on the left most part of the ranges (e.g. "Feeling Bad")
  minLabel: string;

  // maxLabel is the label that's placed on the right most part of the ranges (e.g. "Feeling Good")
  maxLabel: string;

  // max is the maximum value of the range (must be greater than min, 10 or less and 1 or greater)
  max: number;

  // min is the minimum value of the range (must be less than max, greater than 0 and less than 10)
  min: number;
}

type RangeOption = {
  value: string;
  name: string;
};

// RadioRange is a component that allows the user to select a value from a range of whole number values.  The range
// is defined by the min and max properties and a radio button is displayed for all the whole number values between and
// including the min/max properties.  The minLabel and maxLabel properties are used to label the left and right
// most parts of the range.  The values are limited to a range of 0-9 for the min and 1-10 for the max.  This prevents
// the component from displaying in an unpredictable way.  If the min or max values are outside the range, an error
// message will be displayed instead of the component.
//
// This component will most likely be used for things like confidence levels, "how likely are
// you to ___?" type questions, etc.
export const RadioRange: FunctionComponent<RadioRangeProps> = ({ min, max, maxLabel, minLabel, ...props }) => {
  if (max < 1 || max > 10 || min > 9 || min < 0 || min >= max) {
    return (
      <ErrorAlert
        title="Invalid Range"
        message={
          'The max value must be between 1 and 10, and the min value must be between 0 and 9.  The min value ' +
          'must be less than the max.'
        }
      />
    );
  }

  const options = useMemo<RangeOption[]>(() => {
    const opts: RangeOption[] = [];
    for (let i = min; i <= max; i++) {
      opts.push({
        value: i.toString(),
        name: i.toString()
      });
    }
    return opts;
  }, [min, max]);

  return (
    <>
      <RadioGroupWithLabel {...props} className="mt-4 flex max-w-xl flex-row items-end">
        {minLabel.length > 0 ? <div className="flex-1 pb-2 pr-2 text-right">{minLabel}</div> : null}
        <div className="flex-3 flex flex-row items-end gap-4">
          {options.map((opt) => (
            <RadioGroupItemWithLabel
              value={opt.value}
              id={`option-${opt.name}-${props.name}`}
              key={`option-${opt.name}-${props.name}`}
              label={opt.name}
              orientation="vertical"
            />
          ))}
        </div>
        {maxLabel.length > 0 ? <div className="flex-1 pb-2 pl-2 text-left">{maxLabel}</div> : null}
      </RadioGroupWithLabel>
    </>
  );
};
