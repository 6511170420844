import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { Checkbox } from '../';

// getCheckboxColumn is a helper function that returns a column definition for a checkbox column that is used to select
// rows in a DynamicTable.
// TODO: there is a tiny little pixel bounce when the checkbox is clicked.  I spent some time trying to track it down,
//   but I couldn't find it.  When I get some more time, I'll try to track it down.
export const getCheckboxColumn = <TData, TValue>(): ColumnDef<TData, TValue> => ({
  id: 'select',
  header: ({ table }) => (
    <Checkbox
      checked={table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && 'indeterminate')}
      onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
      aria-label="Select all"
    />
  ),
  cell: ({ row }) => (
    <Checkbox
      disabled={!row.getCanSelect()}
      checked={row.getIsSelected()}
      onCheckedChange={(value) => row.toggleSelected(!!value)}
      aria-label="Select row"
    />
  ),
  enableSorting: false,
  enableHiding: false
});
