import * as React from 'react';
import { cn } from '@companion-professional/webutils';

export * from './PaginationContent';
export * from './PaginationEllipsis';
export * from './PaginationItem';
export * from './PaginationLink';
export * from './PaginationNext';
export * from './PaginationPrevious';

// Pagination is a display only (there is no actual pagination logic in here) component that helps with showing page
// number, next and previous buttons, etc.
//
// The component layout looks like this:
// <Pagination>
//   <PaginationPrevious />
//   <PaginationContent>
//     <PaginationItem>1</PaginationItem>
//     <PaginationItem>2</PaginationItem>
//     <PaginationItem>3</PaginationItem>
//     <PaginationEllipsis />
//   </PaginationContent>
//   <PaginationNext />
// </Pagination>
export const Pagination = ({ className, ...props }: React.ComponentProps<'nav'>) => (
  <nav
    role="navigation"
    aria-label="pagination"
    className={cn('mx-auto flex w-full justify-center', className)}
    {...props}
  />
);
Pagination.displayName = 'Pagination';
