import React, { FunctionComponent } from 'react';
import { CheckCircle, Loader, AlertTriangleIcon, CircleDashed } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipTrigger } from '@companion-professional/components';
import { CheckupRequestAnalysisStatus } from '@companion-professional/dctypes';

interface StatusIconProps {
  status: CheckupRequestAnalysisStatus;
  width?: number;
}

// AnalysisIcon is a component that displays the ML status of a checkup request.
export const AnalysisIcon: FunctionComponent<StatusIconProps> = ({ status, width = 18 }) => {
  switch (status) {
    case 'not_started':
      return (
        <Tooltip>
          <TooltipTrigger>
            <CircleDashed width={width} className="text-gray-600" />
          </TooltipTrigger>
          <TooltipContent>
            <p>Not started</p>
          </TooltipContent>
        </Tooltip>
      );
    case 'processing':
      return (
        <Tooltip>
          <TooltipTrigger>
            <Loader width={width} className="animate-pulse" />
          </TooltipTrigger>
          <TooltipContent>
            <p>Processing checkup.</p>
          </TooltipContent>
        </Tooltip>
      );
    case 'gait_anomaly':
      return (
        <Tooltip>
          <TooltipTrigger>
            <AlertTriangleIcon width={width} className="text-red-600" />
          </TooltipTrigger>
          <TooltipContent>
            <p>We have detected a notable gait anomaly and recommend further investigation.</p>
          </TooltipContent>
        </Tooltip>
      );
    case 'no_anomaly_detected':
      return (
        <Tooltip>
          <TooltipTrigger>
            <CheckCircle width={width} className="text-success" />
          </TooltipTrigger>
          <TooltipContent>
            <p>No anomalies detected</p>
          </TooltipContent>
        </Tooltip>
      );
    default:
      return null;
  }
};
