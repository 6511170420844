import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createQuickCheckupRequest } from '../lib/api';

export type QuickCheckupFormValues = {
  clinicPetId: string;
};

export const useQuickCheckupCreate = () => {
  const queryClient = useQueryClient();

  const {
    mutateAsync: createQuickCheckup,
    isPending,
    error
  } = useMutation({
    mutationFn: async (values: QuickCheckupFormValues) => {
      // TODO: Currently hard-coding a single request because we currently only support one request.  Need to update
      //  this to support multiple requests and other types.
      const res = await createQuickCheckupRequest(values.clinicPetId, [{ video_type: 'default' }]);
      await queryClient.invalidateQueries({
        queryKey: ['active-checkups']
      });
      await queryClient.invalidateQueries({
        queryKey: ['pet-checkups', res.pet_id]
      });
      return res;
    }
  });

  return {
    createQuickCheckup,
    isPending,
    error
  };
};
