import React, { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { UpdatePetNameFormValues, useSavePetName } from '../../hooks/useSavePetName';
import {
  Button,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Input,
  Label,
  QueryLoaderWrapper,
  ErrorAlert
} from '@companion-professional/components';

interface AddPetNameProps {
  // petId is the pet id that was created when the user created the checkup.
  petId: string | null;
  setPetIdNotFound: (petIdNotFound: boolean) => void;
}

// AddPetName is a form that allows the user to enter and save a pet name for a newly created pet entry.
export const AddPetName: FunctionComponent<AddPetNameProps> = ({ setPetIdNotFound, petId }) => {
  const { isPending, error, savePetName } = useSavePetName();

  if (!petId) {
    return <ErrorAlert title="Error" message="No pet id was created" />;
  }

  const { register, handleSubmit, reset, formState } = useForm<UpdatePetNameFormValues>({
    defaultValues: { petId: petId }
  });
  return (
    <form
      onSubmit={handleSubmit((values) => {
        savePetName(values)
          .then(() => {
            // Setting the petIdNotFound to true because we have updated the pet name and can now display the final
            // step of the quick checkup flow.
            setPetIdNotFound(true);
            reset();
          })
          .catch((err) => {
            console.log(err);
          });
      })}
    >
      <DialogHeader>
        <DialogTitle>Save New Pet Name</DialogTitle>
        <DialogDescription>
          The pet id you entered is not in our system. Please enter the pet's name to create a new pet entry.
        </DialogDescription>
      </DialogHeader>
      <QueryLoaderWrapper isPending={isPending} error={error} showChildrenOnError>
        <div className="mb-8 mt-4 flex flex-col space-y-5">
          <div className="flex flex-col space-y-1">
            <Label htmlFor="petName">Pet Name</Label>
            <Input
              id="petName"
              aria-invalid={formState.errors.petName ? 'true' : 'false'}
              {...register('petName', { required: true })}
            />
            {formState.errors.petName && formState.errors.petName.type === 'required' ? (
              // TODO: This should be it's own <InputError /> component
              <span className="text-destructive">The Pet Name is required.</span>
            ) : null}
          </div>
        </div>
      </QueryLoaderWrapper>
      <DialogFooter className="flex flex-row items-end space-x-2 md:items-center">
        <Button size="small" type="submit" disabled={isPending}>
          Save Pet Name
        </Button>
      </DialogFooter>
    </form>
  );
};
