import React from 'react';
import { X } from 'lucide-react';
import { Button } from '../Button';

// See BulkOperationHeader.tsx for the definition of the BulkOperationSelectingTitleProps interface.
interface BulkOperationSelectingTitleProps {
  title?: string;
  onCancel?: () => void;
}

// BulkOperationTitle is a component that displays the title of the bulk operation header.  It also has a cancel "X"
// that calls the onCancel function.
export function BulkOperationSelectingTitle({ title = '', onCancel = () => {} }: BulkOperationSelectingTitleProps) {
  return (
    <>
      <div className="flex flex-row items-center">
        <div className="text-xl font-semibold">{title}</div>
      </div>
      <div>
        <Button variant="ghost" size="small" onClick={onCancel} className="h-8 px-2 hover:text-secondary lg:px-3">
          Cancel Multi-Select <X className="ml-2 h-4 w-4" />
        </Button>
      </div>
    </>
  );
}
