import React, { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { InputWithLabel } from '@companion-professional/components';
import { InputError } from '../InputError';
import { UpdatePasswordFormValues } from '../../hooks/useChangePassword';

interface UpdatePasswordFormProps {
  // onFormSubmit is the function that will be called when the form is submitted.  It should return a promise
  //   that resolves to true if the submission was successful, and false if it was not.
  onFormSubmit?: (data: UpdatePasswordFormValues) => Promise<boolean>;

  // The children prop is used to render the submit button area of the form.  Because this form is used in multiple
  //   places, the submit button area might be different in each case (i.e. a dialog vs a page).
  children?: React.ReactNode;
}

// UpdatePasswordForm contains the form fields for updating a user's password.  This is shared between the
// LoginPasswordChange page and the UpdatePasswordDialog components.
export const UpdatePasswordForm: FunctionComponent<UpdatePasswordFormProps> = ({
  children,
  onFormSubmit = async () => false
}) => {
  const { register, handleSubmit, watch, reset, formState } = useForm<UpdatePasswordFormValues>();

  return (
    <form
      onSubmit={handleSubmit((data) => {
        onFormSubmit(data).then((success) => {
          if (success) {
            // If the submission was successful, then reset the form
            reset();
          }
        });
      })}
    >
      <div className="flex flex-col space-y-5 pt-6">
        <InputWithLabel
          label="Current Password"
          id="currentPassword"
          type="password"
          aria-invalid={formState.errors.currentPassword ? 'true' : 'false'}
          {...register('currentPassword')}
        />

        <InputWithLabel
          label="New Password"
          id="newPassword"
          type="password"
          aria-invalid={formState.errors.newPassword ? 'true' : 'false'}
          {...register('newPassword', { required: true })}
        >
          {formState.errors.newPassword && formState.errors.newPassword.type === 'required' ? (
            <InputError>New password is required</InputError>
          ) : null}
        </InputWithLabel>

        <InputWithLabel
          label="Confirm Password"
          id="confirmPassword"
          type="password"
          aria-invalid={formState.errors.confirmPassword ? 'true' : 'false'}
          {...register('confirmPassword', {
            required: true,
            validate: (val: string) => {
              if (watch('newPassword') !== val) {
                return 'Your passwords do no match';
              }
            }
          })}
        >
          {formState.errors.confirmPassword && formState.errors.confirmPassword.message ? (
            <InputError>{formState.errors.confirmPassword.message}</InputError>
          ) : null}
        </InputWithLabel>
      </div>
      {children}
    </form>
  );
};
