import React, { FunctionComponent } from 'react';
import { Answers, ImageDiagnosisField, ImageDiagnosisFieldSchema } from '../dynamicFormSchema';
import {
  ImageDiagnosis,
  DiagnosisImageSets,
  DiagnosisOptionSets,
  DiagnosisOptionSetName,
  DiagnosisImageSetName,
  ImageDiagnosisValues,
  ImageDiagnosisValuesSchema
} from '../../ImageDiagnosis';
import { DynamicFieldProps } from '../DynamicField';
import { DynamicFieldComponent } from './DynamicFieldComponentBase';
import { TypeConverterName, typeConverters } from '../typeConverters';

export const ImageDiagnosisFieldComponent: FunctionComponent<DynamicFieldProps> = ({ field, reactHookField }) => {
  const dp = ImageDiagnosisFieldSchema.parse(field);

  return (
    <ImageDiagnosis
      label={dp.label}
      name={dp.name}
      values={reactHookField.value}
      disabled={reactHookField.disabled}
      defaultOptionValue={dp.defaultOptionValue}
      diagnosisImages={DiagnosisImageSets[dp.diagnosisImageSet as DiagnosisImageSetName]}
      options={DiagnosisOptionSets[dp.diagnosisOptionSet as DiagnosisOptionSetName]}
      onChange={(values) => reactHookField.onChange(values)}
    />
  );
};

export class DynamicImageDiagnosisField extends DynamicFieldComponent<ImageDiagnosisField, ImageDiagnosisValues> {
  Component = ImageDiagnosisFieldComponent;

  constructor(field: ImageDiagnosisField) {
    super(field);
  }

  getAnswer(answers: Answers): ImageDiagnosisValues {
    const answer = answers[this.field.name];
    if (answer === undefined) {
      return this.getDefaultValue();
    }

    if (ImageDiagnosisValuesSchema.safeParse(answer).success) {
      // Merge in default answers for any missing points
      return { ...this.getDefaultValue(), ...(answer as ImageDiagnosisValues) };
    }

    throw new Error(`Invalid answer for field ${this.field.name}`);
  }

  getDefaultValue(): ImageDiagnosisValues {
    const imageSet = DiagnosisImageSets[this.field.diagnosisImageSet as DiagnosisImageSetName];
    const defaultValues: ImageDiagnosisValues = {};
    imageSet.forEach((img) => {
      if (img.points) {
        img.points.forEach((point) => {
          defaultValues[point.name] = this.field.defaultOptionValue as number | string;
        });
      }
    });
    return defaultValues;
  }

  getValueToSubmit(value: ImageDiagnosisValues): ImageDiagnosisValues {
    // Convert the values to the correct type. Default to number if no type is specified.
    const converter = typeConverters[(this.field?.fieldType || 'number') as TypeConverterName];
    const convertedValues: ImageDiagnosisValues = {};
    for (const key in value) {
      convertedValues[key] = converter(value[key]);
    }
    return convertedValues;
  }
}
