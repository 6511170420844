import { useMutation } from '@tanstack/react-query';
import { printDymoLabel } from '../api';

export type PrintLabelValues = {
  printerName: string;
  labelXml: string;
};

// usePrintLabel is a hook the sends a request to the server to print a label.
export const usePrintLabel = () => {
  const {
    mutateAsync: printLabel,
    isPending,
    error
  } = useMutation({
    mutationFn: async (values: PrintLabelValues) => await printDymoLabel(values.printerName, values.labelXml),
    retry: false
  });

  return {
    printLabel,
    isPending,
    error
  };
};
