import React, { FunctionComponent } from 'react';
import { Button } from '@companion-professional/components';
import { Page } from '../../components/Page';

// GenerateError is a page that throws an error when the button is clicked.  This is useful for testing error
// boundaries and error reporting tools (like Sentry).
export const GenerateError: FunctionComponent = () => {
  return (
    <Page title="Error Page">
      <Button
        onClick={() => {
          throw new Error('This is a test error.');
        }}
      >
        Generate Error
      </Button>
    </Page>
  );
};
