import { useQuery } from '@tanstack/react-query';
import { getDymoPrinters } from '../api';
import { Printer } from '../config';

// useDymoPrinters is a hook that returns the list of Dymo printers.
export const useDymoPrinters = () => {
  const {
    isPending,
    error,
    data: printers
  } = useQuery<Printer[]>({
    queryFn: () => getDymoPrinters(),
    queryKey: ['dymoPrinters'],
    retry: false
  });

  return {
    isPending,
    error,
    printers
  };
};
