import React, { FunctionComponent, useEffect, useState } from 'react';
import { cn } from '@companion-professional/webutils';
import { Button } from '../Button';
import { DiagnosisImage, DiagnosisOption, ImageDiagnosisValues } from './imageDiagnosisTypes';
import { ImageViewport } from './ImageViewport';
import { ImageDiagnosisPoint } from './ImageDiagnosisPoint';
import { ErrorAlert } from '../ErrorAlert';

// These are shared settings for images and options
export * from './diagnosisImages';
export * from './diagnosisOptions';
export * from './imageDiagnosisTypes';

interface ImageDiagnosisProps {
  // name is a unique identifier for the diagnosis.
  name: string;

  // label is the title (label) of the diagnosis.  This will be displayed at the top left of the widget.
  label?: string;

  // diagnosisImages is an array of image definitions (DiagnosisImage) that each contain an image URL and an array of
  // points.  The points are used to place the diagnosis options on the image.  NOTE: multiple points can represent the
  // same diagnosis option by giving them the same name. Example:
  // { name: 'pain_option', label: 'Pain 1', x: 100, y: 100 } and
  // { name: 'pain_option', label: 'Pain 2', x: 200, y: 200 }
  // will both represent the same diagnosis option (pain_option) and will be changed at the same time.  This is mostly
  // useful for representing the same diagnosis option on different images (like the back of a dog visible on the left
  // and right images).
  diagnosisImages: DiagnosisImage[];

  // options is a list of options that will be displayed at each point on the image.  The user can select one of these
  // options for each point.
  options: DiagnosisOption[];

  // defaultOptionValue (if provided) is the default value that will be selected for each of the points on the image.
  // If not provided, no value will be selected.  NOTE: The results that are returned from the onChange function will
  // only include the points that have been selected.
  defaultOptionValue?: string | number;

  // onChange is a callback function that will be called whenever the user changes the value of a point.  The function
  // will be called with an object that contains the name of each point and the value that the user has selected for it.
  onChange?: (value: ImageDiagnosisValues) => void;

  // values is an object that contains the name of each point and the value that the user has selected for it.  Because
  // this is a controlled component (the state is managed by a parent component), passing in a new set of values is the
  // only way that the state of the component can be changed.  This can be done by using the onChange function in the
  // parent component.
  values?: ImageDiagnosisValues;

  // disabled is a boolean that determines if all the image diagnosis point selections are disabled.
  disabled?: boolean;
}

// ImageDiagnosis is a component that displays a list of images and allows the user to select a diagnosis option for
// each point on the image.  The user can select one of the options for each point and the onChange function will be
// called with the results.
export const ImageDiagnosis: FunctionComponent<ImageDiagnosisProps> = ({
  label,
  name,
  diagnosisImages,
  options,
  defaultOptionValue,
  onChange = () => {},
  values = {},
  disabled = false
}) => {
  const [currentlySelectedImage, setCurrentlySelectedImage] = useState<number>(0);

  useEffect(() => {
    // Set all the default values for the points when the component is first rendered.
    const newValues: ImageDiagnosisValues = {};
    diagnosisImages.forEach((di) => {
      if (di?.points) {
        di.points.forEach((point) => {
          const dv = defaultOptionValue !== undefined ? defaultOptionValue : '';
          newValues[point.name] = values?.[point.name] || dv;
        });
      }
    });
    onChange(newValues);
  }, []);

  if (!diagnosisImages || diagnosisImages.length === 0) {
    return <ErrorAlert title="Unable to Display" message="No images provided for diagnosis." />;
  }

  return (
    <div className="inline-flex flex-col gap-4 rounded-md border border-primary px-4 py-2">
      <div className="mb-4 flex flex-row justify-between align-middle">
        <div className="text-xl">{label}</div>
        <div className="space-x-2">
          {diagnosisImages.map((di, i) => (
            <Button
              key={`image-btn-${name}-${di.name}`}
              onClick={() => setCurrentlySelectedImage(i)}
              size="small"
              variant={currentlySelectedImage === i ? 'primary' : 'outline'}
            >
              {di.name}
            </Button>
          ))}
        </div>
      </div>
      <div>
        {diagnosisImages.map((di, pageIndex) => (
          <ImageViewport
            key={`image-${name}-${di.name}-${pageIndex}`}
            className={cn({
              hidden: pageIndex !== currentlySelectedImage
            })}
          >
            <img src={di.imageUrl} alt={di.name} className="h-full w-full object-cover" />
            {(di?.points || []).map((point, pointIndex) => (
              <ImageDiagnosisPoint
                key={`point-${name}-${di.name}-point-${pointIndex}`}
                point={point}
                options={options}
                selectedOptions={values || {}}
                onValueChange={(key: string, value: string | number) => {
                  onChange({ ...values, [key]: value });
                }}
                pageIndex={pageIndex}
                disabled={disabled}
              />
            ))}
          </ImageViewport>
        ))}
      </div>
    </div>
  );
};
