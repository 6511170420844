import React from 'react';
import { Clipboard, ClipboardCheck } from 'lucide-react';
import { ColumnDef } from '@tanstack/react-table';
import { format, parseISO } from 'date-fns';
import { ColumnHeaderCycleSort } from '@companion-professional/components';
import { CheckupRequestStatusResponse, SurveyInfo } from '@companion-professional/dctypes';
import { cn } from '@companion-professional/webutils';
import { StatusIcon } from './StatusIcon';
import { CheckupActionRowMenu } from './CheckupActionRowMenu';
import { AnalysisIcon } from './AnalysisIcon';

// getCheckupColumns returns the react-table columns that should be used to render the checkup table.
export const getCheckupColumns = (
  // onOpen is a function that is called when a user clicks on a row in the table. It should navigate the user to the
  // checkup details page.
  onOpen: (checkup: CheckupRequestStatusResponse) => void,

  // openQRCodeDialog is a function that is called when a user clicks on the QR code icon in the table. It should open a
  // dialog that shows the QR code for the checkup.
  openQRCodeDialog: (checkup: CheckupRequestStatusResponse) => void,

  // enableAnalysisColumn is a boolean that determines whether the user is shown the analysis column and filter.
  enableAnalysisColumn?: boolean,

  surveys?: SurveyInfo[]
): ColumnDef<CheckupRequestStatusResponse>[] => {
  const columns: ColumnDef<CheckupRequestStatusResponse>[] = [
    {
      accessorKey: 'status',
      header: () => <div className="flex justify-center align-middle">Status</div>,
      cell: ({ row }) => (
        <div className="flex justify-center align-middle" onClick={() => onOpen(row.original)}>
          <StatusIcon status={row.getValue('status')} />
        </div>
      ),
      enableSorting: false,
      enableHiding: false,
      filterFn: (row, id, value) => value.includes(row.getValue(id))
    }
  ];

  // Add the analysis column if it should be enabled.
  if (enableAnalysisColumn) {
    columns.push({
      accessorKey: 'analysis_status',
      header: ({ column }) => (
        <div className="flex justify-center align-middle">
          <ColumnHeaderCycleSort column={column} title="Analysis" setInitialSort="desc" />
        </div>
      ),
      cell: ({ row }) => (
        <div className="flex justify-center align-middle" onClick={() => onOpen(row.original)}>
          <AnalysisIcon status={row.getValue('analysis_status')} />
        </div>
      ),
      enableSorting: true,
      enableHiding: false,
      filterFn: (row, id, value) => value.includes(row.getValue(id)),
      sortingFn: (rowA) => (rowA.getValue('analysis_status') === 'gait_anomaly' ? 1 : -1)
    });
  }

  if (surveys && surveys.length > 0) {
    surveys.forEach((survey) => {
      columns.push({
        accessorKey: `survey_${survey.survey_id}`,
        accessorFn: (row) =>
          row?.answered_surveys?.some((s) => s.survey_id === survey.survey_id) ? 'answered' : 'not_started',
        header: () => <div className="flex justify-center align-middle">{survey.title}</div>,
        cell: ({ row }) => {
          return (
            <div className="flex justify-center align-middle" onClick={() => onOpen(row.original)}>
              {row.original?.answered_surveys?.some((s) => s.survey_id === survey.survey_id) ? (
                // Has answered the survey
                <ClipboardCheck size={18} className="text-primary" />
              ) : (
                <Clipboard
                  size={18}
                  className={cn('text-destructive', {
                    'text-gray-300': row.original?.status !== 'complete'
                  })}
                />
              )}
            </div>
          );
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id))
      });
    });
  }

  columns.push(
    {
      accessorKey: 'request_created',
      filterFn: 'dateBetweenFilterFn',
      header: ({ column }) => <ColumnHeaderCycleSort column={column} title="Request Created At" />,
      cell: ({ row }) => (
        <div onClick={() => onOpen(row.original)}>
          {format(parseISO(row.getValue('request_created')), 'MM/dd/yyyy hh:mm a')}
        </div>
      )
    },
    {
      accessorKey: 'clinic_pet_id',
      header: ({ column }) => <ColumnHeaderCycleSort column={column} title="Pet ID" />,
      cell: ({ row }) => <div onClick={() => onOpen(row.original)}>{row.getValue('clinic_pet_id')}</div>,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      meta: {
        hideWhenMd: true
      }
    },
    {
      accessorKey: 'pet_name',
      header: ({ column }) => <ColumnHeaderCycleSort column={column} title="Pet Name" />,
      cell: ({ row }) => <div onClick={() => onOpen(row.original)}>{row.getValue('pet_name')}</div>,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      }
    },
    {
      accessorKey: 'vet_first_name',
      header: ({ column }) => <ColumnHeaderCycleSort column={column} title="First Name" />,
      cell: ({ row }) => <div onClick={() => onOpen(row.original)}>{row.getValue('vet_first_name')}</div>,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      meta: {
        hideWhenMd: true
      }
    },
    {
      accessorKey: 'vet_last_name',
      header: ({ column }) => <ColumnHeaderCycleSort column={column} title="Last Name" />,
      cell: ({ row }) => <div onClick={() => onOpen(row.original)}>{row.getValue('vet_last_name')}</div>,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      meta: {
        hideWhenMd: true
      }
    },
    {
      accessorKey: 'actions',
      header: '',
      cell: ({ row }) => {
        return <CheckupActionRowMenu row={row} openQRCodeDialog={openQRCodeDialog} />;
      }
    }
  );

  return columns;
};
