import { useQuery } from '@tanstack/react-query';
import { CheckupRequestInfo } from '@companion-professional/dctypes';
import { getCheckupRequest } from '../lib/api';

// useCheckupRequest is a hook that fetches a single checkup request.
//
// It returns:
// - isPending: A boolean that indicates if the request is currently in progress.
// - error: An error object if the request failed.
// - checkupRequest: A CheckupRequestStatusResponse object.
export const useCheckupRequest = (checkupRequestId: string) => {
  const {
    isPending,
    error,
    data: checkupRequest
  } = useQuery<CheckupRequestInfo>({
    queryKey: ['checkup-request', checkupRequestId],
    queryFn: () => getCheckupRequest(checkupRequestId)
  });

  return {
    isPending,
    error,
    checkupRequest
  };
};
