import React, { FunctionComponent } from 'react';
import { Route, Routes } from 'react-router-dom';
import { IndividualPetRoutes } from './IndividualPetRoutes';
import { AllPets } from './AllPets';

export const Pets: FunctionComponent = () => {
  return (
    <Routes>
      <Route path=":petId/*" element={<IndividualPetRoutes />} />
      <Route path="*" element={<AllPets />} />
    </Routes>
  );
};
