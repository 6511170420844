import React, { FunctionComponent } from 'react';
import { VideoOff } from 'lucide-react';
import { format, parseISO } from 'date-fns';
import { cn } from '@companion-professional/webutils';
import { Card, CardFooter, CardHeader, LoaderSpinner } from '@companion-professional/components';
import { useCheckupRequest } from '../../hooks/useCheckupRequest';
import { CheckupCardContent } from './CheckupCardContent';
import { VideoTypeContainer } from '../VideoTypeContainer';

interface CheckupCardProps {
  // checkupRequestId is the id of the checkup request to display in this card.
  checkupRequestId: string;

  // cardState is the state of the card.  This determines the color of the card and the text displayed.
  // 'attached' - The checkup is attached to the findings (default)
  // 'unattached' - The checkup is not attached to the findings
  // 'to_be_added' - The checkup will be attached to the findings upon the next save
  cardState?: 'attached' | 'unattached' | 'to_be_attached';

  // children is the content to display in the footer of the card (e.g. AddCheckupToSurveyWizard)
  children?: React.ReactNode;
}

// CheckupCard displays a card with the checkup request information (e.g. the attachment status and videos).
export const CheckupCard: FunctionComponent<CheckupCardProps> = ({
  checkupRequestId,
  cardState = 'attached',
  children
}) => {
  const { checkupRequest, error, isPending } = useCheckupRequest(checkupRequestId);
  if (isPending || !checkupRequest) {
    return (
      <Card>
        <div className="flex min-h-36 flex-col items-center justify-center">
          <LoaderSpinner size="medium" />
        </div>
      </Card>
    );
  }

  let cardTitle = '';
  let cardDetails = '';
  switch (cardState) {
    case 'attached':
      cardTitle = 'Attached Checkup';
      cardDetails = 'This checkup is attached to the findings below.';
      break;
    case 'unattached':
      cardTitle = 'Unattached Checkup';
      cardDetails = 'This checkup is not attached to the findings below.';
      break;
    case 'to_be_attached':
      cardTitle = 'To Be Attached ...';
      cardDetails = 'This checkup will be attached to the findings below.';
      break;
  }

  return (
    <Card
      className={cn('min-h-90', {
        'border-primary': cardState === 'attached',
        'border-gray-300 bg-gray-100': cardState === 'unattached',
        'border-secondary': cardState === 'to_be_attached'
      })}
    >
      <CardHeader
        className={cn('flex flex-col bg-gray-100 px-4 pb-4 pt-2', {
          'bg-primary text-primary-foreground': cardState === 'attached',
          'bg-gray-200': cardState === 'unattached',
          'bg-secondary text-secondary-foreground': cardState === 'to_be_attached'
        })}
      >
        <div className="font-semibold">{cardTitle}</div>
        <div className="text-sm italic leading-none">{cardDetails}</div>
        <div className="text-sm leading-none">
          <span className="font-semibold">Created At:</span>{' '}
          {format(parseISO(checkupRequest.request_created), 'MM/dd/yyyy hh:mm a')}
        </div>
      </CardHeader>
      <CheckupCardContent>
        {error && <div>Error: {error.message}</div>}
        {checkupRequest.requests.map((item) => (
          <div key={`card-${checkupRequest.id}-${item.item_id}`}>
            {item.upload_count > 0 ? (
              <VideoTypeContainer videoItemId={item.item_id} />
            ) : (
              <div className="my-8 flex flex-col items-center justify-center gap-4 text-destructive">
                <VideoOff size="36" />
                <div className="font-semibold">Checkup video not uploaded</div>
              </div>
            )}
          </div>
        ))}
      </CheckupCardContent>
      {children ? <CardFooter className="flex flex-row items-end justify-end">{children}</CardFooter> : null}
    </Card>
  );
};
